import { HttpErrorResponse } from '@angular/common/http';
import { sha1 } from 'object-hash';
import swal, { SweetAlertOptions } from 'sweetalert2';
import {
    Activity,
    ActivityDay,
    Appointment,
    BaseError,
    GeoMarker,
    Outlet,
    UserPreference,
    VehicleBrand,
} from '../shared/entities';
import { TechnicalSettingConfiguration } from '../shared/technical-setting-configuration';
import { TechnicalSettingService } from './api/technical-setting.service';
import { AwpIcon } from '../country-configuration';

export class Utils {
  
    public static readonly DISPLAY_SUNDAY = true;

    public static getWeekLength(): number {
        return Utils.DISPLAY_SUNDAY ? 7 : 6;
    }

    public static capitalize(value: string): string {
        return value ? value.charAt(0).toUpperCase() + value.slice(1) : value;
    }

    public static capitalizeAll(value: string): string {
        return value.replace(/\b(\w)/g, s => s.toUpperCase());
    }

    public static cloneDate(date: Date): Date {
        if (date) {
            return new Date(
                Date.UTC(
                    date.getUTCFullYear(),
                    date.getUTCMonth(),
                    date.getUTCDate(),
                    date.getUTCHours(),
                    date.getUTCMinutes(),
                    date.getUTCSeconds()
                )
            );
        }
        return date;
    }

    public static localDate(date: Date): Date {
        if (date) {
            return new Date(
                date.getFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                date.getUTCHours(),
                date.getUTCMinutes(),
                date.getUTCSeconds()
            );
        }
        return date;
    }

    public static toUTCDateTime(date: Date): Date {
        if (date) {
            return new Date(
                Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    date.getSeconds()
                )
            );
        }
        return date;
    }

    public static toUTCDate(date: Date): Date {
        if (date) {
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        }
        return date;
    }

    public static addDays(dat: Date, days: number) {
        const oDat: Date = new Date(dat.getTime());
        oDat.setDate(oDat.getDate() + days);
        return oDat;
    }

    public static subDays(dat: Date, days: number) {
        const oDat: Date = new Date(dat.getTime());
        oDat.setDate(oDat.getDate() - days);
        return oDat;
    }

    public static addYears(dat: Date, years: number) {
        const oDat: Date = new Date(dat.getTime());
        oDat.setFullYear(oDat.getFullYear() + years);
        return oDat;
    }

    public static subYears(dat: Date, years: number) {
        const oDat: Date = new Date(dat.getTime());
        oDat.setFullYear(oDat.getFullYear() - years);
        return oDat;
    }

    public static addMonths(dat: Date, months: number) {
        const oDat: Date = new Date(dat.getTime());
        oDat.setMonth(oDat.getMonth() + months);
        return oDat;
    }

    public static subMonths(dat: Date, months: number) {
        const oDat: Date = new Date(dat.getTime());
        oDat.setMonth(oDat.getMonth() - months);
        return oDat;
    }

    public static datesBetween(startDate, endDate) {
        const retVal = [];
        let current = new Date(startDate);
        current.setHours(12);
        endDate.setHours(12);
        while (current <= endDate) {
            retVal.push(new Date(current));
            current = Utils.addDays(current, 1);
        }
        return retVal;
    }

    public static isEmptyObject(obj: Object): boolean {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    public static isNullOrUndefined(value: any): boolean {
        return value === null || value === undefined;
    }

    /**
     * Check if a value is not null or undefined
     * @param value the value to check
     * @returns a boolean indicating if the value is not null or undefined
     */
    public static isNotNullOrUndefined(value: any): boolean {
        return !this.isNullOrUndefined(value);
    }

    /**
     * Check if an array is not empty
     * @param array the array to check
     * @returns a boolean indicating if the array is not empty
     */
    public static isNotEmptyArray(array: any[]): boolean {
        return !this.isEmptyArray(array);
    }

    /**
     * Check if an array is empty
     * @param array the array to check
     * @returns a boolean indicating if the array is empty
     */
    public static isEmptyArray(array: any[]): boolean {
        return this.isNullOrUndefined(array) || array.length === 0;
    }

    public static clearTimeSlot(appointment: Appointment): void {
        appointment.startTime = null;
        appointment.endTime = null;
        appointment.timeSlot = null;
        appointment.serviceAdvisor = null;
        appointment.fastLaneTimeSlot = null;
        appointment.fastLaneResourceId = null;
        appointment.returnTime = null;
        appointment.returnServiceAdvisor = null;
        Utils.clearActivityDays(appointment.activityList);
    }

    public static clearActivityDays(activities: Activity[]) {
        if (!Utils.isNullOrUndefined(activities)) {
            activities.forEach(activity => {
                activity.activityDays = null;
            });
        }
    }

    public static clearFastLane(appointment: Appointment): void {
        appointment.timeSlot = null;
    }

    public static isSameDay(dateFrom: Date, dateTo: Date): boolean {
        return (
            dateFrom.getDate() === dateTo.getDate() &&
            dateFrom.getMonth() === dateTo.getMonth() &&
            dateFrom.getFullYear() === dateTo.getFullYear()
        );
    }

    public static isSameHour(dateFrom: Date, dateTo: Date): boolean {
        return (
            dateFrom.getHours() === dateTo.getHours()
        );
    }

    public static isSameMinute(dateFrom: Date, dateTo: Date): boolean {
        return (
            dateFrom.getMinutes() === dateTo.getMinutes()
        );
    }

    public static compareDate(date1: Date, date2: Date): boolean {
        if(this.isNotNullOrUndefined(date1) && this.isNotNullOrUndefined(date2)) {
            return this.isSameDay(date1, date2) && this.isSameHour(date1, date2) && this.isSameMinute(date1, date2);
        }
        return false
    }



    public static isNotSameDay(dateFrom: Date, dateTo: Date): boolean {
        return !this.isSameDay(dateFrom, dateTo);
    }

    /**
     * Checks if one date is greater than another date (compares year, month, and day).
     * @param {Date} dateFrom - The first date to compare.
     * @param {Date} dateTo - The second date to compare.
     * @returns {boolean} Returns `true` if `dateFrom` is later than `dateTo`, otherwise `false`.
     * */
    public static dayGreaterThan(dateFrom: Date, dateTo: Date): boolean {
        if(this.isNullOrUndefined(dateFrom) || this.isNullOrUndefined(dateTo)) {
            return false;
        }
        return (
            dateFrom.getFullYear() > dateTo.getFullYear() ||
            (dateFrom.getFullYear() === dateTo.getFullYear() && dateFrom.getMonth() > dateTo.getMonth()) ||
            (dateFrom.getFullYear() === dateTo.getFullYear() &&
                dateFrom.getMonth() === dateTo.getMonth() &&
                dateFrom.getDate() > dateTo.getDate())
        );
    }

    /**
     * Returns the greater of two dates.
     * @param {Date} dateA - The first date to compare.
     * @param {Date} dateB - The second date to compare.
     * @returns {Date} Returns the greater date between `dateA` and `dateB`.
     */
    public static getGreaterDate(dateA: Date, dateB: Date): Date {
        if (this.dayGreaterThan(dateA, dateB)) {
            return dateA;
        } else {
            return dateB;
        }
    }


    public static removeTimezone(date: Date): Date {
        const userTimezoneOffset: number = date.getTimezoneOffset() * 60000;
        const dateNoTZ: Date = new Date(date.getTime() - userTimezoneOffset);
        return dateNoTZ;
    }

    public static getDateFromDateTime(date: Date): string {
        const localDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const newDate = localDate.toISOString().substr(0, 10);
        return newDate;
    }

    public static getHoursAndMinutes(date: Date): string {
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    }
    
    public static getFileOrOpenFromBlob(file: Blob, fileName: string) {
        const navigator: any = window.navigator;
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(file, fileName);
        } else {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    public static getDefaultOrFirstAddress(addressList: GeoMarker[]): GeoMarker {
        if (this.isNullOrUndefined(addressList)) {
            return undefined;
        } else {
            const defaultAddress: GeoMarker = addressList.find(address => address.default);
            if (!this.isNullOrUndefined(defaultAddress)) {
                return defaultAddress;
            } else {
                if (addressList.length > 0) {
                    return addressList[0];
                } else {
                    return undefined;
                }
            }
        }
    }

    public static setDefaultOrFirstAddress(addressList: GeoMarker[], defaultAddress: GeoMarker): GeoMarker[] {
        if (this.isNullOrUndefined(addressList)) {
            return [defaultAddress];
        } else {
            const defaultAddressIndex: number = addressList.findIndex(address => address.default);

            if (!this.isNullOrUndefined(defaultAddressIndex) && defaultAddressIndex !== -1) {
                addressList[defaultAddressIndex] = defaultAddress;
            } else {
                if (addressList.length > 0) {
                    addressList[0] = defaultAddress;
                } else {
                    return [defaultAddress];
                }
            }
        }
        return addressList;
    }

    public static validAddress(address: GeoMarker, gcdmCustomer = false): boolean {
        let valid = false;
        if (address) {
            valid = !(
                Utils.isNullOrUndefined(address.address) ||
                Utils.isNullOrUndefined(address.country) ||
                Utils.isNullOrUndefined(address.zipCode) ||
                (gcdmCustomer &&
                    (Utils.isNullOrUndefined(address.latitude) || Utils.isNullOrUndefined(address.longitude))) ||
                (!Utils.isNullOrUndefined(address.locationComponentList) &&
                    address.locationComponentList.filter(location => location.shortName != null).length === 0)
            );
        }
        return valid;
    }

    public static convertDurationToFRUs(duration: number) {
        if (duration) {
            return duration / 5;
        } else {
            return 0;
        }
    }

    public static convertFRUsToDuration(frus: number) {
        if (frus) {
            return frus * 5;
        } else {
            return 0;
        }
    }

    public static getFirstDayReturnAvailable(appointment: Appointment) {
        let firstDayReturnAvailable = appointment.endTime;
        appointment.activityList.forEach(activity => {
            if (!Utils.isNullOrUndefined(activity.activityDays)) {
                activity.activityDays.forEach(activityDay => {
                    if (activityDay.date.getTimezoneOffset() === 360) {
                        activityDay.date.setHours(0);
                    }
                    if (activityDay.date.getTime() > firstDayReturnAvailable.getTime()) {
                        firstDayReturnAvailable = activityDay.date;
                    }
                });
            }
        });
        return this.localDate(firstDayReturnAvailable);
    }

    public static createActivityDay(date: Date, duration: number): ActivityDay {
        const activityDay: ActivityDay = {};
        activityDay.date = Utils.toUTCDate(date);
        activityDay.duration = duration;
        return activityDay;
    }

    private static getDefaultActivityDayDuration(activity: Activity) {
        return Utils.isNullOrUndefined(activity.workTeam) ? 900 : activity.duration;
    }

    public static getLastActivityDay(activity: Activity): ActivityDay {
        if (activity.activityDays.length === 0) {
            return Utils.createActivityDay(activity.startTime, Utils.getDefaultActivityDayDuration(activity));
        } else {
            return activity.activityDays
                .sort((activityDayA, activityDayB) => (activityDayA.date > activityDayB.date ? 1 : -1))
                .reverse()[0];
        }
    }

    public static getFirstActivityDay(activity: Activity): ActivityDay {
        if (activity.activityDays.length === 0) {
            return Utils.createActivityDay(activity.startTime, Utils.getDefaultActivityDayDuration(activity));
        } else {
            return activity.activityDays.sort((activityDayA, activityDayB) =>
                activityDayA.date > activityDayB.date ? 1 : -1
            )[0];
        }
    }

    public static twoStringNumber(n: number): string {
        return ('00' + n.toString()).substr(-2);
    }

    public static getErrorMessage(e: BaseError) {
        return e.message + ' (' + e.code + ')';
    }

    public static showErrorPopup(error: HttpErrorResponse): void {
        if (!this.isNullOrUndefined(error.error.errors)) {
            const modal = this.retrieveSweetAlertOptionsFromHttpErrorResponse(error);
            modal.icon = 'error';
            swal.fire(modal);
        }
    }

    /**
     * Show an error message in a simple popup
     * @param error The error message to show
     */
    public static showSimpleErrorPopup(error: string): void {
        const modal = {
            text: error,
            heightAuto: false

        } as SweetAlertOptions;
        modal.icon = 'error';
        swal.fire(modal);
    }

    public static showPopup(error: HttpErrorResponse) {
        if (!this.isNullOrUndefined(error.error.errors)) {
            const modal = this.retrieveSweetAlertOptionsFromHttpErrorResponse(error);
            swal.fire(modal);
        }
    }

    private static retrieveSweetAlertOptionsFromHttpErrorResponse(error: HttpErrorResponse): SweetAlertOptions {
        return {
            title: error.error.errors[0].title,
            text: error.error.errors[0].message,
            icon: error.error.errors[0].errorType,
            heightAuto: false
        };
    }

    public static existPreferredOutlet(): boolean {
        const userPreference = this.retrieveUserPreference();
        return !this.isNullOrUndefined(userPreference.selectedOutlet);
    }

    public static retrieveSelectedOutlet(outlets: Outlet[]): Outlet {
        const userPreference = this.retrieveUserPreference();
        let result = outlets.find(outlet => outlet.id === userPreference.selectedOutlet);

        if (this.isNullOrUndefined(result)) {
            result = outlets[0];
        }

        return result;
    }

    public static retrieveSelectedBrandsFromUserPreference(outletId: string): VehicleBrand[] {
        const userPreference = this.retrieveUserPreference();
        if (
            userPreference &&
            userPreference.selectedVehicleBrands &&
            userPreference.selectedVehicleBrands.has(outletId)
        ) {
            return userPreference.selectedVehicleBrands.get(outletId);
        }
        return [];
    }

    public static storeSelectedBrandsIntoUserPreference(newUserBrands: VehicleBrand[], outletId: string): void {
        const userPreference = this.retrieveUserPreference();

        const userBrands: Map<string, VehicleBrand[]> =
            userPreference.selectedVehicleBrands || new Map<string, VehicleBrand[]>();
        userBrands.set(outletId, newUserBrands.length > 0 ? newUserBrands : []);

        const userData: {
            userId: string;
            selectedOutlet: string;
            selectedVehicleBrands: [string, VehicleBrand[]][];
        } = {
            userId: userPreference.userId,
            selectedOutlet: userPreference.selectedOutlet,
            selectedVehicleBrands: Array.from(userBrands.entries()),
        };

        this.setUserPreferenceIntoLocalStorage(userData);
    }

    public static setOutletIdIntoUserPreference(outletId: string): void {
        const userPreference = this.retrieveUserPreference();
        const userData: {
            userId: string;
            selectedOutlet: string;
            selectedVehicleBrands: [string, VehicleBrand[]][];
        } = {
            userId: userPreference.userId,
            selectedOutlet: outletId,
            selectedVehicleBrands: Array.from(userPreference.selectedVehicleBrands.entries()),
        };

        this.setUserPreferenceIntoLocalStorage(userData);
    }

    private static setUserPreferenceIntoLocalStorage(userPreference: any): void {
        localStorage.removeItem('userPreference');
        localStorage.setItem('userPreference', JSON.stringify(userPreference));
    }

    public static isFunctionalCookiesEnabled(): boolean {
        return this.isNullOrUndefined(window['epaas']) || window['epaas'].api.getUserConsent('strictlyNecessary') === 'GIVEN';
    }

    public static isAnalyticsCookiesEnabled(): boolean {
        return this.isNullOrUndefined(window['epaas']) || window['epaas'].api.getUserConsent('advertising') === 'GIVEN';
    }

    public static overwriteExistingUserPreferenceWithNewOne(userId: string): void {
        localStorage.removeItem('userPreference');
        if (Utils.isFunctionalCookiesEnabled()) {
            localStorage.setItem(
                'userPreference',
                JSON.stringify({
                    userId: userId,
                })
            );
        }
    }

    public static retrieveUserPreference(): UserPreference {
        const userData: {
            userId: string;
            selectedOutlet?: string;
            selectedVehicleBrands?: [string, VehicleBrand[]][];
        } = JSON.parse(localStorage.getItem('userPreference'));
        return {
            userId: (userData && userData.userId) || null,
            selectedOutlet: (userData && userData.selectedOutlet) || null,
            selectedVehicleBrands:
                (userData &&
                    userData.selectedVehicleBrands &&
                    new Map<string, VehicleBrand[]>(userData.selectedVehicleBrands)) ||
                new Map<string, VehicleBrand[]>(),
        } as UserPreference;
    }

    /**
     * Checks if the given brand is a type of Motorrad.
     * @param brand the vehicle brand to check 
     * @returns {@code true} if the brand is a Motorrad; {@code false} otherwise
     */
    public static isMotorrad(brand: VehicleBrand): boolean {
        return (
            brand === <VehicleBrand>'BMW_MOTORRAD' ||
            brand === <VehicleBrand>'MOT' ||
            brand === <VehicleBrand>'MOTORBIKE'
        );
    }

    /**
     * Checks if the given appointment is for a Motorrad vehicle.
     *
     * @param appointment the appointment to check
     * @return {@code true} if the appointment is for a Motorrad vehicle; {@code false} otherwise
     */
    public static isMotorradAppointment(appointment: Appointment): boolean {
        if(this.isNullOrUndefined(appointment.vehicleBrand)){
            return false;
        }
        return this.isMotorrad(appointment.vehicleBrand);
    }

    public static calculateTimeslotFromFastLaneDuration(fastLaneDuration: any, appointmenttimeSlots: String) {
        const hours = Math.floor(fastLaneDuration / 3600);
        const tminutes = Math.floor(fastLaneDuration / 60);
        const hminutes = Math.floor(hours * 60);
        const minutes = tminutes - hminutes;

        const timeSlot = appointmenttimeSlots.split('-');
        const endTimeSlot = timeSlot[0].split(':');

        let hoursTimeSlot = Number(endTimeSlot[0]) + hours;
        let minutesTimeSlot = Number(endTimeSlot[1]) + minutes;

        if (minutesTimeSlot >= 60) {
            minutesTimeSlot = minutesTimeSlot - 60;
            hoursTimeSlot = hoursTimeSlot + 1;
        }

        const result =
            (hoursTimeSlot < 10 ? '0' : '') + hoursTimeSlot + ':' + (minutesTimeSlot < 10 ? '0' : '') + minutesTimeSlot;

        timeSlot[1] = result;

        return timeSlot;
    }

    public static hashSha1(value: any): string {
        if (!Utils.isNullOrUndefined(value)) {
            return sha1(value);
        } else {
            return null;
        }
    }

    public static getTechnicalSettings(
        technicalSettingService: TechnicalSettingService,
        technicalSettingConfiguration: TechnicalSettingConfiguration
    ) {
        return () =>
            technicalSettingService.getAll().then(technicalSettingDTOs => {
                technicalSettingDTOs.forEach(technicalSettingDTO =>
                    technicalSettingConfiguration.setTechnicalSetting(
                        technicalSettingDTO.name,
                        technicalSettingDTO.value
                    )
                );
                return technicalSettingDTOs;
            });
    }

    public static handleError(error: any): Promise<any> {
        return Promise.reject(error);
    }

    /**
     * Checks if a given skill exists in the outlet's skills array.
     *
     * @param {Outlet} outlet The outlet object to check for the skill.
     * @param {string} skill The skill to check for in the outlet's skills array.
     * @returns {boolean} true if the outlet has the specified skill, otherwise false.
     */
    public static outletHasSkill(outlet: Outlet, skill: string): boolean {
        return this.isNotNullOrUndefined(outlet) && 
            this.isNotEmptyArray(outlet.skills) &&
            outlet.skills.some(s => s.id === skill);
    }

    /**
     * Builds a complete URL with query parameters for an AWP icon.
     * @param {string} awpIcon - The awpIcon configuration.
     * @param {string} vin - The vin value for query parameter named vin.
     * @returns {string} - The complete URL with query parameters.
     */
    public static buildAwpIconLink(awpIcon: AwpIcon, vin: string): string {
        return awpIcon.url.replace('${vin}', vin);
    }
}
