<form id="vehicle-form" [formGroup]="vehicleForm" [ngClass]="formClass">
    <!-- Vin -->
    <div class="col-md-6" *ngIf="isManual != 0">
        <div class="ui-input-wrapper">
            <label *ngIf="showLabelsOverInput" i18n="@@vin">Vin</label>
            <input
                type="text"
                class="ui-input login-form__input registration__icon--empty"
                formControlName="vin"
                [tooltip]="vinTooltip"
                i18n-placeholder="@@vinNumber"
                placeholder="Vin number"
                [class.submitted]="submitted"
                (change)="searchVehicleOnChangeInput($event.target.value)"
                [readonly]="isData"
            />
        </div>
    </div>
    <!-- Plate -->
    <div class="col-md-6">
        <div class="ui-input-wrapper">
            <label *ngIf="showLabelsOverInput" i18n="@@plate">License Plate</label>
            <input
                type="text"
                class="ui-input login-form__input registration__icon--empty"
                [class.submitted]="submitted"
                formControlName="plate"
                [tooltip]="countryConfiguration.countryCode !== 'ES' ? plateTooltip : null"
                i18n-placeholder="@@licencePlate"
                placeholder="Licence plate"
                #input
                (input)="input.value = $event.target.value.toUpperCase()"
                (change)="searchVehicleOnChangeInput($event.target.value)"
                [readonly]="isData"
            />
        </div>
        <!-- Plate Validation Errors -->
        <div>
            <ng-container *ngTemplateOutlet="plateValidationError; context: { control: vehicleForm.get('plate') }">
            </ng-container>
        </div>
    </div>
    <!-- Fiscal Number -->
    <div class="col-md-6" *ngIf="countryConfiguration.showFiscalNumberVehicleForm && isManual == 0 && !isData">
        <div class="ui-input-wrapper">
            <label *ngIf="showLabelsOverInput" i18n="@@fiscalCode">Fiscal code</label>
            <input
                type="text"
                class="ui-input login-form__input registration__icon--empty"
                formControlName="fiscalNumber"
                i18n-placeholder="@@fiscalNumber"
                placeholder="Fiscal Number"
                [class.submitted]="submitted"
                (change)="searchVehicleOnChangeInput($event.target.value)"
            />
        </div>
        <!-- Fiscal Number Errors -->
        <div>
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: vehicleForm.get('fiscalNumber') }"
            >
            </ng-container>
        </div>
    </div>

    <div class="col-md" *ngIf="searchButtonEnabled">
        <button id="proceedVehicleBtn" class="ui-button btn btn-primary Continue-button" i18n="@@continue" (click)="searchVehicle()" [disabled]="!tcModalClosed">
            Continue
        </button>
    </div>

    <!-- Vehicle Model -->
    <div class="col-md-6" *ngIf="isData">
        <div class="ui-input-wrapper">
            <div class="input-group assistance-search dealer__assistance-search">
                <input
                    #modelLabel
                    type="text"
                    class="form-control ui-typeahead"
                    i18n-placeholder="@@searchForModel"
                    placeholder="Search for model"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="off"
                    [typeahead]="vehicles"
                    [typeaheadScrollable]="true"
                    formControlName="searchCarControl"
                    [typeaheadOptionsInScrollableView]="5"
                    typeaheadOptionField="modelLabel"
                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    [attr.disabled]="!isCatalogLoaded || vehicle.id ? 'disabled' : null"
                    [class.submitted]="submitted"
                    [readonly]="isData"
                />
                <span class="clear-input">
                    <button
                        *ngIf="!vehicle.id && searchCarControl.value"
                        (click)="searchCarControl.reset()"
                        class="btn btn-default button--clear"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <!-- Vehicle Model & Transmission Validation Errors -->

    <!-- Matriculation date -->
    <div class="col-md-6" *ngIf="isData">
        <div class="ui-input-wrapper">
            <input
                type="text"
                class="ui-input login-form__input registration__icon--empty"
                bsDatepicker
                #dp="bsDatepicker"
                [bsConfig]="bsConfig"
                i18n-placeholder="@@matriculationDate"
                placeholder="Matriculation date"
                formControlName="matriculationDate"
                [class.submitted]="submitted"
            />
        </div>
    </div>
</form>

<ng-template #insertFiscalCode>
    <div class="modal-header services__modal-header" *ngIf="isData">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('oas-fiscal-code')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@pickupFiscalCode">Insert Fiscal Code/Vat Number</h4>
    </div>
</ng-template>

<ng-template #requiredValidationError let-control="control">
    <div
        *ngIf="control.invalid && (control.dirty || control.touched || submitted) && control.errors.required"
        class="ui-input-wrapper"
    >
        <div class="errors">
            <p i18n="@@required">Required!</p>
        </div>
    </div>
</ng-template>

<ng-template #plateValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
            <p *ngIf="control.errors.plate" i18n="@@notCompliantPlate">
                Plate not compliant!
            </p>
            <p *ngIf="control.errors.plateOfNotAllowedBrand" i18n="@@invalidLicensePlate">
                Invalid license plate
            </p>
        </div>
    </div>
</ng-template>

<ng-template #vinTooltip>
    <div style="text-align: left;">
        <span i18n="@@vinRules">VIN number must match one of the following formats:</span>
        <ul>
            <li>
                17 <span i18n="@@characters">characters</span> ( <span i18n="@@numbers">numbers</span>/
                <span i18n="@@letters">letters</span>)
            </li>
        </ul>
    </div>
</ng-template>

<ng-template #plateTooltip>    
    <div style="text-align: left;">
        <span i18n="@@plateRules">Plate can only contain letters and digits</span>
    </div>
</ng-template>

<oas-alert-confirm></oas-alert-confirm>
<oas-alert-warning></oas-alert-warning>

<ng-template #plateInfoContent>
    <div class="row">
        <span i18n="@@vehicleNotRecognizedText"
            >Your vehicle was not recognized for central system. Please try another time or fill the basic data
            manually</span
        >
    </div>
    <div class="row">
        <button *ngIf="step == '1'" class="btn btn-primary ui-button modals-button" (click)="retry(1)" i18n="@@retry">
            Try Again
        </button>
        <button *ngIf="step == '1'" class="btn btn-primary ui-button modals-button" (click)="manual(1)" i18n="@@manual">
            Fill Manually
        </button>
        <button *ngIf="step == '2'" class="btn btn-primary ui-button modals-button" (click)="retry(2)" i18n="@@retry">
            Try Again
        </button>
        <button
            *ngIf="countryConfiguration.showAppointmentWithoutRegistrationVehicleForm && step == '2'"
            class="btn btn-primary ui-button modals-button"
            (click)="goTo()"
            i18n="@@withoutRegistration"
        >
            appointment Without Registration
        </button>
    </div>
</ng-template>

<ng-template #warningTitleTmpl>
    <span i18n="@@warning">Warning</span>
</ng-template>

<ng-template #vehicleInformationNotAvailableDescriptionTmpl>
    <div class="row">
        <span>{{ vehicleInformationNotAvailableDescription }}</span>
    </div>
</ng-template>

<ng-template #warningChangeWebsiteToTheAppropriateBrandTitle>
    <span></span>
</ng-template>

<ng-template #warningChangeWebsiteToTheAppropriateBrandContentTmpl>
    <span i18n="@@changeWebsiteToTheAppropriateBrand"
        >Please, in order to offer you the best experience, refer to your vehicle’s brand specific website to create an
        appointment online.</span
    >
</ng-template>
