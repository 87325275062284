import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { AuthService } from '../../auth/auth.service';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { CountryConfiguration } from '../../country-configuration';
import { dataAnalytics } from '../data-analytics';
import { TracyService } from '../tracy.service';
import {BusinessSettingType} from "../entities";
import {BusinessSettingService} from "../../core/api/business-setting.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TechnicalSettingService} from "../../core/api/technical-setting.service";
import { AppComponent } from '../../app.component';

@Component({
   selector: 'oas-welcome',
   templateUrl: './welcome.component.html',
   styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit, OnDestroy {
   countryConfiguration: CountryConfiguration;
   showForm = false;
   loginUrl: string;
   registerUrl: string;
   redirect_uri = encodeURIComponent("https://"+window.location.href.split("/")[2]);
   scope = 'authenticate_user';
   response_type = 'code';
   ssoUrl = window.location.hostname.includes("-int") || window.location.hostname.includes("-dev") ? 'https://customer-i.bmwgroup.com/oneid/#/' : 'https://customer.bmwgroup.com/oneid/#/';
   ssoTokenUrl = window.location.hostname.includes("-int") || window.location.hostname.includes("-dev") ? 'https://customer-i.bmwgroup.com/oneid/' : 'https://customer.bmwgroup.com/oneid/';
   client = 'oas';

   currentBrand = 'BMW';
   hostname: string;
   consentToken: string;
   acidCode: string;

   error: string;
   state: string;


   public loginPageBannerTitleSetting: BusinessSettingType = 'LOGIN_PAGE_BANNER_TITLE';
   public loginPageBannerTextSetting: BusinessSettingType = 'LOGIN_PAGE_BANNER_TEXT';
   public loginPageBannerTitle: string;
   public loginPageBannerText: string;
   public accessToken: string;
   public code: string;
   public ito: string;
   public local: string;
   public isCookieHeightAdjusted = false;

   private expiresIn: string;


   constructor(
      private router: Router,
      private styleSwitcherService: StyleSwitcherService,
      countryConfigurationService: CountryConfigurationService,
      private tracyService: TracyService,
      private businessSettingService: BusinessSettingService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private technicalSettingService: TechnicalSettingService,
      private appComponent: AppComponent
   ) {
      this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
      this.hostname = window.location.hostname;
      this.local = this.countryConfiguration.locale;
      this.setDataAnalytics();
   }

   
   ngOnInit(): void {
      this.appComponent.isWelcomePage = true;

      this.route.fragment.subscribe(fr => {
         this.accessToken = new URLSearchParams(fr).get('access_token');
         this.error = new URLSearchParams(fr).get('error');
         this.state = new URLSearchParams(fr).get('state');
         this.expiresIn = new URLSearchParams(fr).get('expires_in');
      });

      this.route.queryParams.subscribe(params => {
         this.expiresIn = params['expires_in'];
         this.code = params['code'];
         this.ito = params['ito'];
         if (params['outlet']) {
            this.technicalSettingService.findByName("PRESELECT_OUTLET_FROM_DEALERS_WEBSITE").then(response => {
               if(response.value == "true"){
                  sessionStorage.setItem('preselectedOutletInfo', params['outlet']);
               }
            });
         }
      });

      if(this.accessToken != null){
         this.authService
            .gcdmLogin(this.accessToken, Number(this.expiresIn))
            .then((user: any) => {
               if (this.authService.isAuthorized()) {
                  this.router.navigate([this.authService.getDashboardPath()]);
               }
            })
            .catch((error: HttpErrorResponse) => {});
      } else if(this.code != null) {
         this.authService
            .gcdmLoginWithCode(this.code, "https://"+window.location.href.split("/")[2], Number(this.expiresIn))
            .then((user: any) => {
               if (this.authService.isAuthorized()) {
                  this.router.navigate([this.authService.getDashboardPath()]);
               }
            })
            .catch((error: HttpErrorResponse) => {});
      } else if(this.ito != null){
         window.location.href = this.urlSsoBuilder('login') + '#id_token=' + this.ito;
      } else {
         this.getCurrentBrand();
         this.getBannerInformation();
      }
   }

   ngOnDestroy(): void {
      this.appComponent.isWelcomePage = false;
   }

   getCurrentBrand() {
      if (this.hostname.includes('.mini.')) {
         this.currentBrand = 'MINI';
      } else if (this.hostname.includes('.bmw-motorrad.')) {
         this.currentBrand = 'BMW_MOTORRAD';
      } else {
         this.currentBrand = 'BMW';
      }
   }

   isAppointmentWithoutRegistrationEnabled(): boolean {
      return this.countryConfiguration[this.styleSwitcherService.brand.getValue().toUpperCase()].quickAppointment;
   }
   changeShowForm() {
      this.showForm = true;
      this.setDataAnalyticsForAdvantagesPopup();
   }
   setDataAnalyticsForAdvantagesPopup() {
      this.tracyService.setPageInformationIntoDataAnalytics(
         dataAnalytics.page.pageID.lOasGuestVehicle,
         dataAnalytics.page.variant.virtual
      );
      this.tracyService.setUserInformationIntoDataAnalytics('na');

      this.tracyService.sendPageView(dataAnalytics.referenceName.advantagesPopup);
   }
   private setDataAnalytics() {
      this.tracyService.setComponentInformationIntoDataAnalytics(
          dataAnalytics.component.componentId.localStandaloneOas,
          '1'
      );

      this.tracyService.setPageInformationIntoDataAnalytics(
          dataAnalytics.page.pageID.lOasLogin,
          dataAnalytics.page.variant.real
      );
      this.tracyService.setUserInformationIntoDataAnalytics('na');

      this.tracyService.sendPageView(dataAnalytics.referenceName.login);
  }
   closeModal() {
      this.showForm = false;
   }
   goToQuickAppointment() {
      const path = this.checkEmailFirstURL() ? '../../quick-appointment' : '../quick-appointment';
      this.router.navigate([path]);
   }
   checkEmailFirstURL(): boolean {
      return this.authService.getTicketId() != null && this.authService.getTicketId() != '';
   }
   goToRegister() {
      const path = this.checkEmailFirstURL() ? '../../registration' : '../registration';
      this.router.navigate([path]);
   }
   isMINI() {
      return this.currentBrand === 'MINI';
   }

   returnAcidCode(type) {
      if (!this.acidCode && window['epaas'] && window['epaas'].api.isInitialized() && window['epaas'].api.getCookie('AMCV_B52D1CFE5330949C0A490D45%40AdobeOrg')) {
         this.acidCode = window['epaas'].api.getCookie('AMCV_B52D1CFE5330949C0A490D45%40AdobeOrg').split('|')[2];
      }

      if (this.acidCode) {
         return '&acid=' + this.acidCode;
      } else {
         return '';
      }
   }

   returnConsentTokenString(type) {
      if (!this.consentToken && window['epaas'] && window['epaas'].api.isInitialized()) {
         this.consentToken = window['epaas'].api.createConsentToken();
      }

      if (this.consentToken) {
         return '&epaas_consenttoken=' + this.consentToken;
      } else {
         return '';
      }
   }

   returnClient(type) {
      let valueClient = '?client=';

      if (type === 'login') {
         return valueClient + this.client;
      } else {
         switch(this.currentBrand) {
            case 'BMW':
               return valueClient + 'bmwwebcom';
            case 'BMW_MOTORRAD':
               return valueClient + 'bmwmotorrad';
            case 'MINI':
               return valueClient + 'miniwebcom';
         }
      }
   }

   urlBuilder(type: string) {
      const urlType = type === 'login' ? 'login' : 'register';

      return this.ssoUrl +
         urlType +
         this.returnClient(type) +
         '&icp=' + this.client +
         '&country=' +
         this.countryConfiguration.countryCode +
         '&language=' +
         this.countryConfiguration.language +
         '&brand=' +
         this.getCurrentBrandToLowercase(this.currentBrand) +
         '&scope=' +
         this.scope +
         '&redirect_uri=' +
         this.redirect_uri +
         '&response_type=' +
         this.response_type +
         '&state=1' + this.returnConsentTokenString(type) + this.returnAcidCode(type);
   }

   urlSsoBuilder(type: string) {
      const urlType = type === 'login' ? 'login' : 'register';

      return this.ssoTokenUrl +
         urlType +
         this.returnClient(type) +
         '&icp=' + this.client +
         '&country=' +
         this.countryConfiguration.countryCode +
         '&language=' +
         this.countryConfiguration.language +
         '&brand=' +
         this.getCurrentBrandToLowercase(this.currentBrand) +
         '&scope=' +
         this.scope +
         '&redirect_uri=' +
         this.redirect_uri +
         '&response_type=' +
         this.response_type +
         '&state=1' + this.returnConsentTokenString(type) + this.returnAcidCode(type);
   }

   public getBannerInformation() {
      this.getBannerTitleInformation();
      this.getBannerTextInformation();
   }

   public getBannerTitleInformation() {
      this.businessSettingService.getSetting(this.loginPageBannerTitleSetting).then(response => {
         this.loginPageBannerTitle = response.value;
      });
   }

   public getBannerTextInformation() {
      this.businessSettingService.getSetting(this.loginPageBannerTextSetting).then(response => {
         const currentBrand = this.styleSwitcherService.brand.getValue();
         let loginPageBannerText = response.value;
         loginPageBannerText = loginPageBannerText
            .split('{BRAND}')
            .join(currentBrand.toUpperCase().replace('_', ' '));
         loginPageBannerText = loginPageBannerText.split('{brand}').join(currentBrand.replace('_', '-'));
         this.loginPageBannerText = loginPageBannerText;
      });
   }

   navigateToLogin() {
      this.router.navigate(['/backoffice/login']);
   }

   getCurrentBrandToLowercase(brand: string){
      if(brand == "BMW_MOTORRAD"){
         return "bmwmotorrad";
      }
      return brand.toLowerCase();
   }

   showNewBackgroundImage(){
      return ['it-IT', 'es-ES', 'pt-PT'].includes(this.local);
   }
}
