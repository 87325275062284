<div *ngIf="appointment" class="wizard-steps summary__wizard-steps">
    <div class="row">
        <div class="col-md-5">
            <div class="wizard-steps__step wizard-steps__step--transparent summary__your-data">
                <table class="deatail-popup-banner">
                    <thead class="head-align">
                        <th colspan="2">
                            <div>
                                <span i18n="@@customerData">Customer data</span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span i18n="@@name">Name</span>
                            </td>
                            <td>
                                {{
                                    appointment?.fromMobileAppConnectedDrive
                                        ? appointment?.customerName + ' ' + appointment?.customerSurname
                                        : appointment?.customer?.displayName
                                }}
                            </td>
                        </tr>
                        <tr *ngIf="!appointment?.fromMobileAppConnectedDrive">
                            <td>
                                <span i18n="@@vatId">VAT ID</span>
                            </td>
                            <td>
                                {{ appointment?.customerVatNumber }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span i18n="@@email">Email</span>
                            </td>
                            <td>
                                {{
                                    appointment?.fromMobileAppConnectedDrive
                                        ? appointment?.customerEmail
                                        : appointment?.customer?.email
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span i18n="@@phone">Phone</span>
                            </td>
                            <td>
                                {{
                                    appointment?.fromMobileAppConnectedDrive
                                        ? appointment?.customerPhoneNumber
                                        : appointment?.customer?.phoneNumber
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span i18n="@@address">Address</span>
                            </td>
                            <td>
                                {{
                                    appointment?.fromMobileAppConnectedDrive
                                        ? appointment?.customerAddressStreet +
                                          ' ' +
                                          appointment?.customerAddressHouseNumber
                                        : appointment?.customer?.address?.address
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <oas-pre-registration-checkbox
                    [email]="appointment?.customer.email"
                    (valueUpdated)="updateValuePreRegistration($event)"
                ></oas-pre-registration-checkbox>
            </div>
        </div>
        <div class="col-md-3">&nbsp;</div>
        <div class="col-md-4">
            <div class="wizard-steps__step wizard-steps__step--transparent summary__your-data">
                <table class="deatail-popup-banner">
                    <thead class="head-align">
                        <th colspan="2">
                            <div>
                                <span i18n="@@vehicleData">Vehicle data</span>
                            </div>
                        </th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <span i18n="@@brand">Brand</span>
                            </td>
                            <td>
                                {{ appointment?.vehicle.model.brand }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span i18n="@@model">Model</span>
                            </td>
                            <td>
                                {{ appointment?.vehicle.model.modelLabel }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span i18n="@@plate">Plate</span>
                            </td>
                            <ng-container *ngIf="readOnlyPlate">
                                <td>
                                    {{ appointment?.vehicle.plate }}
                                </td>
                            </ng-container>
                            <ng-container *ngIf="!readOnlyPlate">
                                <td>
                                    <div class="ui-input-wrapper">
                                        <input
                                            type="text"
                                            class="ui-input"
                                            [formControl]="plate"
                                            [tooltip]="plateTooltip"
                                            i18n-placeholder="@@licencePlate"
                                            placeholder="License plate"
                                            (keyup)="onKeyUpPlate($event)"
                                        />
                                        <ng-container
                                            *ngTemplateOutlet="plateValidationError; context: { control: plate }"
                                        ></ng-container>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                        <tr *ngIf="countryConfiguration.showVinOnlineAppointmentResume">
                            <td>
                                <span i18n="@@vin">Vin</span>
                            </td>
                            <td>
                                {{ appointment?.vehicle.vin }}
                            </td>
                        </tr>
                        <tr *ngIf="appointment?.vehicle.matriculationDate">
                            <td>
                                <span i18n="@@matriculationDate">Matriculation date</span>
                            </td>
                            <td>
                                {{ appointment?.vehicle.matriculationDate | date: 'dd/MM/yyyy' }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span i18n="@@approxkm">Approx. kilometers</span>
                            </td>
                            <td>
                                <div class="ui-input-wrapper">
                                    <input
                                        id="mileage-input"
                                        type="text"
                                        maxlength="7"
                                        class="ui-input"
                                        [formControl]="mileage"
                                        (keyup)="onKeyUpMileage($event)"
                                        i18n-placeholder="@@mileage"
                                        placeholder="{{ placeHolderMileage }}"
                                    />
                                    <ng-container
                                        *ngTemplateOutlet="mileageValidationError; context: { control: mileage }"
                                    ></ng-container>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ng-container
                    *ngIf="enableRemoteKeyRead && (isRemoteKeyReadAlreadyAccepted || isVehicleWithRemoteKeyRead)"
                >
                    <div class="ui-checkbox left">
                        <input
                            *ngIf="!appointment?.id || (appointment?.id && !isRemoteKeyReadAlreadyAccepted)"
                            type="checkbox"
                            (change)="accept($event.target.checked)"
                        />

                        <label>
                            <i
                                *ngIf="appointment?.id && isRemoteKeyReadAlreadyAccepted"
                                class="fa fa-check"
                                aria-hidden="true"
                            ></i>
                            <span i18n="@@remoteKeyRead">
                                I give my consent to use the “Remote KeyRead” functionality for this appointment. For
                                more information, please
                            </span>
                            <a [href]="remoteKeyReadLink" target="_blank" i18n="@@clickHere"> click here </a>
                        </label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="wizard-steps__step">
                <div class="wizard-steps__step-name wizard_steps__step__icon-top">
                    <div class="text-center">
                        <i class="bmw bmw-wizard_ico_service icon-lg"></i>
                    </div>
                    <span i18n="@@services">Services</span>
                </div>
                <div class="wizard-steps__step-description">
                    <div class="row" *ngIf="appointment.fastLane">
                        <span i18n="@@fastLane">Fast lane</span>
                        <span>
                            {appointment?.vehicleBrand, select, BMW {BMW} BMW_I {BMWi} MINI {MINI} BMW_MOTORRAD {BMW
                            Motorrad}}</span
                        >
                    </div>
                    <div class="row" *ngIf="appointment.waitInOutlet" i18n="@@waitInOutlet">Wait in outlet</div>
                    <div *ngFor="let activity of appointment.activityList | filter: filterPredicate; let isLast = last">
                        <div class="row">{{ activity.name }}</div>
                    </div>
                    <div class="text-right">
                        <button
                            *ngIf="!noEdit"
                            class="btn btn-primary button--clear button--nopadding"
                            (click)="goToStep(0)"
                            i18n="@@edit"
                        >
                            EDIT
                            <i class="fa fa-pencil"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="wizard-steps__step">
                <div class="wizard-steps__step-name wizard_steps__step__icon-top">
                    <div class="text-center">
                        <i class="bmw bmw-wizard_ico_dealer icon-lg"></i>
                    </div>
                    <span i18n="@@dealer">Dealer</span>
                </div>
                <div class="wizard-steps__step-description">
                    <div class="row">{{ appointment.outlet?.businessName }}</div>
                    <div class="row">{{ appointment.outlet?.address.address }}</div>
                    <div class="row">{{ appointment.outlet?.address.stairInt }}</div>
                    <div class="row">{{ appointment.outlet?.phoneNumber }}</div>
                    <div class="text-right">
                        <button
                            *ngIf="!noEdit"
                            class="btn btn-primary button--clear button--nopadding"
                            (click)="goToStep(1)"
                            i18n="@@edit"
                        >
                            EDIT
                            <i class="fa fa-pencil"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="!appointment.waitInOutlet" class="wizard-steps__step" style="margin-top: 15px;">
                <div class="wizard-steps__step-name" i18n="@@mobilityServices">
                    Mobility Services
                </div>
                <div class="wizard-steps__step-description">
                    <div *ngFor="let mobility of appointment.mobilityList; let isLast = last">
                        <div class="row vertical-spacing">{{ mobility.mobilityType.descriptionLocalized }}</div>
                        <div class="vertical-spacing" *ngIf="mobility?.pickup">
                            <div class="row"><span i18n="@@pickup">Pick-up</span>:</div>
                            <div class="row">
                                <i [tooltip]="mobilityPickTooltip"
                                    >{{ mobility.address.address }} {{ mobility.address.stairInt }} -
                                    {{ mobility.address.zipCode }} - {{ mobility.address.country.code }}</i
                                >
                            </div>
                        </div>
                        <div class="vertical-spacing" *ngIf="mobility?.delivery">
                            <div class="row"><span i18n="@@delivery">Delivery</span>:</div>
                            <div class="row">
                                <i [tooltip]="mobilityReturnTooltip"
                                    >{{ mobility.deliveryAddress.address }} {{ mobility.deliveryAddress.stairInt }} -
                                    {{ mobility.deliveryAddress.zipCode }} -
                                    {{ mobility.deliveryAddress.country.code }}</i
                                >
                            </div>
                        </div>
                        <div class="vertical-spacing" *ngIf="mobility?.vehicle?.plate">
                            <div class="row"><span i18n="@@plate">Plate</span>:</div>
                            <div class="row">
                                <i>{{ mobility.vehicle.plate }}</i>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button
                            *ngIf="!noEdit"
                            class="btn btn-primary button--clear button--nopadding"
                            (click)="goToStep(2)"
                            i18n="@@edit"
                        >
                            EDIT
                            <i class="fa fa-pencil"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="wizard-steps__step">
                <div class="wizard-steps__step-name wizard_steps__step__icon-top">
                    <div class="text-center">
                        <i class="bmw bmw-wizard_ico_schedule icon-lg"></i>
                    </div>
                    <span i18n="@@schedule">Schedule</span>
                </div>
                <div class="wizard-steps__step-description">
                    <p>
                        {{ appointment.startTime | datex: 'medium' }}
                    </p>
                </div>
            </div>
            <div class="wizard-steps__step">
                <div class="wizard-steps__step-name" i18n="@@yourReferent">
                    Your referent
                </div>
                <div class="wizard-steps__step-description">
                    <div class="service-advisor__tab__wizard-steps">
                        <div
                            *ngIf="
                                !appointment?.serviceAdvisor?.profilePic ||
                                    !appointment?.serviceAdvisor?.privacyAgreement;
                                else picture
                            "
                            class="service-advisor__tab-image service-advisor__tab-image--empty"
                        >
                            <i class="fa fa-user"></i>
                        </div>
                        <ng-template #picture>
                            <img
                                [src]="appointment?.serviceAdvisor?.profilePic"
                                class="service-advisor__tab-image service-advisor__tab-image--full service-advisor__tab-image--full-margin"
                            />
                        </ng-template>
                        <div class="service-advisor__info">
                            <p class="vertical-spacing-xs service-advisor__info__name ellipsis-text-overflow">
                                {{
                                    appointment.serviceAdvisor?.privacyAgreement
                                        ? appointment.serviceAdvisor?.displayName
                                        : ((appointment.serviceAdvisor?.name)[0] +
                                              (appointment.serviceAdvisor?.surname)[0] | uppercase)
                                }}
                            </p>
                            <p class="vertical-spacing-xs service-advisor__info__name ellipsis-text-overflow">
                                <span i18n="@@phone">Phone</span>: {{ appointment.serviceAdvisor?.mobilePhone }}
                            </p>
                            <p class="vertical-spacing-xs service-advisor__info__name ellipsis-text-overflow">
                                <span i18n="@@email">Email</span>: {{ appointment.serviceAdvisor?.email }}
                            </p>
                        </div>
                    </div>
                    <div class="summary-resume__edit">
                        <button
                            *ngIf="!noEdit"
                            class="btn text-primary button--clear"
                            (click)="goToStep(3)"
                            i18n="@@edit"
                        >
                            EDIT
                            <i class="fa fa-pencil"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-8 col-sm-12 col-xs-12"></div>
    </div>
    <!--<div class="row">
        <div class="col-md-8 col-sm-12 col-xs-12">
            <div class="wizard-steps__step summary-notes">
                <div class="wizard-steps__step-name" i18n="@@notesAndAdditional">
                    Notes & Additional Requests
                </div>
                <div class="wizard-steps__step-description">
                    <textarea [disabled]="noEdit" [(ngModel)]='appointment.note' name="additional_requests" id="" cols="30" rows="5" i18n-placeholder="@@typeHere"
                        placeholder="type here..."></textarea>
                </div>
            </div>
        </div>

    </div>-->

    <div class="row">
        <div class="col-md-8 col-sm-12 col-xs-12">
            <div class="wizard-steps__step summary-notes">
                <div class="wizard-steps__step-name" i18n="@@notesAndAdditional">
                    Notes & Additional Requests
                </div>
                <div class="wizard-steps__step-description">
                    <textarea
                        [formControl]="note"
                        [(ngModel)]="appointment.note"
                        name="additional_requests"
                        id=""
                        cols="30"
                        rows="5"
                        i18n-placeholder="@@typeHere"
                        placeholder="type here..."
                    ></textarea>
                    <ng-container *ngTemplateOutlet="noteValidationError; context: { control: note }"></ng-container>
                    <p *ngIf="attachments && attachments.length > 0" i18n="@@attachments">Attachments</p>
                    <div class="row" style="padding-left: 10px;" *ngFor="let attachment of attachments">
                        <div class="attachments-container">
                            <a [href]="attachmentsUrl + '/' + attachment.path" target="_blank">
                                {{ attachment.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4"  *ngIf="showReturnDate">
            <div class="wizard-steps__step">
                <div class="wizard-steps__step">
                    <div class="wizard-steps__step-name" i18n="@@desiredPickUpHour">
                        Desired Pick up hour
                    </div>
                    <div class="wizard-steps__step-description">
                        <div class="ui-select-wrapper">
                            <select
                                class="ui-select"
                                (change)="switchTimeframe($event.target.value)"
                                [(ngModel)]="selectedTimeframe"
                            >
                                <option
                                    value="Morning"
                                    i18n="@@morning"
                                    *ngIf="appointment.outlet.morningDeliveryEnabled"
                                    >Morning</option
                                >
                                <option
                                    value="Afternoon"
                                    i18n="@@afternoon"
                                    *ngIf="appointment.outlet.afternoonDeliveryEnabled"
                                    >Afternoon</option
                                >
                            </select>
                        </div>
                        <p>{{ slideTimeInfo }}</p>
                        <div class="delivery-slider">
                            <nouislider
                                #sliderRef
                                [config]="sliderConfig"
                                [ngModel]="startTimeRange"
                                (update)="setSlide($event)"
                            ></nouislider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4"  *ngIf="showReturnDate">
            <div class="wizard-steps__step">
                <div class="wizard-steps__step">
                    <div class="wizard-steps__step-name" i18n="@@msgPickUpHour">
                        The vehicle pickup can be done at this time depending on the duration of the service and the
                        availability of your advisor. Please confirm this time with your advisor during the
                        {{ appointment?.vehicle.model.brand | translate }}
                        reception.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #plateValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.plate" i18n="@@notCompliantPlate">
                Plate not compliant!
            </p>
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #mileageValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.pattern" i18n="@@invalidMileage">
                Invalid mileage!
            </p>
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #noteValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.pattern" i18n="@@invalidNote">
                Invalid note!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #plateTooltip>
    <div style="text-align: left;">
        <span i18n="@@plateRules">Plate can only contain letters and digits</span>
    </div>
</ng-template>
