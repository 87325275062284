import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { CountryConfiguration } from '../../country-configuration';
import { Vehicle } from './../../shared/entities';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperContainer } from 'swiper/element';

@Component({
    selector: 'oas-vehicle-carousel',
    templateUrl: './vehicle-carousel.component.html',
})
export class VehicleCarouselComponent implements OnInit, AfterViewInit {
    @ViewChild('swiper', { static: false }) swiper?: ElementRef<SwiperContainer>;

    @Input() public vehicles: Vehicle[];
    @Input() public swiperIndex: number;
    @Output() private swiperIndexChange = new EventEmitter<number>();
    @Output() private select = new EventEmitter<Vehicle>();
    public config: SwiperOptions = {
        direction: 'horizontal',
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        keyboard: true,
        slidePrevClass: '.swiper-button-prev',
        slideNextClass: '.swiper-button-next',
    };
    public showSpinner = true;
    public countryConfiguration: CountryConfiguration;
    public constructor(countryConfigurationService: CountryConfigurationService) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }
    
    public ngAfterViewInit(): void {
        this.onSwiperInit();
    }

    public ngOnInit() {
        this.showSpinner = true;
    }

    public hideSpinner() {
        this.showSpinner = false;
    }

    public trackByVehicle(vehicle: Vehicle) {
        return vehicle.id;
    }

    public onSwiperIndexChange() {
        const swiperActiveIndex = this.swiper.nativeElement.swiper.activeIndex;
        this.select.emit(this.vehicles[swiperActiveIndex]);
        this.swiperIndexChange.emit(swiperActiveIndex);
    }

    private onSwiperInit() {
        if (this.vehicles.length > 0) {
            this.swiper.nativeElement.swiper.slideTo(this.swiperIndex);
            this.select.emit(this.vehicles[this.swiperIndex]);
            this.swiperIndexChange.emit(this.swiperIndex);
        }
    }

    public slideNext(): void {
        this.swiper.nativeElement.swiper.slideNext(1000);
    }
    public slidePrev(): void {
        this.swiper.nativeElement.swiper.slidePrev(1000);
    }
}
