<div class="row">
   <div
      *ngIf="marketingCampaignAvailable && outletMarketingCampaign?.length > 0 && outletMarketingCampaign[0].bannerConfiguration; else originalBanner"
      class="col-md-12"
   >
      <div class="services-banner-container">
         <span>{{outletMarketingCampaign[0].bannerConfiguration}}</span>
      </div>
   </div>
   <ng-template #originalBanner>
      <div
         *ngIf="(servicesBannerEnableWithoutCampaign || showCampaignLabel()) && servicesBannerText?.length > 0"
         class="col-md-12"
      >
         <div class="services-banner-container">
            <span [innerHTML]="servicesBannerText"></span>
         </div>
      </div>
   </ng-template>
    <div class="col-md-6">
        <h2 class="assistance-title" i18n="@@selectAssistance">Select the assistance</h2>
    </div>
    <div class="col-md-6">
        <div class="assistance-search--wrapper wizard__assistance-search--wrapper">
            <div class="input-group assistance-search">
                <input
                    [formControl]="searchActivity"
                    type="text"
                    class="form-control"
                    i18n-placeholder="@@searchFor"
                    placeholder="Search for..."
                />
                <span class="clear-input">
                    <button
                        *ngIf="searchActivity.value"
                        (click)="searchActivity.reset()"
                        class="btn btn-default button--clear"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="row assistance-tabs header-activity">
    <div class="loader-container">
        <ngx-loading [show]="loading"></ngx-loading>
        <tabset justified="true">
            <tab [active]="searchActivity.value" *ngIf="searchActivity.value" customClass="assistance-tabs__tab">
                <ng-template tabHeading>
                    <div class="assistance-tabs__tab--inner">
                        <i class="fa fa-search assistance-tabs__tab-image"></i>
                        <p class="assistance-tabs__tab-service-name" i18n="@@searchResults">
                            Search results
                        </p>
                    </div>
                </ng-template>
                <div class="assistance-tabs__tab-content">
                    <div class="row activity__row">
                        <ng-container
                            *ngFor="let activityCategory of activityCategories; trackBy: trackByActivityCategory"
                        >
                            <ng-container
                                *ngFor="let activityPackage of activityCategory?.activityPackageList.filter(filterActivityPackages.bind(this)); trackBy:trackByActivityPackage"
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        activityTmp;
                                        context: {
                                            activityPackage: activityPackage,
                                            activityCategory: activityCategory
                                        }
                                    "
                                >
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </tab>
            <tab
                [active]="!searchActivity.value && i == activeTabIndex"
                *ngFor="let activityCategory of activityCategories; let i = index; trackBy: trackByActivityCategory"
                customClass="assistance-tabs__tab"
            >
                <ng-template tabHeading>
                    <div
                        class="assistance-tabs__tab--inner flex-container-center"
                        tooltip="{{ activityCategory.subtitleLocalized }}"
                        (click)="activeTabIndex = i"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                iconTmp;
                                context: {
                                    iconName: activityCategory.iconClass,
                                    iconContainerClass: 'svg-icon-container-tab'
                                }
                            "
                        ></ng-container>
                        <p class="tab-service-name assistance-tabs__tab-service-name services-tab-label" [attr.default-work-team]="activityCategory.workTeamType.id" >
                            {{ activityCategory.descriptionLocalized }}
                        </p>
                    </div>
                </ng-template>
                <div class="assistance-tabs__tab-content">
                    <div class="row activity__row">
                        <ng-container>
                            <ng-container
                                *ngFor="
                                    let activityPackage of activityCategory?.activityPackageList;
                                    trackBy: trackByActivityPackage
                                "
                            >
                                  <ng-container
                                     *ngTemplateOutlet="activityTmp; context: { activityPackage: activityPackage,
                                                                                activityCategory: activityCategory }"
                                  >
                                  </ng-container>
                               </ng-container>
                        </ng-container>
                    </div>
                </div>
            </tab>

            <ng-template #activityTmp let-activityCategory="activityCategory" let-activityPackage="activityPackage">
                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div
                        class="assistance-service centred-container"
                        (click)="onSelect(activityCategory,activityPackage, !isAlreadySelected(activityPackage))"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                iconPackageTmp;
                                context: {
                                    iconName: activityPackage.iconClass,
                                    iconContainerClass: 'svg-icon-container'
                                }
                            "
                        ></ng-container>
                        <div class="service-name-container">
                            <p class="assistance-service-for-online__name" [attr.uls]="getServiceUls(activityPackage)" [ngClass]="activityPackage.tab || activityCategory.id == 'TECHNICAL_CAMPAIGN' ? 'text-bold':''">
                                {{ activityPackage.descriptionLocalized }}
                            </p>

                            <span
                                class="assistance-service-subtitle-for-online__name"
                                (click)="$event.stopPropagation()"
                                [innerHTML]="activityPackage.extendedDescription"
                            ></span>
                        </div>

                        <div *ngIf="activityPackage.srpCampaign" class="campaign-container">
                            <i class="fa fa-tag separator"></i>
                            <span>
                                <ng-container *ngIf="activityPackage.srpCampaign?.siPackageIsCoveredByContract; else showPrice">
                                    {{activityPackage.srpCampaign?.siPackageIsCoveredByContract}}
                                </ng-container>
                                <ng-template #showPrice>
                                    {{
                                        activityPackage.srpCampaign?.totalPriceGross
                                            | currency: (activityPackage.srpCampaign?.currency ? activityPackage.srpCampaign?.currency : 'EUR')
                                    }}
                                </ng-template>
                            </span>
                        </div>
                        <div class="ui-checkbox assistance-service__checkbox">
                            <input #chkPackages [id]="'chk'+activityPackage.id"
                                type="checkbox"
                                (change)="changePackageSelected(activityCategory,activityPackage,$event.currentTarget.checked)"
                                [disabled]="
                                    countryConfiguration.automaticSelectionCbs99999 && activityPackage?.id === '0000105' ||
                                    isSelectedAnotherPageEsclusive(activityCategory,activityPackage)
                                "
                                [checked]="isAlreadySelected(activityPackage)"
                            />
                            <label> </label>
                        </div>
                    </div>
                </div>
            </ng-template>
        </tabset>
    </div>
    <div
        *ngIf="totalDuration <= fastLaneThreshold && isFastLaneAllowed(); else noSelectableFastLaneTmpl"
        class="row activity-container"
    >
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6"  *ngIf="hideFastLane">
                    <div class="assistance-service" (click)="selectFastLane()">
                        <i class="bmw bmw-generic_ico_fastlane assistance-service__icon icon-lg"></i>
                        <p class="assistance-service-for-online__name">
                            <span i18n="@@fastLane">Fast lane</span>
                            <span>
                                {vehicle?.model?.brand, select, BMW {BMW} BMW_I {BMWi} MINI {MINI} BMW_MOTORRAD {BMW
                                Motorrad}}*</span
                            >
                        </p>
                        <div class="ui-checkbox assistance-service__checkbox">
                            <input
                                type="checkbox"
                                [checked]="fastLaneSelected"
                                (change)="showFastLaneWarning($event.target.checked)"
                            />
                            <label> </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="assistance-service" (click)="selectWaitInOutlet()" [hidden]="!fastLaneSelected">
                        <i class="fa fa-coffee assistance-service__icon icon-lg"></i>
                        <p class="assistance-service-for-online__name text-left" i18n="@@waitInOutlet">
                            Wait in outlet
                        </p>
                        <div class="ui-checkbox assistance-service__checkbox">
                            <input type="checkbox" [checked]="waitInOutletSelected" />
                            <label> </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TODO: Implement logic -->
        <div class="col-md-12">
            <ng-container *ngTemplateOutlet="notesAndAdditionalTmpl"></ng-container>
        </div>
        <div class="col-md-12 activity-fastlane-description"  *ngIf="hideFastLane">
            <div>
                <i class="bmw bmw-generic_ico_fastlane assistance-service__icon icon-lg"></i>
            </div>
            <div class="activity-fastlane-description-text">
                <span i18n="@@fastlaneDescription"
                    >A {{ vehicle?.model?.brand | translate }} Fast Lane Service is completed from start to finish
                    within just 90 minutes whilst you wait at your local BMW Centre.
                </span>
                <a
                    *ngIf="countryConfiguration.showFastLaneLink"
                    i18n-href="@@fastlaneInfoLink"
                    href="https://www.bmw.co.uk/bmw-ownership/servicing-and-repairs/servicing/fast-lane-service"
                    target="_blank"
                    i18n="@@fastlaneInfo"
                    >For more information, click here</a
                >
            </div>
        </div>
    </div>
    <ng-template #noSelectableFastLaneTmpl>
        <div class="row">
            <div class="col-md-12">
                <ng-container *ngTemplateOutlet="notesAndAdditionalTmpl"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #notesAndAdditionalTmpl>
        <div class="activity__notes">
            <h4 style="display: inline;" i18n="@@notesAndAdditional">
                Notes & Additional Requests
            </h4>
            <div>
                <textarea
                    name="additional_requests"
                    [formControl]="note"
                    [(ngModel)]="appointment.note"
                    id=""
                    cols="30"
                    rows="5"
                    i18n-placeholder="@@typeHere"
                    placeholder="type here..."
                    maxlength="500"
                ></textarea>
                <ng-container *ngTemplateOutlet="noteValidationError; context: { control: note }"></ng-container>

                <div class="row" *ngFor="let attachment of attachments">
                    <div class="col-md-12">
                        <div class="attachments-container">
                            <a
                                *ngIf="!attachment.loading; else attachmentName"
                                [href]="attachmentsUrl + '/' + attachment.path"
                                target="_blank"
                            >
                                {{ attachment.name }}
                            </a>
                            <ng-template #attachmentName>
                                <span>{{ attachment.name }}</span>
                            </ng-template>

                            <progressbar
                                *ngIf="attachment.loading"
                                max="100"
                                [value]="100"
                                [striped]="true"
                                [animate]="true"
                            >
                            </progressbar>
                            <label
                                *ngIf="!attachment.loading && attchmentsEnabled"
                                class="btn"
                                (click)="deleteAttachment(attachment)"
                            >
                                <i class="fa fa-close"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <div
        class="bsi-description"
        *ngIf="countryConfiguration.enableBsi && vehicle?.bsiContracts?.length > 0 && activeTabIndex === 0"
    >
        <div class="bsi-icon-container">
            <ng-container
                *ngTemplateOutlet="
                    iconTmp;
                    context: { iconName: 'service_service-inclusive_b', iconContainerClass: 'svg-icon-container' }
                "
            ></ng-container>
        </div>
        <span class="srp-campaign-icon-note col-xs-12">{{ bsiLabelText }}</span>
    </div>

    <div class="campaign-description" *ngIf="showCampaignLabel() && activeTabIndex === 0">
        <div class="campaign-icon-container">
            <i class="fa fa-tag"></i>
        </div>
        <span
            class="srp-campaign-icon-note col-xs-12"
            [innerHTML]="countryConfiguration[vehicle?.model?.brand]?.srpCampaignFootNote"
        ></span>
    </div>
</div>

<oas-alert-warning
    #warningImpossibleToPreSelect
    i18n-title="@@impossibleToPreSelectTitle"
    title="Services not selected"
    i18n-text="@@impossibleToPreSelectText"
    text="Unable to automatically select certain services. Please try to select them manually"
>
</oas-alert-warning>
<oas-alert-confirm #technicalCampaignWithStaticService>
</oas-alert-confirm>
<oas-alert-warning
    #warningFastLane
    i18n-title="@@fastLane"
    title="Fast Lane"
    i18n-text="@@fastLaneCheckedWarning"
    text="Fast Lane will only be possible if during the service consultation phase is not found an issue that makes impossible to return the car in less than 90 minutes."
>
</oas-alert-warning>

<ng-template #noteValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.pattern" i18n="@@invalidNote">
                Invalid note!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #iconTmp let-iconName="iconName" let-iconContainerClass="iconContainerClass">
    <div [ngClass]="iconContainerClass">
        <svg class="svg-icon">
            <use [attr.xlink:href]="'assets/images/icons/on_brigth_background_sprite.svg#bmw_' + iconName"></use>
        </svg>
    </div>
</ng-template>

<ng-template #iconPackageTmp let-iconName="iconName" let-iconContainerClass="iconContainerClass">
   <div [ngClass]="iconContainerClass">
      <svg class="svg-icon" width="30" height="30">
         <use [attr.xlink:href]="'assets/images/icons/on_brigth_background_sprite.svg#bmw_' + iconName"></use>
      </svg>
   </div>
</ng-template>

<ng-template #technicalCampaignAvailableTitle>
   <span i18n="@@technicalCampaignAvailableTitle">Technical Campaigns available</span>
</ng-template>
<ng-template #technicalCampaignAvailableText>
   <div>
      <span i18n="@@technicalCampaignAvailableText">The following technical campaigns are available for your vehicle:</span><br>
      <ul>
         <ng-container *ngFor="let activity of technicalCampaignsResponse?.category?.activityPackageList;">
            <li>{{activity.id}} - {{activity.descriptionLocalized}}</li>
         </ng-container>
      </ul>
   </div>
</ng-template>
<ng-template #staticSecondaryServiceTitle>
   <span i18n="@@staticSecondaryServiceTitle">Complimentary check available</span>
</ng-template>

<ng-template #staticSecondaryServiceText>
   <span i18n="@@staticSecondaryServiceText">A complimentary check is available for your vehicle, do you wish to select the service?</span>
</ng-template>
<ng-template #technicalCampaignWithStaticServiceText>
   <div>
      <div>
         <span i18n="@@technicalCampaignAvailableText">The following technical campaigns are available for your vehicle:</span><br/>
         <ul>
            <ng-container *ngFor="let activity of technicalCampaignsResponse?.category?.activityPackageList;">
               <li>{{activity.id}} - {{activity.descriptionLocalized}}</li>
            </ng-container>
         </ul>
      </div>
      <br/>
      <div>
         <span i18n="@@staticSecondaryServiceText">A complimentary check is available for your vehicle, do you wish to select the service?</span>
      </div>
   </div>
</ng-template>
