import { CountryConfiguration } from '../app/country-configuration';
import { RoleType } from '../app/shared/entities';
export let indiaCountryConfiguration: CountryConfiguration = {
    language: 'en',
    countryCode: 'IN',
    locale: 'en-IN',
    welcomeTerminalAppointmentIdenabled: false,
    appointmentCountForSadvInDashboard: false,
    showCarShadowImageInClientSearch:true,
    nscCicSearchOnDMS: false,
    phoneNumberRegex: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[1-9]\d{9}$/,
    mobileNumberRegex: [/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[1-9]\d{9}$/],
    vatRegex: /^[a-zA-Z0-9]{15}$/,
    fiscalCodeRegex: null,
    dsaFilePath: 'assets/pdf/IN/DSA_Notice.pdf',
    showDSALink: false,
    BMW: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.in/en/footer/metanavigation/privacy-policy.html',
            legalNoteLink: 'https://www.bmw.in/en/footer/metanavigation/-legal-disclaimer-imprint.html',
            contactLink: 'mailto:dataprotection@bmw.in',
            textHomeLink: 'www.bmw.in',
            homeLink: 'https://www.bmw.in',
            vatNumber: 'GSTIN - 06AABCB7140C1Z9',
        },
        srpCampaignFootNote: 'Fixed prices program',
        tssbInfoUrl: 'https://www.bmw.in',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: false,
            tenantId: undefined,
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    MINI: {
        footer: {
            privacyPolicyLink: 'https://www.mini.in/en_IN/home/footer/privacy-policy.html',
            legalNoteLink: 'https://www.mini.in/en_IN/home/footer/Legal.html',
            contactLink: 'mailto:datasafety@mini.in',
            textHomeLink: 'www.mini.in',
            homeLink: 'https://www.mini.in',
            vatNumber: 'GSTIN - 06AABCB7140C1Z9',
        },
        srpCampaignFootNote: 'Fixed prices program',
        tssbInfoUrl: 'https://www.mini.in',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: false,
            tenantId: undefined,
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_MOTORRAD: {
        footer: {
            privacyPolicyLink: 'https://www.bmw-motorrad.in',
            legalNoteLink: 'https://www.bmw-motorrad.in',
            contactLink: 'https://www.bmw-motorrad.in',
            textHomeLink: 'www.bmw-motorrad.in',
            homeLink: 'https://www.bmw-motorrad.in',
            vatNumber: 'vatNumberBMWMotorradIndia',
        },
        srpCampaignFootNote: 'Fixed prices program',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: false,
        longLifeCareMobilityBanner: false,
        ePaas: {
            enabled: false,
            tenantId: undefined,
        },
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },

    BMW_I: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.in/en/footer/metanavigation/privacy-policy.html',
            legalNoteLink: 'https://www.bmw.in/en/footer/metanavigation/-legal-disclaimer-imprint.html',
            contactLink: 'mailto:dataprotection@bmw.in',
            textHomeLink: 'www.bmw.in',
            homeLink: 'https://www.bmw.in',
            vatNumber: 'GSTIN - 06AABCB7140C1Z9',
        },
        srpCampaignFootNote: 'Fixed prices program',
        tssbInfoUrl: 'https://www.bmw.in',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: false,
            tenantId: undefined,
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    tssb: {
        enable: false,
        enableRecentPendingAppointmentCheckboxByRoles: new Map<RoleType, boolean>([
            [<RoleType>'ROLE_CIC_EP_MANAGER', false],
            [<RoleType>'ROLE_CIC_EP_USER', false],
            [<RoleType>'ROLE_S_ADV', false],
            [<RoleType>'ROLE_S_ASSISTANT', false],
            [<RoleType>'ROLE_CIC_NSC_ADMIN', false],
            [<RoleType>'ROLE_CIC_NSC_USER', false],
            [<RoleType>'ROLE_ASM', false],
            [<RoleType>'ROLE_RENTING_ADM', false],
            [<RoleType>'ROLE_RENTING_USER', false],
        ]),
        enableEmail: true,
    },
    emailQuickAppointment: 'no-reply-oas-in@bmw.in',
    showAllCapacityTeams: false,
    waitInOutlet: false,
    mobilityStepAvailability: false,
    enableToros: false,
    editCustomer: true,
    vatNumberValidator: '',
    dcomHealth: true,
    showFastLaneLink: true,
    // Quick appointment REFACTOR
    latitude: 28.64,
    longitude: 77.21,
    createVehicleFiscalCode: false,
    dcomValidation: true,
    mapsRadius: 50000,
    mapsZoom: 9,
    updateDMS: false,
    maxDelay: 72,
    daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    enableRenting: false,
    enableNscReport: true,
    nscReportFilename: 'Detailed NSC appointments.csv',
    enableSMS: true,
    sendSMS: false,
    automaticSelectionCbs99999: false,
    enableBsi: true,
    hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration: true,
    enableKiosk: false,
    enablePPVSync: false,
    enableTjekvik: false,
    enableSharebox: false,
    enableSPOA: true,
    enableVinLookup: false,
    preRegistration: {
        enablePreRegistrationFromDms: true,
        enablePreRegistrationFromFastAppointment: true,
        hideCheckBoxWithoutRegistration: false,
        forceSendEmailWithoutRegistration: false,
    },
    showVinOnlineAppointmentResume: true,
    showFiscalNumberVehicleForm: false,
    servicesBannerEnableWithoutCampaign: true,
    vehicleFormSmartRegistrationType: 'MANUAL',
    showAppointmentWithoutRegistrationVehicleForm: true,
    fastAppointmentMigrationToConsumeCapacity: false,
    dateInputRangeFormat: 'MM/DD/YYYY',
    enableLeadApi: true,
    currencyCode: 'INR',
    enableEmail: true,
    sendEmail: true,
    leadApiOutlets: ['OUTL_30432', 'OUTL_23441'],
    leadApiOutletFilterEnable: true,
    leadTssbAsmEnable: true,
    appointmentFastCustomerCreationGenderEnabled:true,
    eitherPhoneOrEmailEnabled:false,
    showOnlineWorkTeam: false,
    workingDaysEnabled:false,
    dropDownLeadStatuses: [
        'assignedToTsp',
        'contacting',
        'postponed',
        'escalated'
    ],
    availabilityFilter: false,
    awp: {
        url: 'https://awp.bmwgroup.com/servicedemand?',
        vinParameter: 'Vin=',
        icon: {
            enabled: false,
            url: 'awp:/start?app=vehicledetails&vin=${vin}',
        }
    },
    enableLeadBattery: false,
};
