import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { countryConfiguration } from '../../../configuration/country.configuration';
import { AppComponent } from '../../app.component';
import { AuthService } from '../../auth/auth.service';
import { ActivityService } from '../../core/api/activity/activity.service';
import { AppointmentLinkService } from '../../core/api/appointment-link/appointment-link.service';
import { AppointmentService } from '../../core/api/appointment/appointment.service';
import { MobilityService } from '../../core/api/mobility/mobility.service';
import { PreRegistrationService } from '../../core/api/pre-registration/pre-registration.service';
import { TechnicalSettingService } from '../../core/api/technical-setting.service';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { Utils } from '../../core/utils';
import { CountryConfiguration } from '../../country-configuration';
import { CustomerFormWithoutRegistrationTermsComponent } from '../../customer/account/form/customer-form-without-registration-terms.component';
import { CustomerFormComponent } from '../../customer/account/form/customer-form.component';
import { AlertConfirmComponent } from '../../shared/alert/alert-confirm.component';
import { AlertErrorComponent } from '../../shared/alert/alert-error.component';
import { AlertSuccessComponent } from '../../shared/alert/alert-success.component';
import { AlertWarningComponent } from '../../shared/alert/alert-warning.component';
import { AppointmentWizardComponent } from '../../shared/AppointmentWizardComponent';
import { dataAnalytics } from '../../shared/data-analytics';
import {
    Activity,
    ActivityCategory,
    ActivityPackage,
    Appointment,
    BaseResponse,
    CatalogType,
    Customer,
    EventSci,
    GeoMarker,
    Mobility,
    Outlet,
    SearchSlotBean,
    Slot,
    StatusType,
    TssbServiceState,
    User,
    Vehicle,
    VehicleBrand,
    WelcomeActivityCategory,
} from '../../shared/entities';
import { TracyService } from '../../shared/tracy.service';
import { ActivityPriceComponent } from './activity/activity-price.component';
import { ActivityComponent } from './activity/activity.component';
import { AppointmentResumeComponent } from './appointment-resume.component';
import { OutletAgendaComponent } from './outlet/outlet-agenda.component';
import { MatStepper } from '@angular/material/stepper';
import { OutletMapsComponent } from './outlet/outlet-maps.component';
import { OutletMobilityComponent } from './outlet/outlet-mobility.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
    selector: 'oas-appointment-wizard',
    templateUrl: './online-appointment-wizard.component.html',
    styleUrls: ['./online-appointment-wizard.scss'],
})
export class OnlineAppointmentWizardComponent implements OnInit, AfterViewInit, AppointmentWizardComponent {
    static ACTIVITY_STEP = 0;
    static OUTLET_STEP = OnlineAppointmentWizardComponent.ACTIVITY_STEP + 1;
    static MOBILITY_STEP = OnlineAppointmentWizardComponent.OUTLET_STEP + 1;
    static SADV_STEP = OnlineAppointmentWizardComponent.MOBILITY_STEP + 1;
    static CUSTOMER_DATA_STEP = OnlineAppointmentWizardComponent.SADV_STEP + 1;
    static RESUME_STEP = OnlineAppointmentWizardComponent.CUSTOMER_DATA_STEP + 1;

    @ViewChild('wizard', {static: false}) public wizard: MatStepper;
    @ViewChild(ActivityComponent) public activityComponent: ActivityComponent;
    @ViewChild(ActivityPriceComponent) private activityPriceComponent: ActivityPriceComponent;
    @ViewChild(AppointmentResumeComponent) private appointmentResumeComponent: AppointmentResumeComponent;
    @ViewChild('alertWarning') private alertWarning: AlertWarningComponent;
    @ViewChild(AlertSuccessComponent) private alertSuccess: AlertSuccessComponent;
    @ViewChild(AlertConfirmComponent) private alertConfirm: AlertConfirmComponent;
    @ViewChild(AlertErrorComponent) private alertError: AlertErrorComponent;
    @ViewChild('appointmentSuccessfullyCreatedTmpl') private appointmentSuccessfullyCreatedTmpl: TemplateRef<any>;
    @ViewChild('appointmentSuccessfullyUpdatedTmpl') private appointmentSuccessfullyUpdatedTmpl: TemplateRef<any>;
    @ViewChild('bodyAndPaintAlertTitleTmpl') private bodyAndPaintAlertTitleTmpl: TemplateRef<any>;
    @ViewChild('bodyAndPaintAlertTextTmpl') private bodyAndPaintAlertTextTmpl: TemplateRef<any>;
    @ViewChild('favouriteOutletInactiveTitleTmpl') private favouriteOutletInactiveTitleTmpl: TemplateRef<any>;
    @ViewChild('favouriteOutletInactiveTextTmpl') private favouriteOutletInactiveTextTmpl: TemplateRef<any>;
    @ViewChild('cannotPreSelectFavOutletTitleTmpl') private cannotPreSelectFavOutletTitleTmpl: TemplateRef<any>;
    @ViewChild('cannotPreSelectFavOutletTextTmpl') private cannotPreSelectFavOutletTextTmpl: TemplateRef<any>;
    @ViewChild('confirmProceedActivityStepTitleTmpl') private confirmProceedActivityStepTitleTmpl: TemplateRef<any>;
    @ViewChild('confirmProceedActivityStepContentTmpl') private confirmProceedActivityStepContentTmpl: TemplateRef<any>;
    @ViewChild('fastLaneNoLongerAvailableContentTmpl') private fastLaneNoLongerAvailableContentTmpl: TemplateRef<any>;
    @ViewChild('alertWarningInvalidFormTitleTmpl') private alertWarningInvalidFormTitleTmpl: TemplateRef<any>;
    @ViewChild('alertWarningInvalidFormContentTmpl') private alertWarningInvalidFormContentTmpl: TemplateRef<any>;
    @ViewChild('warningFixedPricesSameDates') private warningFixedPricesSameDates: AlertWarningComponent;
    @ViewChild('warningFixedPricesDistinctDates') private warningFixedPricesDistinctDates: AlertWarningComponent;
    @ViewChild('agenda') public agendaComponent: OutletAgendaComponent;
    @ViewChild(CustomerFormComponent) public customerFormComponent: CustomerFormComponent;
    @ViewChild(CustomerFormWithoutRegistrationTermsComponent)
    public customerFormWithoutRegistrationTermsComponent: CustomerFormWithoutRegistrationTermsComponent;
    @ViewChild('serviceAdvisorWortTeamException')
    public serviceAdvisorWortTeamException: AlertErrorComponent;
    @ViewChild('myMap') public myMap: OutletMapsComponent;
    @ViewChild('mobility') public mobility: OutletMobilityComponent;

    private showWarningFixedPrices: boolean;
    public campaignDate: string;
    public warningYearDate: number;
    private countryConfiguration: CountryConfiguration;
    private showWarningCannotPreSelectFavOutlet = false;
    private showBodyAndPaintAlert: boolean;
    private showFastLaneNoLongerAvailable = false;

    ssoUrl =
        window.location.hostname.includes('-int') || window.location.hostname.includes('-dev')
            ? 'https://customer-i.bmwgroup.com/oneid/#/'
            : 'https://customer.bmwgroup.com/oneid/#/';
    client = 'oas';
    currentBrand = 'BMW';
    local: string;

    public existFixedPrices = false;
    public fastLaneSkillNotAvailable = false;
    public errorResponse: HttpErrorResponse;
    public appointment: Appointment;
    public searchSlot: SearchSlotBean;
    public categoryDurationMap = new Map<string, number>();
    public showSpinner = true;
    public activityCategories: ActivityCategory[];
    public selectedVehicle;
    private profileBrand: string;
    private hostname: string;
    public note = '';
    public disableRemove = false;
    public notAvailableServicesLabels: string[];
    public preselectedActivityPackageIds: string[] = [];
    public preselectedTirePackageIds: string[] = [];
    public preselectedActivityCategoryId: string;
    public outlet: Outlet;
    isDelearChange = false;

    public showCustomerDataForm: boolean;
    public isFromWithoutRegistration: boolean;
    public showRegistrationCheckbox: boolean;
    public sendPreRegistrationEmail: boolean;
    public activityPriceDesabled: boolean;
    isUpdateLink = false;
    isLead = false;
    public technicalCampaignActivitiesIds: Array<string> = [];

    public filterPredicate = act =>
        act.aprilActivityId != null ||
        (act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
        (act.activityType.showOnline ||
            act.activityType.id === '000-00-FLATRATE' ||
            act.activityType.id === '000-00-FIXEDPRICES' ||
            act.activityType.id === '000-00-PACKAGE'));

    public canExitActivityStep (): Promise<boolean> {
        let canExit = Promise.resolve(true);
            if (this.appointment.outlet) {
                const notAvailableServicesIds: string[] = [];
                this.categoryDurationMap.forEach((value, key) => {
                    if (
                        !this.appointment.outlet.skills ||
                        !this.appointment.outlet.skills.find(skill => skill.id === key)
                    ) {
                        notAvailableServicesIds.push(key);
                    }
                });
                if (this.appointment.fastLane) {
                    if (!this.appointment.outlet.skills.find(skill => skill.id === 'FAST_LANE')) {
                        this.fastLaneSkillNotAvailable = true;
                    }
                }

                if (notAvailableServicesIds.length > 0 || this.fastLaneSkillNotAvailable) {
                    if (!this.existFixedPrices) {
                        this.notAvailableServicesLabels = this.activityComponent.getCategoriesDescriptions(
                            notAvailableServicesIds
                        );
                    }

                    this.alertConfirm.titleTmpl = this.confirmProceedActivityStepTitleTmpl;
                    this.alertConfirm.contentTmpl = this.confirmProceedActivityStepContentTmpl;
                    canExit = this.alertConfirm.show().then(result => {
                        this.notAvailableServicesLabels = null;
                        this.fastLaneSkillNotAvailable = false;
                        return result.value;
                    });
                }
            }
        

        return canExit;
    };

    constructor(
        public appComponent: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        private appointmentService: AppointmentService,
        public activityService: ActivityService,
        private authService: AuthService,
        private mobilityService: MobilityService,
        private vehicleService: VehicleService,
        private tracyService: TracyService,
        private preRegistrationService: PreRegistrationService,
        private location: Location,
        countryConfigurationService: CountryConfigurationService,
        private appointmentLinkService: AppointmentLinkService,
        private technicalSettingService: TechnicalSettingService,
        private cdr: ChangeDetectorRef
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.hostname = window.location.hostname;
        this.tracyService.resetProduct();
    }

    public ngOnInit(): void {
        this.technicalSettingService.findByName("COUNTRY_SRP_DISABLED").then(Response=>{
            const countries = Response.value.split(",");
            this.activityPriceDesabled = countries.includes(countryConfiguration.countryCode);
            console.log(this.activityPriceDesabled ? "SRP activity price is disabled for countries (" +  countries +")"
                                                   : "SRP activity price is  enabled for countries (" +  countries +")" )
        });

        this.getCurrentBrand();
        if (this.countryConfiguration.preRegistration.forceSendEmailWithoutRegistration) {
            this.sendPreRegistrationEmail = true;
        }

        this.isFromWithoutRegistration = this.route.snapshot.queryParamMap.get('isWithoutRegistration') === 'true';
        this.isUpdateLink = this.router.url.indexOf('/update/') > -1;
        this.showRegistrationCheckbox =
            this.isFromWithoutRegistration && !countryConfiguration.preRegistration.hideCheckBoxWithoutRegistration;

        this.preselectedActivityPackageIds = this.route.snapshot.queryParamMap
            .getAll('preselectedCBS')
            .map(actTypeId => actTypeId.substring(actTypeId.lastIndexOf('-') + 1));

        this.preselectedTirePackageIds = this.route.snapshot.queryParamMap
          .getAll('preselectedTires')
          .map(actTypeId => actTypeId.substring(actTypeId.lastIndexOf('-') + 1));

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasAppointmentSchedule,
            dataAnalytics.page.variant.real
        );

        if (
            this.isFromWithoutRegistration &&
            !Utils.isNullOrUndefined(sessionStorage.getItem('appointmentFromWithoutRegistration'))
        ) {
            this.appointment = JSON.parse(sessionStorage.getItem('appointmentFromWithoutRegistration')) as Appointment;
        }
        if (this.isUpdateLink) {
            this.isFromWithoutRegistration = true;
        }
        if (this.route.snapshot.data.customer) {
            // default coordinates for customer without address
            if (
                Utils.isNullOrUndefined(this.route.snapshot.data.customer.address) ||
                Utils.isNullOrUndefined(this.route.snapshot.data.customer.address.country) ||
                Utils.isNullOrUndefined(this.route.snapshot.data.customer.address.latitude) ||
                Utils.isNullOrUndefined(this.route.snapshot.data.customer.address.longitude) ||
                Utils.isNullOrUndefined(this.route.snapshot.data.customer.address.zipCode) ||
                (!Utils.isNullOrUndefined(this.route.snapshot.data.customer.address.locationComponentList) &&
                    this.route.snapshot.data.customer.address.locationComponentList.filter(
                        location => location.shortName != null
                    ).length === 0)
            ) {
                let address: GeoMarker = {
                    longitude: this.countryConfiguration.longitude,
                    latitude: this.countryConfiguration.latitude,
                };

                this.route.snapshot.data.customer.address = address;
            }
            // new appointment flow
            const selectedVehicleId = this.route.snapshot.paramMap.get('vehicleId');
            const vehicle: Vehicle = this.route.snapshot.data.customer.vehicleList.find(
                vehicleSel => vehicleSel.id === selectedVehicleId
            );
            this.selectedVehicle = vehicle;
            this.preselectedActivityPackageIds = this.route.snapshot.paramMap
                .getAll('activities')
                .map(actTypeId => actTypeId.substring(actTypeId.lastIndexOf('-') + 1));
            if (this.route.snapshot.queryParamMap.get('spoa') === 'true') {
                switch (vehicle.model.brand) {
                    case <VehicleBrand>'BMW':
                    case <VehicleBrand>'MINI':
                    case <VehicleBrand>'BMW_I':
                        this.preselectedActivityPackageIds.push('OTHER_MAINT');
                        this.preselectedActivityCategoryId = 'OTHER';
                        break;
                    case <VehicleBrand>'BMW_MOTORRAD':
                        this.preselectedActivityPackageIds.push('OTHER_MAINT_MOTO');
                        this.preselectedActivityCategoryId = 'OTHER_MOTO';
                        break;
                }
            }

            const ticketTssbId = this.route.snapshot.queryParamMap.get('ticketTssbId');
            const ticketLeadTssbId = this.route.snapshot.queryParamMap.get('ticketLeadTssbId');

            this.appointment = this.createBaseAppointment(this.route.snapshot.data.customer, vehicle);

            if (!Utils.isNullOrUndefined(ticketTssbId)) {
                this.isLead = false;
                let preselectedCBSList;
                if (!Utils.isNullOrUndefined(this.route.snapshot.queryParamMap.get('preselectedCBS'))) {
                    preselectedCBSList = this.route.snapshot.queryParamMap.getAll('preselectedCBS');
                }

                this.initAppointmentFromTssb(ticketTssbId, preselectedCBSList);
                this.appointment.ticketTssbId = ticketTssbId;
            } else if (!Utils.isNullOrUndefined(ticketLeadTssbId)) {
                this.isLead = true;
                let preselectedCBSList;
                if (!Utils.isNullOrUndefined(this.route.snapshot.queryParamMap.get('preselectedCBS'))) {
                    preselectedCBSList = this.route.snapshot.queryParamMap.getAll('preselectedCBS');
                }

                this.initAppointmentFromTssb(ticketLeadTssbId, preselectedCBSList);
                this.appointment.ticketLeadTssbId = ticketLeadTssbId;
            } else {
                this.initAppointment();
            }

            this.checkTechnicalCampaigns(vehicle.vin);
            this.initSearchSlot(this.appointment);
        } else if (this.route.snapshot.data.appointment) {
            // edit appointment flow
            this.appointment = this.route.snapshot.data.appointment.payload;
            this.appointment.activityList.map(activity => {
                if (activity.activityDays) {
                    activity.activityDays.forEach(activityDay => {
                        if (activityDay && activityDay.date) {
                            const date = new Date(activityDay.date);
                            date.setHours(5);
                            activityDay.date = date;
                            if (date.getTimezoneOffset() === 360 || countryConfiguration.countryCode === 'MX') {
                                activityDay.date = Utils.addDays(date, 1);
                            }
                        }
                    });
                }
            });
            if (
                this.appointment.customer &&
                (Utils.isNullOrUndefined(this.appointment.customer.address) ||
                    Utils.isNullOrUndefined(this.appointment.customer.address.longitude) ||
                    Utils.isNullOrUndefined(this.appointment.customer.address.latitude))
            ) {
                this.appointment.customer.address = {
                    latitude: this.countryConfiguration.latitude,
                    longitude: this.countryConfiguration.longitude,
                };
            }
            this.initSearchSlot(this.appointment);
            if (this.appointment.technicalCampaignsAvailable && this.appointment.fastLane) {
                this.showFastLaneNoLongerAvailable = true;
            }
        }

        if (this.appointment && this.appointment.outlet && !this.appointment.outlet.outletMobilityTypeList) {
            this.mobilityService.getAvailableMobilityTypes(this.appointment.outlet.id).then(outletMobilityTypes => {
                this.appointment.outlet.outletMobilityTypeList = outletMobilityTypes;
            });
        }
        this.showBodyAndPaintAlert = !(
            this.appointment &&
            this.appointment.activityList &&
            this.appointment.activityList.some(
                act =>
                    act.catalogType !== 'TECHNICAL_CAMPAIGNS' && act.activityType.activityPackageId === 'BODY_AND_PAINT'
            )
        );

        if (this.isFromWithoutRegistration) {
            this.initSearchSlot(this.appointment);
            this.showCustomerDataForm = true;
            if (this.appointment.vehicle && !Utils.isNullOrUndefined(this.appointment.vehicle.vin)) {
                this.checkTechnicalCampaigns(this.appointment.vehicle.vin);
            }
        }

        this.outlet = this.appointment.outlet;
        if(this.isFromWithoutRegistration && sessionStorage.getItem('preselectedOutlet')){
           this.outlet = this.appointment.outlet = JSON.parse(sessionStorage.getItem('preselectedOutlet'));
        }

        this.hasFixedPrices();
        this.local = this.countryConfiguration.locale;
    }

    public ngAfterViewInit(): void {
        this.wizard.selectedIndex = this.getDefaultStepIndex();
        if(this.wizard.selectedIndex === 0) {
            this.disableRemove = false;
        }
        if (this.showFastLaneNoLongerAvailable) {
            this.alertWarning.contentTmpl = this.fastLaneNoLongerAvailableContentTmpl;
            this.alertWarning.show().then(() => {
                Utils.clearTimeSlot(this.appointment);
                this.wizard.selectedIndex = OnlineAppointmentWizardComponent.SADV_STEP;
            });
            this.showFastLaneNoLongerAvailable = false;
        } else {
            this.sendPageViewForServicesSelection();
            this.tracyService.sendEvent(dataAnalytics.event.referenceName.serviceSelecltionFormStart);
        }

        if (this.showWarningCannotPreSelectFavOutlet) {
            this.alertWarning.titleTmpl = this.cannotPreSelectFavOutletTitleTmpl;
            this.alertWarning.contentTmpl = this.cannotPreSelectFavOutletTextTmpl;
            this.alertWarning.show();
            this.showWarningCannotPreSelectFavOutlet = false;
        }
        this.cdr.detectChanges();
    }

    private initSearchSlot(appointment: Appointment) {
        this.searchSlot = {
            appointment: appointment,
            checkOnline: true,
        };
    }

    private createBaseAppointment(customer: Customer, vehicle: Vehicle): Appointment {
        const welcomeCategory = this.retrieveWelcomeCategory(vehicle.model.brand);
        return {
            customer,
            contactAppointment: {
                firstName: customer.name,
                lastName: customer.surname,
                email: customer.email,
                mobile: customer.phoneNumber,
                gender: customer.gender,
            },
            sendSms: customer.preferences.contactBySms,
            sendEmail: customer.preferences.contactByMail,
            vehicle,
            outlet: this.retrieveFavouriteOutlet(customer, welcomeCategory),
            mobilityList: [],
            technicalCampaignsAvailable: false,
            addingItemsToBasket: true,
            editItemsFromBasket: true,
            removingItemsFromBasket: true,
            cancelAppointment: true,
        };
    }

    private initAppointment(): void {
        this.appointment.activityList = [];
        this.appointment.appointmentSource = 'ONLINE';
    }

    private initAppointmentFromTssb(ticketTssbId: string, preselectedCBSList: string[]): void {
        let activitiesPromise: Promise<BaseResponse<ActivityPackage[]>>;
        if (this.countryConfiguration.enableLeadApi && this.isLead) {
            activitiesPromise = this.activityService.getActivityPackagesByLeadTicket(parseInt(ticketTssbId, 10));
        } else {
            activitiesPromise = this.activityService.getActivityPackagesByTicket(parseInt(ticketTssbId, 10));
        }
        this.appointment.activityList = null;

        activitiesPromise
            .then(activityPackages => {
                const mActivityList = [];
                activityPackages.payload
                    .filter(
                        activityPackage =>
                            (activityPackage.cbs != null &&
                                ((!Utils.isNullOrUndefined(preselectedCBSList) &&
                                    preselectedCBSList.includes(activityPackage.cbs.identifier)) ||
                                    (Utils.isNullOrUndefined(preselectedCBSList) &&
                                        activityPackage.cbs.state !== <TssbServiceState>'GREEN'))) ||
                            (activityPackage.id === '0000105' && countryConfiguration.automaticSelectionCbs99999)
                    )
                    .map(activityPackage => {
                        return {
                            name: activityPackage.descriptionLocalized,
                            activityType: {
                                id: '000-00-FLATRATE',
                                descriptionLocalized: activityPackage.descriptionLocalized,
                                showOnline: true,
                                activityPackageId: 'FLATRATE',
                                activityCategory: { id: 'OTHER' },
                            },
                            duration: activityPackage.duration,
                            activityExtradata: { flatrateNumber: activityPackage.flatRateNumber },
                            aprilActivityId: activityPackage.id,
                            catalogType: <CatalogType>'CBS',
                        };
                    })
                    .forEach(activityPackage => {
                        mActivityList.push(activityPackage);
                    });
                this.appointment.activityList = mActivityList;
            })
            .catch(error => {
                this.appointment.activityList = [];
            });
    }

    private checkTechnicalCampaigns(vin: string) {
        this.vehicleService.hasTechnicalCampaigns(vin).then(response => {
            if (response.errors.length === 0) {
                this.appointment.technicalCampaignsAvailable = response.payload;
            }
        });
    }

    private retrieveFavouriteOutlet(customer: Customer, welcomeCategory: WelcomeActivityCategory): Outlet {
        let outlet;

        // if preselected outlet is set
        if (sessionStorage.getItem('preselectedOutlet')) {
            outlet = JSON.parse(sessionStorage.getItem('preselectedOutlet'));

            // if outlet cannot handle current vehicle brand
            if (!outlet.skills.some(actCat => actCat.id === welcomeCategory)) {
                outlet = undefined;
                this.showWarningCannotPreSelectFavOutlet = true;
            }

            // if customer favourite outlet is set
        } else if (customer.favouriteOutlet) {
            outlet = customer.favouriteOutlet;

            // if outlet cannot handle current vehicle brand
            if (!outlet.skills.some(actCat => actCat.id === welcomeCategory)) {
                outlet = undefined;
                this.showWarningCannotPreSelectFavOutlet = true;
            }
        }

        if (!Utils.isNullOrUndefined(outlet)) {
            this.setDealerInformationIntoDataAnalytics(outlet);
        }

        return outlet;
    }

    public retrieveWelcomeCategory(vehicleBrand: VehicleBrand): WelcomeActivityCategory {
        let welcomeCategory: WelcomeActivityCategory = null;

        switch (vehicleBrand) {
            case 'BMW':
                welcomeCategory = 'WELCOME_BMW';
                break;

            case 'BMW_I':
                welcomeCategory = 'WELCOME_BMWi';
                break;

            case 'MINI':
                welcomeCategory = 'WELCOME_MINI';
                break;

            case 'BMW_MOTORRAD':
                welcomeCategory = 'WELCOME_MOTORRAD';
                break;
        }

        return welcomeCategory;
    }

    public hideSpinner() {
        this.showSpinner = false;
    }

    /**
     * Check if almost one activity is selected
     */
    public isActivitySelected(): boolean {
        return this.appointment.activityList ? this.appointment.activityList.length > 0 : false;
    }

    public removeActivity(activity: Activity): void {
        console.log('removeActivity//removeActivity')
        const index = this.appointment.activityList.indexOf(activity);

        if (this.wizard.selectedIndex > 0) {
            if (this.appointment.activityList.length === 1) {
                this.disableRemove = true;
                return;
            }
        }

        if (index >= 0) {
            this.appointment.activityList.splice(index, 1);
            if (
                countryConfiguration.automaticSelectionCbs99999 &&
                this.appointment.activityList.filter(a => a.catalogType === <CatalogType>'CBS').length === 1
            ) {
                this.removeActivity(
                    this.appointment.activityList.find(
                        act => act.activityType.activityPackageId === '0000105' || act.aprilActivityId === '0000105'
                    )
                );
            }
        }
    }

    /**
     * Manage category duration map changing event
     * @param event category duration map
     */
    public onCategoryDurationMapChange(categoryDurationMap: Map<string, number>) {
        this.categoryDurationMap = categoryDurationMap;
    }

    public isOutletSelected(): boolean {
        return this.appointment.outlet ? true : false;
    }

    public onSlotChange(slot: Slot) {
        this.appointment.timeSlot = slot.timeSlotName;
        this.appointment.startTime = slot.slotStart;
        this.appointment.endTime = slot.slotEnd;

        // if appointment is moved forward, the return time is no more valid and has to be recalculated by the system or setted by the BO user
        if (this.appointment.returnTime && this.appointment.returnTime.getTime() < this.appointment.endTime.getTime()) {
            this.appointment.returnTime = undefined;
        }

        if (this.appointment.fastLane && slot.fastLaneSlots) {
            this.appointment.fastLaneTimeSlot = slot.fastLaneSlots[0].timeSlotName;
            // TODO: rename the slot field "saName" to something like "resourceName"
            this.appointment.fastLaneResourceId = slot.fastLaneSlots[0].resourceId;
        } else {
            this.appointment.fastLaneTimeSlot = null;
            this.appointment.fastLaneResourceId = null;
        }
    }

    public onChangeWaitInOutlet(waitInOutlet: boolean) {
        this.appointment.waitInOutlet = waitInOutlet;
        if (waitInOutlet) {
            this.appointment = {
                ...this.appointment,
                mobilityList: [],
                courtesyCarRequired: false,
                courtesyCarActivity: undefined,
            };
        }
    }

    public onChangeServiceAdvisor(sa: User) {
        this.appointment.serviceAdvisor = sa;
    }

    public isSlotSelected(): boolean {
        return this.appointment.timeSlot ? true : false;
    }

    public isServiceAdvisorSelected(): boolean {
        return this.appointment.serviceAdvisor != null;
    }

    /**
     * Create or update appointment
     */
    public book(): void {
        this.appComponent.loading = true;
        let result: Promise<any>;
        let template: TemplateRef<any>;
        this.appointment.vehicle.mileage = this.appointmentResumeComponent.mileage.value;
        if (!this.appointmentResumeComponent.readOnlyPlate) {
            this.appointment.vehicle.plate = this.appointmentResumeComponent.plate.value;
        }

        if (!this.appointmentResumeComponent.valid) {
            this.alertWarning.titleTmpl = this.alertWarningInvalidFormTitleTmpl;
            this.alertWarning.contentTmpl = this.alertWarningInvalidFormContentTmpl;
            this.alertWarning.show();
            this.appComponent.loading = false;
        } else {
            if (this.appointment.id) {
                result = this.updateAppointment();
                template = this.appointmentSuccessfullyUpdatedTmpl;
            } else {
                this.tracyService.sendEvent(dataAnalytics.event.referenceName.summaryFormFinishedIntention);
                result = this.createAppointment();
                template = this.appointmentSuccessfullyCreatedTmpl;
            }

            result
                .then(result => {
                    if (this.sendPreRegistrationEmail && !Utils.isNullOrUndefined(result)) {
                        this.preRegistrationService.initializeRegistrationWithCustomerDataAndAppointmentId(
                            result,
                            this.appointment
                        );
                    }
                    this.appComponent.loading = false;
                    this.alertSuccess.titleTmpl = template;
                    this.alertSuccess.show();
                    this.setDataAnalyticsForProductAndServicesInSuccessPage(
                        this.appointment.vehicle,
                        this.appointment.activityList,
                        this.appointment.mobilityList
                    );
                    this.sendPageViewForAppointmentSuccess();
                    this.tracyService.sendEvent(dataAnalytics.event.referenceName.successFormFinishedIntention);
                    this.router.navigate([this.authService.getDashboardPath()]);
                })
                .catch((errorResponse: HttpErrorResponse) => {
                    this.errorResponse = errorResponse;
                    this.appComponent.loading = false;
                    if (errorResponse.status === 409) {
                        this.serviceAdvisorWortTeamException.show();
                    } else {
                        this.alertError.show();
                    }
                });
        }
    }

    public cancel(): void {
        this.alertConfirm.titleTmpl = null;
        this.alertConfirm.contentTmpl = null;
        if (this.isFromWithoutRegistration) {
            this.alertConfirm.show().then(result => {
                if (result.value) {
                    this.appComponent.loading = true;
                    const uuid = this.route.snapshot.data.uuid;
                    const appointmentId = this.route.snapshot.data.appointmentId;
                    this.appointmentLinkService
                        .deleteAppointment(uuid, appointmentId, '', 'UPDATE')
                        .then(() => {
                            this.appComponent.loading = false;
                            this.alertSuccess.show().then(() => {
                                this.router.navigate(['/']);
                            });
                        })
                        .catch(error => {
                            this.appComponent.loading = false;
                            this.alertError.showHttpError(error).then(value => {
                                this.router.navigate(['/']);
                            });
                        });
                }
            });
        } else {
            this.alertConfirm.show().then(result => {
                if (result.value) {
                    this.appComponent.loading = true;
                    this.appointmentService
                        .delete(this.appointment.id)
                        .then(() => {
                            this.appComponent.loading = false;
                            this.alertSuccess.show().then(() => {
                                this.router.navigate([this.authService.getDashboardPath()]);
                            });
                        })
                        .catch((errorResponse: HttpErrorResponse) => {
                            this.errorResponse = errorResponse;
                            this.appComponent.loading = false;
                            this.alertError.show();
                            this.router.navigate([this.authService.getDashboardPath()]);
                        });
                }
            });
        }
    }

    private createAppointment(): Promise<string> {
        const lightAppointment: Appointment = {};
        lightAppointment.vehicle = this.appointment.vehicle;
        lightAppointment.serviceAdvisor = this.appointment.serviceAdvisor;
        lightAppointment.startTime = this.appointment.startTime;
        lightAppointment.endTime = this.appointment.endTime;
        lightAppointment.mobilityList = this.appointment.mobilityList;
        lightAppointment.timeSlot = this.appointment.timeSlot;
        lightAppointment.appointmentSource = 'ONLINE';
        lightAppointment.note = this.appointment.note;
        lightAppointment.courtesyCarRequired = this.appointment.courtesyCarRequired;
        lightAppointment.fastLane = this.appointment.fastLane;
        lightAppointment.fastLaneTimeSlot = this.appointment.fastLaneTimeSlot;
        lightAppointment.fastLaneResourceId = this.appointment.fastLaneResourceId;
        lightAppointment.waitInOutlet = this.appointment.waitInOutlet;
        lightAppointment.customerGcdm = !this.isFromWithoutRegistration;
        lightAppointment.preferedReturnTimeStart = this.appointment.preferedReturnTimeStart;
        lightAppointment.preferedReturnTimeEnd = this.appointment.preferedReturnTimeEnd;
        lightAppointment.returnTime = this.appointment.returnTime;
        lightAppointment.contactAppointment = this.appointment.contactAppointment;
        lightAppointment.sendSms = this.appointment.customer.preferences.contactBySms;
        lightAppointment.sendEmail = this.appointment.customer.preferences.contactByMail;
        lightAppointment.withoutRegistration = this.isFromWithoutRegistration;
        lightAppointment.remoteKeyRead = this.appointment.remoteKeyRead;

        let customer: Customer;
        if (!this.isFromWithoutRegistration) {
            customer = {
                id: this.appointment.customer.id,
            };
        } else {
            customer = this.appointment.customer;
            customer.customerType = 'PERSON';
        }

        lightAppointment.customer = customer;

        lightAppointment.outlet = {
            id: this.appointment.outlet.id,
        };
        if (this.appointment.ticketTssbId) {
            lightAppointment.ticketTssbId = this.appointment.ticketTssbId;
        }
        if (this.appointment.ticketLeadTssbId) {
            lightAppointment.ticketLeadTssbId = this.appointment.ticketLeadTssbId;
        }

        if (this.appointment.attachments) {
            lightAppointment.attachments = this.appointment.attachments.map(attachment =>
                this.getAttachmentFullName(attachment)
            );
        }
        lightAppointment.activityList = [];
        this.appointment.activityList.forEach(activity =>
            lightAppointment.activityList.push({
                name: activity.name,
                activityType: !this.technicalCampaignActivitiesIds.includes(activity.activityType.id) ? {
                    id: activity.activityType.id,
                    activityCategory: {
                        id: activity.activityType.activityCategory.id,
                    },
                } : undefined,
                activityExtradata: activity.activityExtradata,
                aprilActivityId: activity.aprilActivityId,
                iconClass: this.technicalCampaignActivitiesIds.includes(activity.activityType.id) ? activity.iconClass : undefined,
                catalogType: this.technicalCampaignActivitiesIds.includes(activity.activityType.id) ? activity.catalogType : undefined,
                duration: this.technicalCampaignActivitiesIds.includes(activity.activityType.id) ? activity.duration : undefined,
            })
        );

        function randomString(length, chars) {
            var result = '';
            for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
            return result;
        }

        if(this.countryConfiguration.welcomeTerminalAppointmentIdenabled){
            lightAppointment.welcomeTerminalAppointmentId = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ').toUpperCase();
        }

        if(this.technicalCampaignActivitiesIds.length != 0) {
            lightAppointment.technicalCampaignsAvailable = true;
        }

        return this.appointmentService.book(lightAppointment);
    }

    public onChangeActivity(event: EventSci) : void {
        if(Utils.isNullOrUndefined(event) || event.isOnlineTechnicalCampaign === false) {
            return;
        }
        const index = this.technicalCampaignActivitiesIds.indexOf(event.value);
        if(event.eventType === 'ADD') {
            this.technicalCampaignActivitiesIds.push(event.value);
        }
        else if(event.eventType === 'REMOVE' && index > -1) {
            this.technicalCampaignActivitiesIds.splice(index, 1);
        }
    }

    private updateAppointment(): Promise<void> {
        if (
            this.appointment.mobilityList &&
            this.appointment.mobilityList[0] &&
            this.appointment.mobilityList[0].vehicle
        ) {
            this.appointment.mobilityList[0].vehicle = undefined;
        }

        /**
         * During the update process for the newly selected technical campaigns, 
         * it's important to omit sending information related to workTeam or activityType,
         * similar to how it's handled during the initial creation.
         */
        this.appointment.activityList.forEach(activity => {
            if(Utils.isNotNullOrUndefined(activity.activityType) && this.technicalCampaignActivitiesIds.includes(activity.activityType.id)) {
                activity.workTeam = null;
                activity.activityType = null;
                activity.catalogType =  activity.catalogType;
                activity.duration = activity.duration;
                activity.iconClass = activity.iconClass;
            }
        });

        return this.appointmentService.update(this.appointment, this.appointment.id);
    }

    public trackByActivity(activity: Activity) {
        let result = null;

        if (activity && activity.activityType) {
            result = activity.activityType.id;
        }

        return result;
    }

    public dismiss(): void {
        this.alertConfirm.titleTmpl = null;
        this.alertConfirm.contentTmpl = null;
        this.alertConfirm.show().then(result => {
            if (result.value) {
                if (this.isFromWithoutRegistration) {
                    this.router.navigate(['/welcome']);
                } else {
                    this.router.navigate([this.authService.getDashboardPath()]);
                }
            }
        });
    }

    public defaultOutletIsManageable(): boolean {
        if (this.appointment.outlet.status === <StatusType>'inactive') {
            this.alertWarning.titleTmpl = this.favouriteOutletInactiveTitleTmpl;
            this.alertWarning.contentTmpl = this.favouriteOutletInactiveTextTmpl;
            this.alertWarning.show();
            return false;
        }
        const welcomeCategory = this.retrieveWelcomeCategory(this.appointment.vehicle.model.brand);
        if (
            !this.appointment.outlet.skills ||
            !this.appointment.outlet.skills.some(actCat => actCat.id === welcomeCategory)
        ) {
            return false;
        }

        // categories
        const categoriesId = this.appointment.activityList
            .filter(
                act => act.catalogType !== 'TECHNICAL_CAMPAIGNS' && act.activityType.activityPackageId !== 'FIXEDPRICES'
            )
            .map(act => act.activityType.activityCategory.id);
        if (this.appointment.fastLane) {
            categoriesId.push('FAST_LANE');
        }

        if (
            !categoriesId.every(caterogyId =>
                this.appointment.outlet.skills.map(skill => skill.id).includes(caterogyId)
            )
        ) {
            return false;
        }

        return true;
    }

    public goToStepRelative(dir: number): void {
        let index = this.wizard.selectedIndex;
        index += dir;

        if (
            index === OnlineAppointmentWizardComponent.CUSTOMER_DATA_STEP &&
            (!this.isFromWithoutRegistration || this.isUpdateLink)
        ) {
            index++;
        }

        if (this.isOutletChange(this.appointment.outlet)) {
            this.activityComponent.ngOnInit();
            index = OnlineAppointmentWizardComponent.ACTIVITY_STEP;
            dir = 0;
        }

        let recheck: boolean;
        do {
            recheck = false;

            // show warning fixed prices
            if (index === OnlineAppointmentWizardComponent.OUTLET_STEP && this.showWarningFixedPrices) {
                this.campaignDate =
                    this.activityPriceComponent.fixedPriceList[0].startValidityTime.getDate() +
                    '/' +
                    (this.activityPriceComponent.fixedPriceList[0].startValidityTime.getMonth() + 1) +
                    '/' +
                    this.activityPriceComponent.fixedPriceList[0].startValidityTime.getFullYear() +
                    '-' +
                    this.activityPriceComponent.fixedPriceList[0].endValidityTime.getDate() +
                    '/' +
                    (this.activityPriceComponent.fixedPriceList[0].endValidityTime.getMonth() + 1) +
                    '/' +
                    this.activityPriceComponent.fixedPriceList[0].endValidityTime.getFullYear();

                this.warningYearDate = new Date().getFullYear() - 5;

                if (this.activityPriceComponent.sameDates) {
                    this.warningFixedPricesSameDates.show();
                } else {
                    this.warningFixedPricesDistinctDates.show();
                }
                this.showWarningFixedPrices = false;
            }

            // outlet
            // if outlet is already preselected then skip step
            if (
                index === OnlineAppointmentWizardComponent.OUTLET_STEP &&
                this.appointment.outlet &&
                this.defaultOutletIsManageable() &&
                !this.isUpdateLink
            ) {
                index += dir;
                recheck = true;
                if (
                    this.showBodyAndPaintAlert &&
                    this.appointment.activityList.some(
                        act =>
                            act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
                            act.activityType.activityPackageId === 'BODY_AND_PAINT'
                    )
                ) {
                    this.alertWarning.titleTmpl = this.bodyAndPaintAlertTitleTmpl;
                    this.alertWarning.contentTmpl = this.bodyAndPaintAlertTextTmpl;
                    this.alertWarning.show();
                    this.showBodyAndPaintAlert = false;
                }
            }
            // mobility
            // if selected outlet doesn't have at least one mobility service or the user wait in outlet then skip step
            if (
                index === OnlineAppointmentWizardComponent.MOBILITY_STEP &&
                ((this.appointment.outlet &&
                    (!this.appointment.outlet.outletMobilityTypeList ||
                        this.appointment.outlet.outletMobilityTypeList.length === 0)) ||
                    this.appointment.waitInOutlet)
            ) {
                index += dir;
                recheck = true;
            }
        } while (recheck);
        this.wizard.selectedIndex = index;
    }

    public isOutletChange(outlet: Outlet) {
        if (outlet === this.outlet) {
            return false;
        } else {
            this.outlet = outlet;
            return true;
        }
    }

    public onChangeAttachments(attachments: string[]) {
        this.appointment.attachments = attachments;
    }

    public getAttachmentFullName(attachment: string) {
        const sp = attachment.split('/');
        return sp[sp.length - 1];
    }

    public hasFixedPrices() {
        if (!this.appointment.outlet) {
            this.showWarningFixedPrices = false;
            this.existFixedPrices = false;
        } else {
            this.activityService
                .getFixedPriceCount(this.appointment.outlet.id, this.appointment.vehicle.model.id)
                .then(con => {
                    this.showWarningFixedPrices = con > 0;
                    this.existFixedPrices = con > 0;
                });
        }
    }

    public isBasketReadOnly(): boolean {
        console.log('isBasketReadOnly//isBasketReadOnly')
        return (
            (this.wizard.selectedIndex > 0 && this.appointment.activityList.length === 1) ||
            (countryConfiguration.automaticSelectionCbs99999 &&
                this.appointment.activityList.length === 2 &&
                !Utils.isNullOrUndefined(
                    this.appointment.activityList.find(
                        activity => activity.activityType.id === '0000105' && activity.aprilActivityId === '0000105'
                    )
                ))
        );
    }

    public getReadOnlyActivityIds(): string[] {
        return countryConfiguration.automaticSelectionCbs99999 ? ['0000105'] : [];
    }

    public showSupportChat(): boolean {
        return (
            this.wizard?.selectedIndex === OnlineAppointmentWizardComponent.ACTIVITY_STEP ||
            this.wizard?.selectedIndex === OnlineAppointmentWizardComponent.SADV_STEP
        );
    }

    public sendPageViewForServicesSelection(): void {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasServicesSelection,
            dataAnalytics.page.variant.real
        );

        this.tracyService.sendPageView(dataAnalytics.referenceName.serviceSelection);
    }

    public outletChange(outlet: Outlet) {
        this.setDealerInformationIntoDataAnalytics(outlet);
    }

    public setDealerInformationIntoDataAnalytics(outlet: Outlet) {
        if (!Utils.isNullOrUndefined(outlet)) {
            this.tracyService.setDealerInformationIntoDataAnalytics(
                outlet.dealerId,
                outlet.businessName,
                outlet.id,
                'selected'
            );
        }
    }

    public goToStepFromResumeStep(pageNumber: number): void {
        this.wizard.selectedIndex = pageNumber;
    }

    public initAgendaComponent() {
        this.sendPageViewForAgendaComponent();
        this.agendaComponent.init(this.searchSlot);
    }

    private sendPageViewForAgendaComponent(): void {
        this.tracyService.sendPageView(dataAnalytics.referenceName.appointmentSchedule);
    }

    public initResumeComponent() {
        this.sendPageViewForAppointmentSummary();
        this.appointmentResumeComponent.init(this.appointment);
    }

    private sendPageViewForAppointmentSummary(): void {
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasAppointmentSummary,
            dataAnalytics.page.variant.real
        );

        this.tracyService.sendPageView(dataAnalytics.referenceName.appointmentSummary);
    }

    private sendPageViewForAppointmentSuccess(): void {
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasAppointmentSuccess,
            dataAnalytics.page.variant.real
        );

        this.tracyService.sendPageView(dataAnalytics.referenceName.appointmentSuccess);
    }

    public setDataAnalyticsForProductAndServicesInSuccessPage(
        vehicle: Vehicle,
        activityList: Activity[],
        mobilityList: Mobility[]
    ) {
        if(Utils.isNullOrUndefined(dataAnalytics) || Utils.isNullOrUndefined(dataAnalytics.product)) {
            return;
        }
        this.tracyService.setProductInformationIntoDataAnalytics(
            Utils.hashSha1(vehicle.vin),
            dataAnalytics.product.productType.vehicle,
            vehicle.model.modelLabel,
            vehicle.model.brand,
            vehicle.model.id,
            dataAnalytics.product.vehicleSelection.selected
        );

        activityList.forEach(activity => {
            const brand = Utils.isNotNullOrUndefined(activity.workTeam) ? activity.workTeam.brand : 'na';
            const activityTypeId = Utils.isNotNullOrUndefined(activity.activityType) ? activity.activityType.id : 'na';
            this.tracyService.setProductInformationIntoDataAnalytics(
                activityTypeId,
                dataAnalytics.product.productType.service,
                activity.name,
                brand,
                'na',
                'na',
                'na',
                'na'
            );
        });

        // mobility services
        mobilityList.forEach(mobility => {
            this.tracyService.setProductInformationIntoDataAnalytics(
                String(mobility.id),
                dataAnalytics.product.productType.service,
                mobility.mobilityType.descriptionLocalized,
                'na',
                'na',
                'na',
                'na',
                'na'
            );
        });
    }

    public updateCustomerData(): void {
        const customerFromForm = this.customerFormComponent.turnIntoModel();

        this.appointment.customer = customerFromForm;

        this.appointment.customer.displayName = customerFromForm.name + ' ' + customerFromForm.surname;
        this.appointment.customerVatNumber = customerFromForm.vatNumber;
        this.appointment.customer.preferences = {
            contactByMail: true,
            contactBySms: true,
        };
        this.appointment.contactAppointment = {
            email: customerFromForm.email,
            gender: customerFromForm.gender,
            firstName: customerFromForm.name,
            lastName: customerFromForm.surname,
            mobile: customerFromForm.phoneNumber,
        };
    }

    public isNextButtonOfCustomerDataStepDisabled(): boolean {
        return (
            this.isFromWithoutRegistration &&
            !(
                this.customerFormComponent &&
                this.customerFormComponent.isValid() &&
                this.customerFormWithoutRegistrationTermsComponent.isTermsAndConditionsAccepted()
            )
        );
    }

    public getDefaultStepIndex() {
        return this.appointment.id ? OnlineAppointmentWizardComponent.RESUME_STEP : 0;
    }

    public onActivityStepBackButtonClicked(): void {
        this.location.back();
        sessionStorage.setItem('isTCAccepted', 'true');
    }

    public updateSendPreRegistrationEmailValue(value: boolean): void {
        this.sendPreRegistrationEmail = value;
    }

    public onTermsAndConditionsChecked(): void {
        this.customerFormComponent.markAsTouched();
    }
    onDelearChange() {
        this.isDelearChange = true;
        // this.tracyService.setPageInformationIntoDataAnalytics(
        //     dataAnalytics.page.pageID.lOasServiceSelectionModified,
        //     dataAnalytics.page.variant.virtual
        // );
        // this.tracyService.setUserInformationIntoDataAnalytics('na');

        // this.tracyService.sendPageView(dataAnalytics.referenceName.serviceSelectionModified);
    }
    sendPageViewForServicesSelectionModified() {
        // if (this.isDelearChange) {
        //     this.tracyService.setPageInformationIntoDataAnalytics(
        //         dataAnalytics.page.pageID.lOasServiceSelectionModified,
        //         dataAnalytics.page.variant.virtual
        //     );
        //     this.tracyService.setUserInformationIntoDataAnalytics('na');
        //     this.tracyService.sendPageView(dataAnalytics.referenceName.serviceSelectionModified);
        // }
    }
    public editProfile() {
        return (
            this.ssoUrl +
            'profile' +
            '?client=' +
            this.client +
            '&country=' +
            this.countryConfiguration.countryCode +
            '&language=' +
            this.countryConfiguration.language +
            '&brand=' +
            this.getCurrentBrandToLowercase(this.profileBrand)
        );
    }

   getCurrentBrandToLowercase(brand: string){
      if(brand == "BMW_MOTORRAD"){
         return "bmwmotorrad";
      }
      return brand.toLowerCase();
   }

   getCurrentBrand() {
      if (this.hostname.includes('.mini.')) {
         this.profileBrand = 'MINI';
      } else if (this.hostname.includes('.bmw-motorrad.')) {
         this.profileBrand = 'BMW_MOTORRAD';
      } else {
         this.profileBrand = 'BMW';
      }
   }

   public onStepChange(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex === 0 &&  event.previouslySelectedIndex < event.selectedIndex) {
         this.canExitActivityStep().then(result => {
            if(!result) {
                this.wizard.selectedIndex = 0;
            } else {
                this.wizard.selectedIndex = event.selectedIndex;
            }
        }) 
    }
    switch (event.selectedIndex) {
        case 0: 
            this.disableRemove = false;
            break;
        case 1:
            this.myMap.init(this.categoryDurationMap, this.appointment.customer.favouriteOutletId, this.appointment.fastLane,this.isDelearChange);
            break;
        case 2:
            this.mobility.init(this.appointment.outlet, this.appointment.customer);    
            break;             
        case 3: 
            this.initAgendaComponent();
            break;
        case 5: 
            this.initResumeComponent();
            break;
        default:
    }
}
}
