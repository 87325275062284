import { CountryConfiguration } from '../app/country-configuration';
import { RoleType } from '../app/shared/entities';
export let italyCountryConfiguration: CountryConfiguration = {
    language: 'it',
    countryCode: 'IT',
    welcomeTerminalAppointmentIdenabled: false,
    appointmentCountForSadvInDashboard: true,
    showCarShadowImageInClientSearch:false,
    locale: 'it-IT',
    nscCicSearchOnDMS: true,
    phoneNumberRegex: /^(([+]|00)39)?[0-9]{1,11}$/,
    mobileNumberRegex: [
        /^(([+]|00)39)?[0-9]{9,11}$/,
        /^(([+]|00)49)[2-9][0-9]{9,10}$/,
        /^(([+]|00)33)[1-79][0-9]{8}$/,
        /^(([+]|00)34)([6-7][0-9]{8}|[8-9][1-9][0-9]{7})$/,
        /^(([+]|00)41)[0-9]{9}$/,
        /^(([+]|00)3861)[0-9]{7}$/,
    ],
    vatRegex: /^[0-9]{11}$/,
    fiscalCodeRegex: /^([0-9a-zA-Z]{16}|[0-9]{11})$/,
    dsaFilePath: 'assets/pdf/IT/DSA_Notice.pdf',
    showDSALink: true,
    BMW: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.it/it/footer/metanavigation/informativa-privacy.html',
            legalNoteLink: 'https://www.bmw.it/it_IT/footer/metanavigation/legal-notice-pool/imprint.html',
            contactLink: 'https://www.bmw.it/it/topics/mondo-bmw/contatti-bmw.html',
            textHomeLink: 'BMW.IT',
            homeLink: 'https://www.bmw.it/it/home.html',
            vatNumber: 'Partita IVA - IT12532500159',
        },
        srpCampaignFootNote:
            '<span>Prezzi comprensivi di IVA, ricambi e mano d’opera con sconto minimo garantito del 20%</span>',
        tssbInfoUrl: 'https://www.bmw.it',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'appuntamento-online_bmw_it',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: true,
        remoteKeyReadLink: 'https://www.bmw.it/it/footer/metanavigation/legal-information.html',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    MINI: {
        footer: {
            privacyPolicyLink: 'https://www.mini.it/it_IT/home/footer/privacy-policy.html',
            legalNoteLink: 'https://www.mini.it/it_IT/home/footer/Legal.html',
            contactLink: 'https://www.mini.it/it_IT/home/footer/easy-mini.html',
            textHomeLink: 'MINI.IT',
            homeLink: 'https://www.mini.it/it_IT/home.html',
            vatNumber: 'Partita IVA - IT12532500159',
        },
        srpCampaignFootNote:
            '<span>Prezzi comprensivi di IVA, ricambi e mano d’opera con sconto minimo garantito del 20%</span>',
        tssbInfoUrl: 'https://www.mini.it',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'appuntamento-online_mini_it',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: true,
        remoteKeyReadLink: 'https://www.mini.it/it_IT/home/terms-and-conditions/mini-connected-legal-information.html',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_MOTORRAD: {
        footer: {
            privacyPolicyLink: 'https://www.bmw-motorrad.it/it/public-pool/content-pool/privacy.html',
            legalNoteLink: 'https://www.bmw-motorrad.it/it/public-pool/content-pool/nota-legale.html',
            contactLink: 'https://www.bmw-motorrad.it/it/public-pool/content-pool/contatti.html',
            textHomeLink: 'BMW-MOTORRAD.IT',
            homeLink: 'https://www.bmw-motorrad.it/it_IT/home.html',
            vatNumber: 'Partita IVA - IT12532500159',
        },
        srpCampaignFootNote: '',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: true,
        longLifeCareMobilityBanner: false,
        ePaas: {
            enabled: true,
            tenantId: 'appuntamento-online_bmw-motorrad_it',
        },
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_I: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.it/it/footer/metanavigation/informativa-privacy.html',
            legalNoteLink: 'https://www.bmw.it/it_IT/footer/metanavigation/legal-notice-pool/imprint.html',
            contactLink: 'https://www.bmw.it/it/topics/mondo-bmw/contatti-bmw.html',
            textHomeLink: 'BMW.IT',
            homeLink: 'https://www.bmw.it/it/home.html',
            vatNumber: 'Partita IVA - IT12532500159',
        },
        srpCampaignFootNote:
            '<span>Prezzi comprensivi di IVA, ricambi e mano d’opera con sconto minimo garantito del 20%</span>',
        tssbInfoUrl: 'https://www.bmw.it',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'appuntamento-online_bmw_it',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: 'https://www.bmw.it/it/footer/metanavigation/legal-information.html',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    tssb: {
        enable: false,
        enableRecentPendingAppointmentCheckboxByRoles: new Map<RoleType, boolean>(),
        enableEmail: true,
    },
    emailQuickAppointment: 'no-reply-oas-it@bmw.it',
    showAllCapacityTeams: true,
    waitInOutlet: true,
    mobilityStepAvailability: false,
    enableToros: false,
    editCustomer: true,
    vatNumberValidator: '',
    dcomHealth: true,
    showFastLaneLink: true,
    // Quick appointment REFACTOR
    latitude: 41.9,
    longitude: 12.5,
    createVehicleFiscalCode: false,
    dcomValidation: true,
    mapsRadius: 20000,
    mapsZoom: 13,
    updateDMS: false,
    maxDelay: 72,
    daysOfWeek: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    enableRenting: false,
    enableNscReport: true,
    nscReportFilename: 'Appuntamenti NSC Dettagliati.csv',
    enableSMS: true,
    sendSMS: true,
    automaticSelectionCbs99999: false,
    enableBsi: true,
    hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration: false,
    enableKiosk: true,
    enablePPVSync: false,
    enableTjekvik: true,
    enableSharebox: true,
    enableSPOA: true,
    enableVinLookup: true,
    preRegistration: {
        enablePreRegistrationFromDms: false,
        enablePreRegistrationFromFastAppointment: false,
        hideCheckBoxWithoutRegistration: false,
        forceSendEmailWithoutRegistration: false,
    },
    showVinOnlineAppointmentResume: false,
    showFiscalNumberVehicleForm: false,
    servicesBannerEnableWithoutCampaign: true,
    vehicleFormSmartRegistrationType: 'PLATE',
    showAppointmentWithoutRegistrationVehicleForm: false,
    fastAppointmentMigrationToConsumeCapacity: true,
    dateInputRangeFormat: 'DD/MM/YYYY',
    exchangeMethodTrans: {
        in: 'consegna',
        out: 'ritiro',
        'multi-in': 'multi+in',
        'in+out': 'consegna+ritiro',
        'out+in': 'ritiro+consegna',
        'drop-in': 'Consegna spontenea',
        'check-in': 'Consegna senza appuntamento',
    },
    colorTrans: {
        green: 'Verde',
        red: 'Rosso',
        white: 'Bianco',
    },
    phonePrefix: '+39',
    enableLeadApi: false,
    currencyCode: 'EUR',
    enableEmail: true,
    sendEmail: true,
    leadApiOutlets: [],
    leadApiOutletFilterEnable: false,
    leadTssbAsmEnable: false,
    appointmentFastCustomerCreationGenderEnabled:false,
    eitherPhoneOrEmailEnabled:true,
    showOnlineWorkTeam: false,
    workingDaysEnabled:true,
    dropDownLeadStatuses: [],
    availabilityFilter: true,
    awp: {
        url: 'https://awp.bmwgroup.com/servicedemand?',
        vinParameter: 'Vin=',
        icon: {
            enabled: true,
            url: 'awp:/start?app=vehicledetails&vin=${vin}',
        }
    },
    enableLeadBattery: false,
};
