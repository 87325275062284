import { Logger } from '../../logger.service';
import { HttpResponse } from '@angular/common/http';

export class ApiUtils {
    public static handleError(error: any, logger?: Logger): Promise<any> {
        if (logger) {
            logger.debug('error');
            logger.debug(error);
        }

        return Promise.reject(error);
    }

    public static retrieveFileName(response: HttpResponse<any>): string {
        const contentDisposition = response.headers.get('Content-Disposition') || '';
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();
        return fileName;
    }
}
