import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { countryConfiguration } from '../../../../configuration/country.configuration';
import { environment } from '../../../../environments/environment';
import { ActivityService } from '../../../core/api/activity/activity.service';
import { AttachmentService } from '../../../core/api/attachment/attachment.service';
import { BusinessSettingService } from '../../../core/api/business-setting.service';
import { DictionaryService } from '../../../core/api/dictionary.service';
import { VehicleService } from '../../../core/api/vehicle/vehicle.service';
import { Utils } from '../../../core/utils';
import { OasValidators } from '../../../core/validator/oas-validators';
import { CountryConfiguration } from '../../../country-configuration';
import { AlertWarningComponent } from '../../../shared/alert/alert-warning.component';
import { Attachment } from '../../../shared/attachment/entity';
import {
   Activity,
   ActivityCategory,
   ActivityCategoryOnlineDTO,
   ActivityPackage,
   ActivityPart,
   Appointment,
   BusinessSettingType,
   CatalogType,
   EventSci, Outlet,
   Setting, OnlineTechnicalCampaign,
   Vehicle,
   VehicleBrand,
   ActivityPackageOnlineDTO,
} from './../../../shared/entities';
import * as moment from "moment";
import {AlertConfirmComponent} from "../../../shared/alert/alert-confirm.component";

@Component({
    selector: 'oas-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ActivityComponent implements OnInit, AfterViewInit {
    @Input() public vehicle: Vehicle;
    @Input() public activities: Activity[];
    @Input() public appointment: Appointment;
    @Input() fastLaneSelected = false;
    @Input() waitInOutletSelected = false;
    @Input() public preselectedActivityPackageIds: String[];
    @Input() public selectedOutlet: Outlet;
    @Input() public preselectedActivityCategoryId: string;

    @Output() private categoryDurationMapChange = new EventEmitter<Map<string, number>>();
    @Output() fastLaneSelectedChange = new EventEmitter<boolean>();
    @Output() waitInOutletSelectedChange = new EventEmitter<boolean>();
    @Output() attachmentsChange = new EventEmitter<string[]>();
    @Output() activityEvent = new EventEmitter<EventSci>();

    @ViewChild('warningFastLane') warningFastLane: AlertWarningComponent;
    @ViewChild(AlertWarningComponent) alertWarningComponent: AlertWarningComponent;
    @ViewChild('technicalCampaignWithStaticService') alertConfirmComponent: AlertConfirmComponent;
    @ViewChild('warningImpossibleToPreSelect') private alertWarningUnableToPreSelectCbs: AlertWarningComponent;
    @ViewChild('technicalCampaignAvailableTitle') private technicalCampaignAvailableTitle: TemplateRef<any>;
    @ViewChild('technicalCampaignAvailableText') private technicalCampaignAvailableText: TemplateRef<any>;
    @ViewChild('staticSecondaryServiceTitle') private staticSecondaryServiceTitle: TemplateRef<any>;
    @ViewChild('staticSecondaryServiceText') private staticSecondaryServiceText: TemplateRef<any>;
    @ViewChild('technicalCampaignWithStaticServiceText') private technicalCampaignWithStaticServiceText: TemplateRef<any>;
    @ViewChildren('chkPackages') private chkPackages: QueryList<ElementRef>;


    public activityCategories: ActivityCategoryOnlineDTO[];
    public outletMarketingCampaign: ActivityCategoryOnlineDTO[];
    public marketingCampaignAvailable: boolean = false;
    public loading = false;
    public searchActivity = new UntypedFormControl();
    public totalDuration = 0;
    public fastLaneThreshold = environment.fastLaneThreshold;
    public attachmentsUrl: string = environment.attachmentsUrl;
    public attchmentsEnabled: boolean = environment.attachmentsEnabled;
    public attachments: Attachment[];
    public showFastLaneLink: boolean;
    public note = new UntypedFormControl(null, [OasValidators.getLatinValidator()]);
    private categoryDurationMap = new Map<string, number>();
    public countryConfiguration: CountryConfiguration;
    public servicesBannerText: String;
    public bsiLabelText: String;
    public servicesBannerEnableWithoutCampaign: boolean;
    public activeTabIndex = 0;
    public activityPackageExclusions: Map<string, string[]>;
    public technicalCampaignsResponse: OnlineTechnicalCampaign;
    public activeCampaignCategories: ActivityCategoryOnlineDTO[] = [];
    public hostname: string;
    public hideFastLane:boolean;
    constructor(
        private activityService: ActivityService,
        private attachmentService: AttachmentService,
        private dictionaryService: DictionaryService,
        private vehicleService: VehicleService,
        private sanitizer: DomSanitizer,
        private businessSettingService: BusinessSettingService,
        countryConfigurationService: CountryConfigurationService,
        private route: ActivatedRoute,
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.hostname = window.location.hostname;
    }

    public ngOnInit() {
        this.hideFastLane = Utils.outletHasSkill(this.selectedOutlet, 'FAST_LANE');
        this.attachments = [];
        if(this.selectedOutlet){
           this.activityService.findByOutletIdAndBrand(this.selectedOutlet.id, this.vehicle.model.brand).then(response => {
              this.outletMarketingCampaign = response;
              if(this.outletMarketingCampaign && this.outletMarketingCampaign.length){
                this.showOrHideMarketingCampaign();
              }
              this.retrieveAvailableActivities();
           });
        } else {
           this.retrieveAvailableActivities();
        }

        if (this.appointment.id) {
            this.attachmentService.getAppointmentAttachments(this.appointment.id).then(
                attachments =>
                    (this.attachments = attachments.map((attachment, index, array) => {
                        return {
                            loading: false,
                            path: attachment,
                            name: this.getAttachmentName(attachment),
                            fullName: this.getAttachmentFullName(attachment),
                        } as Attachment;
                    }))
            );
        }

        this.servicesBannerEnableWithoutCampaign = this.countryConfiguration.servicesBannerEnableWithoutCampaign;

        this.fillServicesBanner();
        this.getVehicleBsiContract();
    }

    public ngAfterViewInit() {
        if (this.route.snapshot.queryParamMap.has('showErrorImpossibleToAutomaticSelectActivities')) {
            this.alertWarningUnableToPreSelectCbs.show();
        }
    }

    private getVehicleBsiContract() {
        this.vehicleService.getVehicleBsiInformation(this.vehicle.vin).then(response => {
            this.vehicle.bsiContracts = response.payload;
            if (this.countryConfiguration.enableBsi && this.vehicle.bsiContracts) {
                this.fillBsiLabel();
            }
        });
    }

    private fillServicesBanner() {
        this.dictionaryService
            .findByNameAndBrand('SERVICES_BANNER_DESCRIPTION', this.vehicle.model.brand)
            .then(response => {
                if (response) {
                    this.servicesBannerText = response.value;
                }
            });
    }

    private fillBsiLabel() {
        this.dictionaryService.findByNameAndBrand('BSI_LABEL_DESCRIPTION', this.vehicle.model.brand).then(response => {
            if (response) {
                this.bsiLabelText = response.value;
            }
        });
    }

    public updateTotalDuration(): void {
        this.totalDuration = this.activities.reduce((ac, cv) => (ac += cv.duration), 0);
        if (this.fastLaneSelected && this.totalDuration > this.fastLaneThreshold) {
            this.selectFastLane();
        }
    }

    public showCampaignLabel(): boolean {
        let showCampaignLabel = false;
        if (!Utils.isNullOrUndefined(this.activityCategories)) {
            this.activityCategories.forEach(category => {
                category.activityPackageList.forEach(availablePkg => {
                    showCampaignLabel = showCampaignLabel || !Utils.isNullOrUndefined(availablePkg.srpCampaign);
                });
            });
            return showCampaignLabel;
        }
    }

    private retrieveAvailableActivities(): void {
        this.loading = true;

        let activityCategoriesPromise: Promise<ActivityCategoryOnlineDTO[]>;
        if (!Utils.isMotorrad(this.vehicle.model.brand)) {
            const showErrorImpossibleToAutomaticSelectActivities = this.route.snapshot.queryParamMap.has(
                'showErrorImpossibleToAutomaticSelectActivities'
            );
            const selectedCbsIds = this.route.snapshot.queryParamMap.getAll('selectedCbsIds');
            activityCategoriesPromise = this.activityService.getAvailableActivitiesWithCbsAndCampaigns(
                this.vehicle.model.id,
                this.vehicle.vin,
                this.appointment.outlet ? this.appointment.outlet.id : null,
                showErrorImpossibleToAutomaticSelectActivities ? [] : selectedCbsIds
            );
        } else {
            activityCategoriesPromise = this.activityService.getAvailableActivities(this.vehicle.model.id);
        }

        let technicalCampaignPromise: Promise<OnlineTechnicalCampaign> = Promise.resolve(undefined);;
        
        
        const outletHasTechnicalCampaignSkillEnabled =
            (this.getCurrentBrand() !== 'moto' && Utils.outletHasSkill(this.selectedOutlet, "TECHNICAL_CAMPAIGN")) || 
            (this.getCurrentBrand() === 'moto' && Utils.outletHasSkill(this.selectedOutlet, "TECHNICAL_CAMPAIGNS_MOTO"));
        
        if(Utils.isNotNullOrUndefined(this.vehicle) && Utils.isNotNullOrUndefined(this.vehicle.vin) && outletHasTechnicalCampaignSkillEnabled) {
            const excludeBookedTC = Utils.isNotNullOrUndefined(this.appointment) && Utils.isNullOrUndefined(this.appointment.id);
            technicalCampaignPromise = this.activityService.getAvailableTechnicalCampaigns(this.vehicle.vin, excludeBookedTC, this.vehicle.model.brand);
        }

        let bsiLinkPromise: Promise<Setting> = Promise.resolve(undefined);
        if (countryConfiguration.enableSPOA) {
            bsiLinkPromise = this.businessSettingService.getSetting(
                this.getBsiLinkSettingType(this.vehicle.model.brand)
            );
        }

        const activityPackageExclusionsPromise = this.activityService.getActivityPackageExclusions();

        const requestsPromises: Promise<any>[] = [bsiLinkPromise, activityCategoriesPromise, activityPackageExclusionsPromise, technicalCampaignPromise];

        Promise.all(requestsPromises.map(promise => 
            promise.catch(e => {
                // handle individual errors for each request
                console.error("an error occured : ", e);
                return null;
            })
        ))
        .then(result => {
            let bsiLink: string;
            if (Utils.isNotNullOrUndefined(result[0])) {
                bsiLink = result[0].value;
            }
            const activityCategories = Utils.isNotNullOrUndefined(result[1]) ? result[1] : [];
            this.activityPackageExclusions = result[2];
            this.technicalCampaignsResponse = result[3];
            
            this.activityCategories = activityCategories;

            const preselectedActivityCategoryTabIndex = this.activityCategories.findIndex(
                activityCategory => activityCategory.id === this.preselectedActivityCategoryId
            );
            if (preselectedActivityCategoryTabIndex !== -1) {
                this.activeTabIndex = preselectedActivityCategoryTabIndex;
            }
            this.activeCampaignCategories = [];

            this.activityCategories.forEach(category => {
                category.activityPackageList.forEach(availablePkg => {
                    if (!category.marketingCampaign) {
                        this.manageActivityDurationMap(availablePkg);
                        this.managePreselectedActivities(category, availablePkg);

                        if (availablePkg.selected) {
                            this.onSelect(category, availablePkg, true);
                        }
                    }

                    if (
                        !Utils.isNullOrUndefined(bsiLink) &&
                        !Utils.isNullOrUndefined(availablePkg.extendedDescription)
                    ) {
                        availablePkg.extendedDescription = availablePkg.extendedDescription.replace(
                            '{{SPOA_URL}}',
                            bsiLink
                        );
                    }
                });
                if (category.marketingCampaign && !this.activeCampaignCategories.includes(category)) {
                    this.activeCampaignCategories.push(category);
                }
            });

            if (!this.outletMarketingCampaign || this.outletMarketingCampaign.length == 0) {
                this.removeMarketingCampaignPackagesFromBasket();
            }

            if (this.technicalCampaignsResponse != null) {
                //insert technical campaign category
                const isStaticPackageAvailable = this.technicalCampaignsResponse.staticPackage != null;

                const isTCServicesAvailable = this.technicalCampaignsResponse.category != null
                    && Utils.isNotNullOrUndefined(this.technicalCampaignsResponse.category.activityPackageList)
                    && this.technicalCampaignsResponse.category.activityPackageList.length > 0;

                const isTCContainsStaticAndPackages = isTCServicesAvailable && isStaticPackageAvailable;

                if (isTCContainsStaticAndPackages) {
                    this.addAndPreselectTechnicalCampaigns();
                    this.alertConfirmComponent.titleTmpl = this.technicalCampaignAvailableTitle;
                    this.alertConfirmComponent.contentTmpl = this.technicalCampaignWithStaticServiceText;
                    this.addStaticServiceToOtherCategory();
                    // during appointment update by the customer the modal will not be shown
                    if (Utils.isNullOrUndefined(this.appointment.id)) {
                        this.alertConfirmComponent.show().then(result => {
                            const isSelectYes = Utils.isNotNullOrUndefined(result.value) ? true : false;
                            this.toggleStaticPackageSelection(isSelectYes, this.technicalCampaignsResponse.staticPackage);
                        });
                    }
                }

                else if (isTCServicesAvailable && Utils.isNullOrUndefined(this.appointment.id)) {
                    this.addAndPreselectTechnicalCampaigns();
                    // during appointment update by the customer the modal will not be shown
                    if (Utils.isNullOrUndefined(this.appointment.id)) {
                        this.alertWarningComponent.titleTmpl = this.technicalCampaignAvailableTitle;
                        this.alertWarningComponent.contentTmpl = this.technicalCampaignAvailableText;
                        this.alertWarningComponent.show();
                    }

                }

                //insert static service to the 'OTHER' category
                else if (isStaticPackageAvailable) {
                    this.alertConfirmComponent.titleTmpl = this.staticSecondaryServiceTitle;
                    this.alertConfirmComponent.contentTmpl = this.staticSecondaryServiceText;
                    this.addStaticServiceToOtherCategory();
                    if (Utils.isNullOrUndefined(this.appointment.id)) {
                        this.alertConfirmComponent.show().then(result => {
                            const isSelectYes = Utils.isNotNullOrUndefined(result.value) ? true : false;
                            this.toggleStaticPackageSelection(isSelectYes, this.technicalCampaignsResponse.staticPackage);
                        });
                    }
                }
            }

            this.activityCategories = this.activityCategories.filter(category => category.marketingCampaign == false);
            if (this.selectedOutlet) {
                this.activityCategories.forEach(category => {
                    this.outletMarketingCampaign.forEach(campaignCategory => {
                        let packages = campaignCategory.activityPackageList.filter(actPackage => {
                            return actPackage.showOnline && this.checkCorrectTab(category, actPackage.tab);
                        });
                        packages.forEach(p => {
                            p.categoryId = category.id;
                        });
                        category.activityPackageList = packages.concat(category.activityPackageList);
                    });
                });
            }
            this.loading = false;
        })
        .catch((e) => {
            console.error("an error occured to handle results of the promises", e);
            this.loading = false;
        });
    }

    /**
     * Toggle static package selection
     * @param isSelect A boolean flag indicating whether to pre-select or deselect the package
     * @param activityPackage The static activity package to be toggled
     */
    private toggleStaticPackageSelection(isSelect: boolean, activityPackage: ActivityPackageOnlineDTO) : void {
        const activityCategory = this.activityCategories.find(category => category.id == 'OTHER');
        if (isSelect) {
            this.onSelect(activityCategory, activityPackage, true);
        }
        else {
            this.onSelect(activityCategory, activityPackage, false);
        }
    }

    private getBsiLinkSettingType(brand: VehicleBrand): BusinessSettingType {
        let bsiLinkSettingType = <BusinessSettingType>'INFO_BOX_NO_BSI_LINK_BMW';
        switch (brand) {
            case <VehicleBrand>'MINI':
                bsiLinkSettingType = <BusinessSettingType>'INFO_BOX_NO_BSI_LINK_MINI';
                break;
            case <VehicleBrand>'BMW_MOTORRAD':
                bsiLinkSettingType = <BusinessSettingType>'INFO_BOX_NO_BSI_LINK_BMW_MOTORRAD';
                break;
        }
        return bsiLinkSettingType;
    }

    private addAndPreselectTechnicalCampaigns(){
        this.activityCategories.push(this.technicalCampaignsResponse.category);
        const technicalCampaignIndex = this.activityCategories.length - 1;
        this.activityCategories[technicalCampaignIndex].activityPackageList.forEach(activityPackage => {
            const preselectTC = Utils.isNullOrUndefined(this.appointment.id) 
                ? true
                : Utils.isNotNullOrUndefined(this.findActivityByActivityPackageId(activityPackage.id));

            this.onSelect(this.activityCategories[technicalCampaignIndex], activityPackage, preselectTC);
       });
    }

    private addStaticServiceToOtherCategory() :void {
        const otherCategory = this.activityCategories.find(category => category.id == `OTHER`);
        if(Utils.isNullOrUndefined(otherCategory)) {
            return;
        }
        otherCategory.activityPackageList.push(this.technicalCampaignsResponse.staticPackage);
    }

    private manageActivityDurationMap(availablePkg: ActivityPackage): void {
        const mActivity = this.activities.find(
            selectedAct => Utils.isNotNullOrUndefined(selectedAct.activityType) && selectedAct.activityType.activityPackageId === availablePkg.id
        );
        if (mActivity) {
            if (!mActivity.activityType.activityDuration) {
                // FIXME: Check it
                // mActivity.activityType = availablePkg;
            }
            this.updateCategoryDurationMap(availablePkg.categoryId, mActivity.activityType.activityDuration);
        }
    }

    private updateCategoryDurationMap(key: string, newValue: number): void {
        const oldValue = this.categoryDurationMap.has(key) ? this.categoryDurationMap.get(key) : 0;
        const value = oldValue + newValue;

        if (value === 0) {
            this.categoryDurationMap.delete(key);
        } else {
            this.categoryDurationMap.set(key, value);
        }

        if (this.fastLaneSelected && !this.isFastLaneAllowed()) {
            this.selectFastLane();
        }

        this.categoryDurationMapChange.emit(this.categoryDurationMap);
    }

    private managePreselectedActivities(activityCategory: ActivityCategory, availablePkg: ActivityPackage): void {
        if (this.preselectedActivityPackageIds.includes(availablePkg.id)) {
            this.onSelect(activityCategory,availablePkg, true);
        }
    }

    private onSelect(activityCategory: ActivityCategory, activityPackage: ActivityPackage, checked: boolean): void {

       //If there is a esclusitivty package select do nothing
       if (activityCategory.activityPackageList
        .filter(currActivityPackage => currActivityPackage !=activityPackage && currActivityPackage.isSelected
             && currActivityPackage.exclusive).length ==0){

                if (!(activityPackage.id === '0000105' && countryConfiguration.automaticSelectionCbs99999)) {
                    if (checked) {
                        this.addActivity(activityPackage);
                    } else {
                        this.removeActivity(activityPackage);
                    }

                    this.updateTotalDuration();
                }
                activityPackage.isSelected=checked;
                this.changePackageSelected(activityCategory, activityPackage, checked);
            }
    }

    private addActivity(activityPackage: ActivityPackage): void {

        const isPackageAlreadyExistInActivities = this.activities.some(a => 
            (Utils.isNotNullOrUndefined(a.activityType) &&
            Utils.isNotNullOrUndefined(activityPackage.id) &&
            a.activityType.id === '000-00-' + activityPackage.id) ||
            (a.aprilActivityId === activityPackage.id)
        );
        if(isPackageAlreadyExistInActivities === true) {
            return;
        }
        const isServiceFromApril = activityPackage.categoryId === 'SERVICE' && activityPackage.fromApril;
        const isServiceFromTechnicalCampaign = activityPackage.categoryId === 'TECHNICAL_CAMPAIGN' && activityPackage.tab === 'TECHNICAL_CAMPAIGN';
        const isStaticPackage = activityPackage.id === 'OTHER_EGR';
        const activityToAdd: Activity = {
            name: activityPackage.descriptionLocalized,
            activityType: {
                id: '000-00-' + activityPackage.id,
                descriptionLocalized: activityPackage.descriptionLocalized,
                showOnline: activityPackage.showOnline,
                activityPackageId: activityPackage.id,
                marketingCampaign: activityPackage.tab != null && activityPackage.workTeam != null,
                startValidityDate: activityPackage.activityTypeList && activityPackage.activityTypeList.length > 0 && activityPackage.activityTypeList[0] && activityPackage.tab && activityPackage.workTeam ? activityPackage.activityTypeList[0].startValidityDate : null,
                endValidityDate: activityPackage.activityTypeList && activityPackage.activityTypeList.length > 0 && activityPackage.activityTypeList[0] && activityPackage.tab && activityPackage.workTeam ? activityPackage.activityTypeList[0].endValidityDate : null
            },
            duration: activityPackage.duration,
        };

        if(isServiceFromTechnicalCampaign || isStaticPackage){
           activityToAdd.workTeam = isStaticPackage ? undefined : { workTeamType: { id: activityPackage.workTeamTypeId } };
           activityToAdd.activityType.activityDuration =  activityPackage.duration;
           activityToAdd.aprilActivityId = activityPackage.id;
           activityToAdd.iconClass = activityPackage.iconClass;
           activityToAdd.catalogType = 'TECHNICAL_CAMPAIGNS';
        }

        if (isServiceFromApril) {
            let partList: ActivityPart[] = null;
            if (activityPackage.activityTypeList && activityPackage.activityTypeList[0] !== undefined) {
                partList = [];
                activityPackage.activityTypeList[0].sparePartList.forEach(part => {
                    const newPart: ActivityPart = {
                        partReference: part.id,
                        partDescription: part.sparePart.descriptionList[0].description,
                        partQuantity: part.quantity,
                        partUnit: part.unit,
                    };
                    partList.push(newPart);
                });
            }

            activityToAdd.activityType.id = activityPackage.id;
            activityToAdd.activityType.activityCategory = {
                id: activityPackage.categoryId,
                descriptionLocalized: activityPackage.categoryDescription,
            };
            activityToAdd.catalogType = <CatalogType>'CBS';
        } else {
            activityToAdd.activityType.activityCategory = { id: activityPackage.categoryId };
        }

        const event: EventSci = {
            value: activityToAdd.activityType.id,
            eventType: 'ADD',
            isOnlineTechnicalCampaign: isServiceFromTechnicalCampaign || isStaticPackage
        };

        this.activityEvent.emit(event);
        this.activities.push(activityToAdd);

        this.updateCategoryDurationMap(activityPackage.categoryId, activityPackage.duration);

        /* Remove timeslot when changing an activity */
        if (activityPackage.duration !== 0) {
            Utils.clearTimeSlot(this.appointment);
        }

        if (
            countryConfiguration.automaticSelectionCbs99999 &&
            isServiceFromApril &&
            !this.activities.find(
                activity => activity.activityType.id === '0000105' || activity.aprilActivityId === '0000105'
            )
        ) {
            const linkedActivityToAdd: ActivityPackage = this.retrieveLinkedActivityByCategoryAndId(
                activityPackage.categoryId,
                '0000105'
            );
            if (!Utils.isNullOrUndefined(linkedActivityToAdd)) {
                this.addActivity(linkedActivityToAdd);
            }
        }

        if (Utils.isNotNullOrUndefined(this.activityPackageExclusions) && this.activityPackageExclusions.has(activityPackage.id)) {
            this.activityPackageExclusions
                .get(activityPackage.id)
                .map(selectedActivityPackageExclusion =>
                    this.findActivityPackageByActivityPackageId(selectedActivityPackageExclusion)
                )
                .forEach(activityPackageToRemove => this.removeActivity(activityPackageToRemove));
        }
    }

    private findActivityByActivityPackageId(activityPackageId: string): Activity {
        return (
            this.activities.find(act => (
                act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
                Utils.isNotNullOrUndefined(act.activityType) &&
                act.activityType.id === this.vehicle.model.id + '-' + activityPackageId) ||
                ( Utils.isNotNullOrUndefined(act.activityType) && act.activityType.id === activityPackageId)
            ) ||
            this.activities.find(act =>
                act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
                Utils.isNotNullOrUndefined(act.activityType) &&
                act.activityType.id === '000-00-' + activityPackageId
            ) ||
            this.activities.find(act => act.aprilActivityId === activityPackageId)
        );
    }

   private removeMarketingCampaignPackagesFromBasket(){
       if (this.activities.length !== 0) {
          // remove activity
          const activityToRemove = this.activities.filter(activity => {
             return Utils.isNotNullOrUndefined(activity.activityType) && activity.activityType.marketingCampaign == true;
          });
          if (activityToRemove && activityToRemove.length > 0) {
             activityToRemove.forEach(activity =>{
                const event: EventSci = {
                   value: Utils.isNotNullOrUndefined(activity.activityType) ? activity.activityType.id : null,
                   eventType: 'REMOVE',
                };

                this.activityEvent.emit(event);
                const indexToRemove = this.activities.indexOf(activity);
                this.activities.splice(indexToRemove, 1);
             });
          }
       }
    }

    private removeActivity(activityPackage: ActivityPackage): void {
        if (this.activities.length !== 0) {
            // remove activity
            const activityToRemove = this.findActivityByActivityPackageId(activityPackage.id);

            if (Utils.isNotNullOrUndefined(activityToRemove)) {
                const event: EventSci = {
                    value: Utils.isNotNullOrUndefined(activityToRemove.activityType) ? activityToRemove.activityType.id : null,
                    eventType: 'REMOVE',
                };

                this.activityEvent.emit(event);

                const indexToRemove = this.activities.indexOf(activityToRemove);
                this.activities.splice(indexToRemove, 1);
                this.updateCategoryDurationMap(activityPackage.categoryId, -activityPackage.duration);

                const isCbsService = activityPackage.categoryId === 'SERVICE' && activityPackage.fromApril;

                if (
                    countryConfiguration.automaticSelectionCbs99999 &&
                    isCbsService &&
                    this.activities.filter(a => a.catalogType === <CatalogType>'CBS').length === 1
                ) {
                    const linkedActivityToRemove: ActivityPackage = this.retrieveLinkedActivityByCategoryAndId(
                        activityPackage.categoryId,
                        '0000105'
                    );

                    if (linkedActivityToRemove !== null) {
                        this.removeActivity(linkedActivityToRemove);
                    }
                }
            }
        }
    }

    private retrieveLinkedActivityByCategoryAndId(
        activityCategoryId: string,
        activityPackageId: string
    ): ActivityPackage {
        return this.activityCategories
            .find(activityCategory => activityCategory.id === activityCategoryId)
            .activityPackageList.find(activityPackageFromList => activityPackageFromList.id === activityPackageId);
    }

    private findActivityPackageByActivityPackageId(activityPackageId: string): ActivityPackage {
        return this.activityCategories
            .map(activityCategory => activityCategory.activityPackageList)
            .reduce((returnValue, elementArray) => {
                returnValue = returnValue.concat(elementArray);
                return returnValue;
            }, [])
            .find(activityPackageFromList => activityPackageFromList.id === activityPackageId);
    }

    /**
     * ngFor traking by id
     * @param activityCategory
     */
    public trackByActivityCategory(activityCategory: ActivityCategory) {
        return activityCategory.id;
    }

    /**
     * ngFor traking by id
     * @param activityPackage
     */
    public trackByActivityPackage(activityPackage: ActivityPackage) {
        return activityPackage.id;
    }

    public isAlreadySelected(activityPackage: ActivityPackage): boolean {
        // Add description if needed
        const activityFound =
            this.activities.find(
                act =>
                    act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
                    Utils.isNotNullOrUndefined(act.activityType) &&
                    act.activityType.id === this.vehicle.model.id + '-' + activityPackage.id
            ) ||
            this.activities.find(
                act =>
                    act.catalogType !== 'TECHNICAL_CAMPAIGNS' && Utils.isNotNullOrUndefined(act.activityType) && act.activityType.id === '000-00-' + activityPackage.id
            ) ||
            this.activities.find(
                act => Utils.isNotNullOrUndefined(act.activityType) && act.activityType.id === activityPackage.id || act.aprilActivityId === activityPackage.id
            );

        if (activityFound && Utils.isNotNullOrUndefined(activityFound.activityType) && !activityFound.activityType.descriptionLocalized) {
            activityFound.activityType.descriptionLocalized = activityPackage.descriptionLocalized;
        }
        return activityFound != null;
    }

    public filterActivityPackages(activityPackage: ActivityPackage): boolean {
        const filter: string = this.searchActivity.value.toLowerCase();
        return activityPackage.descriptionLocalized.toLowerCase().indexOf(filter) >= 0;
    }

    public selectFastLane() {
        this.fastLaneSelected = !this.fastLaneSelected;
        this.fastLaneSelectedChange.emit(this.fastLaneSelected);
        if (!this.fastLaneSelected && this.waitInOutletSelected) {
            this.selectWaitInOutlet();
        }

        Utils.clearTimeSlot(this.appointment);
    }

    public selectWaitInOutlet() {
        this.waitInOutletSelected = !this.waitInOutletSelected;
        this.waitInOutletSelectedChange.emit(this.waitInOutletSelected);
    }

    public isFastLaneAllowed(): boolean {
        return (
            !this.appointment.technicalCampaignsAvailable &&
            (this.categoryDurationMap.size === 0 ||
                (this.categoryDurationMap.size === 1 &&
                    (this.categoryDurationMap.has('SERVICE') || this.categoryDurationMap.has('SERVICE_MOTO')))) &&
                    this.checkFastLaneAvailability()
        );
    }

    checkFastLaneAvailability() {
        if (this.vehicle.model.brand == 'BMW') {
            return this.countryConfiguration.BMW.fastLaneEnabled;
        } else if (this.vehicle.model.brand == 'MINI') {
            return this.countryConfiguration.MINI.fastLaneEnabled;
        } else if (this.vehicle.model.brand == 'BMW_MOTORRAD') {
            return this.countryConfiguration.BMW_MOTORRAD.fastLaneEnabled;
        } else if (this.vehicle.model.brand == 'BMW_I') {
            return this.countryConfiguration.BMW_I.fastLaneEnabled;
        } else {
            return true;
        }
    }

    /**
     * Retrieve and return the localized description from the activity category id.
     * @param actCatId activity category id
     */
    public getCategoryDescription(actCatId: string): string {
        const actCatFound = this.activityCategories.find(actCat => actCat.id === actCatId);

        return actCatFound ? actCatFound.descriptionLocalized : actCatId;
    }

    public getCategoriesDescriptions(actCatIds: string[]): string[] {
        return actCatIds.map(actCatId => this.getCategoryDescription(actCatId));
    }

    public fileChangeListener(event: any) {
        if ((event.target.files as FileList).length === 1) {
            const fileName = event.target.files[0].name.replace(/\s/g, '_');

            const attachment = {
                loading: true,
                name: fileName,
                fullName: null,
                path: null,
            };
            this.attachments.push(attachment);
            let promise: Promise<string>;
            if (this.appointment.id) {
                promise = this.attachmentService.uploadAttachment(this.appointment.id, event.target.files[0], fileName);
            } else {
                promise = this.attachmentService.uploadAttachmentTemp(event.target.files[0], fileName);
            }

            promise
                .then(att => {
                    const index = this.attachments.findIndex(
                        attach => attach.name === attachment.name && attach.loading
                    );
                    this.attachments[index] = Object.assign(
                        {},
                        {
                            ...attachment,
                            loading: false,
                            path: att,
                            fullName: this.getAttachmentFullName(att),
                        }
                    );

                    this.attachmentsChange.emit(this.attachments.map(attach => attach.path));
                })
                .catch(() => {
                    const index = this.attachments.findIndex(att => att.name === attachment.name);
                    this.attachments.splice(index, 1);
                });
        }
    }

    public deleteAttachment(attachment: Attachment) {
        attachment.loading = true;

        let promise: Promise<void>;
        if (this.appointment.id) {
            promise = this.attachmentService.deleteAttachment(this.appointment.id, attachment.path);
        } else {
            promise = this.attachmentService.deleteAttachmentTemp(attachment.fullName);
        }

        promise.then(() => {
            this.removeAttachment(attachment);
            this.attachmentsChange.emit(this.attachments.map(attachment => attachment.fullName));
        });
    }

    public removeAttachment(attachment: Attachment) {
        const index = this.attachments.findIndex(att => att.fullName === attachment.fullName);
        this.attachments.splice(index, 1);
    }

    public getAttachmentFullName(attachment: string) {
        const sp = attachment.split('/');
        return sp[sp.length - 1];
    }

    public getAttachmentName(attachment: string) {
        const name = this.getAttachmentFullName(attachment);
        return name.split('|')[1];
    }

    public showFastLaneWarning(checked: boolean) {
        if (checked) {
            this.warningFastLane.show();
        }
    }

    public valid(): boolean {
        return this.note.valid && this.appointment.activityList ? this.appointment.activityList.length > 0 : false;
    }

    public sanitizeServiceSubtitle(subtitle: string): SafeHtml {
        let result: SafeHtml;
        if (!Utils.isNullOrUndefined(subtitle)) {
            result = this.sanitizer.bypassSecurityTrustHtml(subtitle);
        }
        return result;
    }

    public showOrHideMarketingCampaign(){
       this.marketingCampaignAvailable = moment(new Date()).isBetween(this.outletMarketingCampaign[0].startDate, this.outletMarketingCampaign[0].endDate);
    }

    private checkCorrectTab(category: ActivityCategoryOnlineDTO, tab){
       if(tab == "Maintenance" && (category.id =="SERVICE_MOTO" || category.id =="SERVICE")) return true;
       if(tab == "Diagnosys" && category.id == "DIAGNOSIS") return true;
       if(tab == "BodyAndPaint" && category.id == "BODY_AND_PAINT") return true;
       if(tab == "Tires" && (category.id == "WHEEL_MOTO" || category.id == "WHEEL")) return true;
       if(tab == "Other" && (category.id == "OTHER_MOTO" || category.id == "OTHER")) return true;
       else return false;
    }


    private isNotSelecteEsclusivePackage(activityCategory: ActivityCategory): boolean{
        return activityCategory.activityPackageList.filter(currActivityPackage => currActivityPackage.exclusive && currActivityPackage.isSelected).length==0;
   }

   public isSelectedAnotherPageEsclusive(activityCategory: ActivityCategory,activityPackage: ActivityPackage){
       return activityCategory.activityPackageList.filter(currActivityPackage =>
                                                          currActivityPackage != activityPackage &&
                                                          currActivityPackage.exclusive &&
                                                          currActivityPackage.isSelected).length>0;
   }

   public changePackageSelected(activityCategory: ActivityCategory, activityPackage: ActivityPackage, isSelected:boolean) {
       console.log('changePackageSelected');
       if (activityPackage.exclusive)
       {
           if (isSelected){
               activityCategory.activityPackageList
                   .filter(currActivityPackage => currActivityPackage !=activityPackage)
                   .forEach(currActivityPackage => {
                        currActivityPackage.isSelected = false;
                        this.removeActivity(currActivityPackage);
                    });
               this.chkPackages
                   .filter(chk => chk.nativeElement.id!='chk'+activityPackage.id)
                   .forEach(chk => {chk.nativeElement.disabled=true});
           }
           else {
               // if no esclusive package selected
               if (this.isNotSelecteEsclusivePackage(activityCategory))
               this.chkPackages
                   .filter(chk => chk.nativeElement.id!='chk'+activityPackage.id)
                   .forEach(chk => {chk.nativeElement.disabled=false});
           }

       }
   }

   getCurrentBrand() {
      if (this.hostname.includes('.bmw-motorrad.')) {
         return 'moto';
      } else {
         return 'bmwmini';
      }
   }

   public getServiceUls(activityPackage :ActivityPackage) :number {
    const uls = (+activityPackage.duration) / 5;
    return Utils.isNotNullOrUndefined(uls) ? uls : 0;
   }
}
