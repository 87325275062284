import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { countryConfiguration } from '../../../configuration/country.configuration';

export class OasValidators {
    private static readonly vinRegexp = new RegExp('^[0-9a-zA-Z]{17}$');
    private static readonly plateRegexp = new RegExp('^[0-9a-zA-Z *-//%]+$');
    private static readonly spainPlateRegexp = new RegExp('^[A-Za-z0-9 *-//%]{0,10}$');
    private static readonly newPlateRegexp = new RegExp('^[A-Za-z0-9-]{5,10}$');
    private static readonly emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // GCDM allows phone number with a max length of 30, but it has also to be normalizable
    private static readonly gcdmPhoneRegexp = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;

    private static readonly fiscalCodeVatRegexpIt = /^([0-9a-zA-Z]{16}|[0-9]{11})$/;

    private static readonly latin1Regexp = /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178\n]*$/;

    constructor() {}

    public static email(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;

        if (control.value) {
            if (OasValidators.emailRegexp.test(control.value)) {
                result = null;
            } else {
                result = { email: true };
            }
        }

        return result;
    }

    public static testEmail(value: string): boolean {
        return OasValidators.emailRegexp.test(value);
    }

    public static getMobilePhoneValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let result: ValidationErrors = null;

            if (control.value) {
                if (this.testMobilePhone(control.value)) {
                    result = null;
                } else {
                    result = { mobilePhone: true };
                }
            }

            return result;
        };
    }

    public static genericPhone(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let result: ValidationErrors = null;

            if (control.value) {
                let regExp;
                regExp = countryConfiguration.phoneNumberRegex;

                const noBlankSpacesValue = control.value.replace(/\s/g, '');
                const valid = control.value.length <= 30 && OasValidators.gcdmPhoneRegexp.test(control.value);

                if (valid && (regExp.test(control.value) || regExp.test(noBlankSpacesValue))) {
                    result = null;
                } else {
                    result = { genericPhone: true };
                }
            }

            return result;
        };
    }

    public static testMobilePhone(value: string): boolean {
        let validPhone = false;
        if (value) {
            const regExp = countryConfiguration.mobileNumberRegex;
            const noBlankSpacesValue = value.replace(/\s/g, '');
            const validValue = value.length <= 30 && OasValidators.gcdmPhoneRegexp.test(value);

            let i = 0;
            while (!validPhone && i < regExp.length) {
                validPhone = validValue && (regExp[i].test(noBlankSpacesValue) || regExp[i].test(value));
                i++;
            }
        }
        return validPhone;
    }

    public static testVin(value: string): boolean {
        return OasValidators.vinRegexp.test(value);
    }

    public static vin(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;

        if (control.value) {
            if (OasValidators.testVin(control.value)) {
                result = null;
            } else {
                result = { vin: true };
            }
        }

        return result;
    }

    public static plate(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;
        let countryRegex: RegExp= countryConfiguration.countryCode == "ES" ? OasValidators.spainPlateRegexp : OasValidators.plateRegexp;

        if (control.value) {
            if (countryRegex.test(control.value)) {
                result = null;
            } else {
                result = { plate: true };
            }
        }

        return result;
    }

    public static newPlate(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;
        let countryRegex: RegExp=  OasValidators.newPlateRegexp ;

        if (control.value) {
            if (countryRegex.test(control.value)) {
                result = null;
            } else {
                result = { plate: true };
            }
        }

        return result;
    }

    public static fiscalCode(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;

        if (control.value) {
            if (OasValidators.fiscalCodeVatRegexpIt.test(control.value)) {
                result = null;
            } else {
                result = { fiscalCode: true };
            }
        }

        return result;
    }

    public static vatNumber(control: AbstractControl): ValidationErrors {
        let result: ValidationErrors = null;

        if (control.value) {
            if (OasValidators.isVatNumberValid(control.value)) {
                result = null;
            } else {
                result = { vatNumber: true };
            }
        }

        return result;
    }

    public static isVatNumberValid(value: string): boolean {
        const vatRegex = countryConfiguration.vatRegex;
        const fiscalCodeRegex = countryConfiguration.fiscalCodeRegex;

        return (fiscalCodeRegex !== null && fiscalCodeRegex.test(value)) || vatRegex.test(value);
    }

    public static customerCompliantPassword(
        control: AbstractControl,
        username: string = undefined
    ): ValidationErrors | null {
        let result: ValidationErrors = null;
        let counter = 0;

        // Has invalid char
        const hasInvalidValidChar = /[^a-zA-Z\d\-\.\/\'\,\;\&\@\#\*\)\(\_\+\:\"´`~]/.test(control.value);
        const isContainedInUsername = username && username.lastIndexOf(control.value) !== -1; // if username is setted, password can't be contained on it
        if (!hasInvalidValidChar && !isContainedInUsername) {
            // Has letter
            if (/[a-zA-Z]/.test(control.value)) {
                counter++;
            }
            // Has number
            if (/\d/.test(control.value)) {
                counter++;
            }
            // Has special chars
            if (/[\-\.\/\'\,\;\&\@\#\*\)\(\_\+\:\"´`~]/.test(control.value)) {
                counter++;
            }

            if (counter >= 2) {
                return null;
            } else {
                result = { compliantPassword: true };
            }
        } else {
            result = { compliantPassword: true };
        }

        return result;
    }

    public static userCompliantPassword(
        control: AbstractControl,
    ): ValidationErrors | null {
        let result: ValidationErrors = null;
        // Has invalid char
        const hasInvalidValidChar = /[^a-zA-Z\d\&\*\:\#\_\-]/.test(control.value);
        if (!hasInvalidValidChar) {
            // Has at least 1 number, 1 Uppercase and 1 lowercase letter. 
            if (/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(control.value)) {
                return null;
            } else {
                result = {
                    compliantPassword: true,
                };
            }
        } else {
            result = {
                compliantPassword: true,
            };
        }
        return result;
    }

    public static getLatinValidator(): ValidatorFn {
        return Validators.pattern(OasValidators.latin1Regexp);
    }

    public static getPlateRegex(): RegExp {
        return OasValidators.plateRegexp;
    }

    public static notNullValue(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value ? null : { valueNull: true };
        };
    }

    public static requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
        return function validate(formGroup: UntypedFormGroup) {
            let checked = 0;

            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];

                if ((control.dirty && control.value) || (!control.dirty && control.valid)) {
                    checked++;
                }
            });

            if (checked < minRequired) {
                return {
                    requireCheckboxesToBeChecked: true,
                };
            }

            return null;
        };
    }
}
