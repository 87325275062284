import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';
import {
   ActivityCategory,
   ActivityPackage,
   FixedPrice,
   ActivityDay,
   BaseResponse,
   ActivityCategoryOnlineDTO,
   MarketingCampaign,
   MarketingCampaignServices,
   OnlineTechnicalCampaign,
   VehicleBrand,
} from './../../../shared/entities';
import { Logger } from './../../logger.service';

@Injectable()
export class ActivityService extends AbstractRestService<ActivityCategory, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/activity', http, logger);
    }

    public getAvailableActivities(vehicleModelId: string): Promise<ActivityCategoryOnlineDTO[]> {
        const url = `${this.actionUrl}/activity-category/by-vehicle-model/${vehicleModelId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as ActivityCategoryOnlineDTO[])
            .catch(error => this.handleError(error));
    }

   public getAvailableTechnicalCampaigns(vin: string, excludeBookedTechCampaign: boolean, vehicleType: VehicleBrand): Promise<OnlineTechnicalCampaign> {
      const url = `${this.actionUrl}/activity-category/online-technical-campaign`;
      const body = {
        vin: vin,
        excludeBookedTechCampaign: excludeBookedTechCampaign,
        vehicleType: vehicleType
      };
      return this.http
         .post(url, body)
         .toPromise()
         .then(response => response as OnlineTechnicalCampaign[])
         .catch(error => this.handleError(error));
   }

    public getAvailableActivitiesWithCbsAndCampaigns(
        vehicleModelId: string,
        vin: string,
        outletId: string,
        preselectedCBS: string[]
    ): Promise<ActivityCategoryOnlineDTO[]> {
        const url = `${this.actionUrl}/activity-category/by-vehicle/${vehicleModelId}/${outletId}`;
        const requestBody = {
            vin
          };
        return this.http
            .post(url, requestBody, {
                params:
                    preselectedCBS.length > 0 ? new HttpParams().set('selectedCbsIds', preselectedCBS.join(',')) : null,
            })
            .toPromise()
            .then(response => response as ActivityCategoryOnlineDTO[])
            .catch(error => this.handleError(error));
    }

    public getFixedPriceByOutlet(outletId: string, vehicleModelId: string): Promise<FixedPrice[]> {
        const url = `${this.actionUrl}/fixed-price/outlet/${outletId}/vehicle-model/${vehicleModelId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as FixedPrice[])
            .catch(error => this.handleError(error));
    }
    public getFixedPriceCount(outletId: string, vehicleModelId: string): Promise<number> {
        const url = `${this.actionUrl}/count/fixed-price/outlet/${outletId}/vehicle-model/${vehicleModelId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as FixedPrice[])
            .catch(error => this.handleError(error));
    }

   public getGroupActivitiesByVehicle(vehicleModelId: string, groupId: number, vehicleVin?: string): Promise<ActivityPackage[]> {
      let url = `${this.actionUrl}/activity-type/${groupId}/by-vehicle-model/${vehicleModelId}`;     
      let requestBody = {
        vin: vehicleVin
      }
      return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as ActivityCategory[])
            .catch(error => this.handleError(error));
    }

    public getAllSkills(): Promise<ActivityCategory[]> {
        const url = `${this.actionUrl}/activity-category`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as ActivityCategory[])
            .catch(error => this.handleError(error));
    }

    public getActivityPackagesByTicket(ticketId: number): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/activity-package/by-ticket-cbs/${ticketId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as BaseResponse<ActivityCategory[]>)
            .catch(error => this.handleError(error));
    }

    public getActivityPackagesByLeadTicket(ticketId: number): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/activity-package/by-lead-ticket/${ticketId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as BaseResponse<ActivityCategory[]>)
            .catch(error => this.handleError(error));
    }

    public getActivitySrpCampaignPackages(vin: string,outletId: string): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/packages/by-vin-srp/${outletId}`;
        const requestBody = {
            vin
        };

        return this.http.post(url, requestBody) .toPromise()
        .then(response => response as BaseResponse<ActivityCategory[]>)
        .catch(error => this.handleError(error));;
    }

    public getPackagesByVehicle(vin: string, outletId: string): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/packages/by-vin-cbs/${outletId}`;
        const requestBody = {
            vin
        };

        return this.http
            .post(url,requestBody)
            .toPromise()
            .then(response => response as BaseResponse<ActivityCategory[]>)
            .catch(error => this.handleError(error));
    }
    public getActivityPackagesByLeadVin(vin: string, outletId: string): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/activity-package/by-lead-vin/${outletId}`;
        const requestBody = {
            vin
        };

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as BaseResponse<ActivityCategory[]>)
            .catch(error => this.handleError(error));
    }

    public getActivityDay(activityId: number): Promise<ActivityDay[]> {
        const url = `${this.actionUrl}/activity-day/${activityId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as ActivityDay[])
            .catch(error => this.handleError(error));
    }

    public getActivityDays(appointmentId: string): Promise<ActivityDay[]> {
        const url = `${this.actionUrl}/activity-days/${appointmentId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as ActivityDay[])
            .catch(error => this.handleError(error));
    }

    public getActivityPackageExclusions(): Promise<Map<string, string[]>> {
        const url = `${this.actionUrl}/activity-package-exclusions`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => {
                const activityPackageExclusions = new Map<string, string[]>();
                Object.keys(response).forEach(activityPackageId => {
                    activityPackageExclusions.set(activityPackageId, response[activityPackageId]);
                });
                return activityPackageExclusions;
            })
            .catch(error => this.handleError(error));
    }

   public postCampaignWithServices(marketingCampaign: MarketingCampaign): Promise<void> {
      const url = `${this.actionUrl}/marketing-campaign`;
      return this.http
         .post(url, marketingCampaign)
         .toPromise()
         .catch(error => this.handleError(error));
   }

   public getCampaignWithServicesByCountryCode(): Promise<ActivityCategoryOnlineDTO[]> {
      const url = `${this.actionUrl}/marketing-campaign`;
      return this.http
         .get(url)
         .toPromise()
         .then(response => response as MarketingCampaign[])
         .catch(error => this.handleError(error));
   }

   public deleteCampaignService(id): Promise<void>{
      const url = `${this.actionUrl}/marketing-campaign/service/${id}`;
      return this.http
         .delete(url)
         .toPromise()
         .then(() => null)
         .catch(error => this.handleError(error));
   }

   public updateCampaignService(id, marketingService: MarketingCampaignServices): Promise<void>{
      const url = `${this.actionUrl}/marketing-campaign/service/${id}`;
      return this.http
         .put(url, marketingService)
         .toPromise()
         .then(() => null)
         .catch(error => this.handleError(error));
   }

   public findByOutletIdAndBrand(id, brand): Promise<ActivityCategoryOnlineDTO[]>{
      const url = `${this.actionUrl}/marketing-campaign/by-outlet/${id}/${brand}`;
      return this.http
         .get(url)
         .toPromise()
         .then(response => response as ActivityCategoryOnlineDTO)
         .catch(error => this.handleError(error));
   }

   public deleteMarketingCampaign(id): Promise<void>{
      const url = `${this.actionUrl}/marketing-campaign/${id}`;
      return this.http
         .delete(url)
         .toPromise()
         .then(() => null)
         .catch(error => this.handleError(error));
   }

   public getLocalCampaignWithServicesByCountryCodeAndOutlets(outletIds: string[]): Promise<ActivityCategoryOnlineDTO[]> {
      const url = `${this.actionUrl}/marketing-campaign/by-outlets`;
      return this.http
         .post(url, outletIds)
         .toPromise()
         .then(response => response as ActivityCategoryOnlineDTO[])
         .catch(error => this.handleError(error));
   }

   public getActiveNationalCampaignByOutlet(outletId: string): Promise<ActivityCategoryOnlineDTO> {
      const url = `${this.actionUrl}/marketing-campaign/by-outlet/${outletId}`;
      console.log(url);
      return this.http
         .get(url)
         .toPromise()
         .then(response => response as ActivityCategoryOnlineDTO)
         .catch(error => this.handleError(error));
   }

}
