import { CountryConfiguration } from '../app/country-configuration';
import { RoleType } from '../app/shared/entities';
export let portugalCountryConfiguration: CountryConfiguration = {
    language: 'pt',
    countryCode: 'PT',
    locale: 'pt-PT',
    welcomeTerminalAppointmentIdenabled: false,
    appointmentCountForSadvInDashboard: true,
    showCarShadowImageInClientSearch:false,
    nscCicSearchOnDMS: true,
    phoneNumberRegex: /^(([+]|00)351)?[0-9]{1,10}$/,
    mobileNumberRegex: [
        /^(([+]|00)351)?[2-9][0-9]{8}$/,
        /^(([+]|00)49)[2-9][0-9]{9,10}$/,
        /^(([+]|00)33)[1-79][0-9]{8}$/,
        /^(([+]|00)34)([6-7][0-9]{8}|[8-9][1-9][0-9]{7})$/,
        /^(([+]|00)39)[0-9]{9,11}$/,
        /^(([+]|00)41)[0-9]{9}$/,
    ],
    vatRegex: /^[a-zA-Z0-9]{1,40}$/,
    fiscalCodeRegex: null,
    dsaFilePath: 'assets/pdf/PT/DSA_Notice.pdf',
    showDSALink: true,
    BMW: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.pt/pt/footer/metanavigation/data-privacy.html',
            contactLink: 'https://www.bmw.pt/pt/footer/metanavigation/legal-disclaimer-pool/ral.html',
            legalNoteLink: 'https://www.bmw.pt/pt/footer/metanavigation/legal-disclaimer-pool/legal-disclaimer.html',
            textHomeLink: 'BMW.PT',
            homeLink: 'https://www.bmw.pt/pt/index.html',
            vatNumber: 'Número de identificação fiscal - 506698521',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais BMW, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: 'https://www.bmw.pt/pt/topics/offers-and-services/service-workshop/bmw-teleservices.html',
        supportChatAvailable: true,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'marcacoes-online_bmw_pt',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: true,
        fastLaneEnabled: true,
    },
    MINI: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.pt/pt/footer/metanavigation/data-privacy.html',
            contactLink:
                'https://www.mini.pt/pt_PT/home/finance-and-insurance/PrecarioInformacaoLegal/resolucao-alternativa-de-litigios.html',
            legalNoteLink: 'https://www.mini.pt/pt_PT/home/footer/legal.html',
            textHomeLink: 'MINI.PT',
            homeLink: 'https://www.mini.pt/pt_PT/home.html',
            vatNumber: 'Número de identificação fiscal - 506698521',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais MINI, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: 'https://www.mini.pt/pt_PT/home/services/service-and-repair/teleservices.html',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'marcacoes-online_mini_pt',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: true,
        fastLaneEnabled: true,
    },
    BMW_MOTORRAD: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.pt/pt/footer/metanavigation/data-privacy.html',
            contactLink:
                'https://www.bmw-motorrad.pt/pt_PT/home/finance-and-insurance/PrecarioInformacaoLegal/resolucao-alternativa-de-litigios.html',
            legalNoteLink: 'https://www.bmw-motorrad.pt/pt_PT/home/footer/legal.html',
            textHomeLink: 'BMW-MOTORRAD.PT',
            homeLink: 'https://www.bmw-motorrad.pt/pt_PT/home.html',
            vatNumber: 'Número de identificação fiscal - 506698521',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais BMW Motorrad, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: '',
        supportChatAvailable: false,
        quickAppointment: true,
        longLifeCareMobilityBanner: false,
        ePaas: {
            enabled: true,
            tenantId: 'marcacoes-online_bmw-motorrad_pt',
        },
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: false,
        fastLaneEnabled: false,
    },
    BMW_I: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.pt/pt/footer/metanavigation/data-privacy.html',
            contactLink: 'https://www.bmw.pt/pt/footer/metanavigation/legal-disclaimer-pool/ral.html',
            legalNoteLink: 'https://www.bmw.pt/pt/footer/metanavigation/legal-disclaimer-pool/legal-disclaimer.html',
            textHomeLink: 'BMW.PT',
            homeLink: 'https://www.bmw.pt/pt/index.html',
            vatNumber: 'Número de identificação fiscal - 506698521',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais BMW, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: 'https://www.bmw.pt/pt/topics/offers-and-services/service-workshop/bmw-teleservices.html',
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'marcacoes-online_bmw_pt',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: "",
        enableTugaTicket: true,
        fastLaneEnabled: true,
    },
    tssb: {
        enable: true,
        enableRecentPendingAppointmentCheckboxByRoles: new Map<RoleType, boolean>([
            [<RoleType>'ROLE_CIC_NSC_ADMIN', true],
            [<RoleType>'ROLE_CIC_NSC_USER', true],
            [<RoleType>'ROLE_CIC_EP_MANAGER', true],
            [<RoleType>'ROLE_CIC_EP_USER', true],
            [<RoleType>'ROLE_S_ADV', true],
            [<RoleType>'ROLE_S_ASSISTANT', true],
            [<RoleType>'ROLE_ASM', true],
            [<RoleType>'ROLE_RENTING_ADM', true],
            [<RoleType>'ROLE_RENTING_USER', true],
        ]),
        enableEmail: true,
    },
    emailQuickAppointment: 'no-reply-oas-pt@bmw.pt',
    showAllCapacityTeams: false,
    waitInOutlet: true,
    mobilityStepAvailability: true,
    enableToros: false,
    editCustomer: false,
    vatNumberValidator: '',
    dcomHealth: true,
    showFastLaneLink: true,
    // Quick appointment REFACTOR
    latitude: 38.707751,
    longitude: -9.136592,
    createVehicleFiscalCode: false,
    dcomValidation: true,
    mapsRadius: 10000,
    mapsZoom: 13,
    updateDMS: false,
    maxDelay: 72,
    daysOfWeek: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    enableRenting: true,
    enableNscReport: true,
    nscReportFilename: 'Consultas detalhadas do NSC.csv',
    enableSMS: true,
    sendSMS: false,
    automaticSelectionCbs99999: false,
    enableBsi: true,
    hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration: false,
    enableKiosk: false,
    enablePPVSync: true,
    enableTjekvik: true,
    enableSharebox: false,
    enableSPOA: true,
    enableVinLookup: true,
    preRegistration: {
        enablePreRegistrationFromDms: true,
        enablePreRegistrationFromFastAppointment: true,
        hideCheckBoxWithoutRegistration: true,
        forceSendEmailWithoutRegistration: true,
    },
    showVinOnlineAppointmentResume: false,
    showFiscalNumberVehicleForm: false,
    servicesBannerEnableWithoutCampaign: true,
    vehicleFormSmartRegistrationType: 'PLATE',
    showAppointmentWithoutRegistrationVehicleForm: false,
    fastAppointmentMigrationToConsumeCapacity: false,
    dateInputRangeFormat: 'DD/MM/YYYY',
    enableLeadApi: true,
    currencyCode: 'EUR',
    enableEmail: true,
    sendEmail: false,
    leadApiOutlets: ['OUTL_27940', 'OUTL_02154','OUTL_02575','OUTL_02569','OUTL_02594','OUTL_02602','OUTL_02157','OUTL_47018','OUTL_36729','OUTL_02181','OUTL_34887','OUTL_02552','OUTL_02251','OUTL_28863','OUTL_02542','OUTL_02557','OUTL_28843','OUTL_47151','OUTL_50194','OUTL_02544','OUTL_02572','OUTL_28458','OUTL_02604','OUTL_34906','OUTL_34907','OUTL_45788','OUTL_02546','OUTL_52717','OUTL_52718','OUTL_52719','OUTL_47590','OUTL_50117','OUTL_32924','OUTL_02566','OUTL_32925','OUTL_50434','OUTL_47776','OUTL_20522','OUTL_02568','OUTL_52894','OUTL_52896','OUTL_52897','OUTL_52899','OUTL_52902','OUTL_52903','OUTL_52904','OUTL_52906','OUTL_52901','OUTL_52905','OUTL_52907','OUTL_52893','OUTL_52875','OUTL_52257','OUTL_42447','OUTL_52255','OUTL_02554','OUTL_02182'],
    leadApiOutletFilterEnable: true,
    leadTssbAsmEnable: true,
    appointmentFastCustomerCreationGenderEnabled:false,
    eitherPhoneOrEmailEnabled:true,
    showOnlineWorkTeam: false,
    workingDaysEnabled: true,
    dropDownLeadStatuses: [
        'assignedToTsp',
        'contacting',
        'postponed',
        'escalated'
    ],
    availabilityFilter: true,
    awp: {
        url: 'https://awp.bmwgroup.com/servicedemand?',
        vinParameter: 'Vin=',
        icon: {
            enabled: true,
            url: 'awp:/start?app=vehicledetails&vin=${vin}',
        }
    },
    enableLeadBattery: true,
};
