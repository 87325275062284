import { Component, EventEmitter, OnInit, Output, Inject, LOCALE_ID, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AttachmentService } from '../../core/api/attachment/attachment.service';
import { Attachment } from '../../shared/attachment/entity';
import { Appointment } from './../../shared/entities';
import { OasValidators } from '../../core/validator/oas-validators';
import { Utils } from '../../core/utils';
import { CountryConfiguration } from '../../country-configuration';
import { ResumeAppointmentComponent } from '../../shared/resume-appointment';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { NouisliderComponent } from 'ng2-nouislider';
import { ActivatedRoute } from '@angular/router';
import { TechnicalSettingService } from '../../core/api/technical-setting.service';

@Component({
    selector: 'oas-appointment-resume',
    templateUrl: './appointment-resume.component.html',
    styleUrls: ['./appointment-resume.component.scss'],
})
export class AppointmentResumeComponent extends ResumeAppointmentComponent implements OnInit {
    static MORNING = 'Morning';
    static AFTERNOON = 'Afternoon';
    static TIME_SWITCH = 26;
    static TIME_END = 48;
    static TIME_START = 12;

    @Input() public showRegistrationCheckbox: boolean;
    @Output() public sendPreRegistrationEmail = new EventEmitter<boolean>();
    @ViewChild('sliderRef') public sliderRef: NouisliderComponent;
    colorTheme = 'theme-blue';
    public noEdit = false;
    private returnDate: Date;
    public mobilityPickTooltip: string;
    public mobilityReturnTooltip: string;
    public slideTimeInfo: string;
    public mileage = new UntypedFormControl(null, [Validators.required, Validators.pattern('^[0-9]+')]);
    public note = new UntypedFormControl({ value: null, disabled: this.noEdit }, [OasValidators.getLatinValidator()]);
    public plate = new UntypedFormControl(null, [Validators.required, OasValidators.plate]);
    public placeHolderMileage = 'Kilometers';
    public newMileage: number;
    public oldMileage: number;
    public attachments: Attachment[];
    public attachmentsUrl: string = environment.attachmentsUrl;
    public language: string;
    public readOnlyPlate = false;
    private countryConfiguration: CountryConfiguration;
    public sliderConfig: any;
    public startTimeRange: number[];
    public selectedTimeframe: string;
    public outletMorningConfig: number[] = [];
    public outletAfternoonConfig: number[] = [];
    public enableRemoteKeyRead: boolean;
    public isVehicleWithRemoteKeyRead: boolean;
    public remoteKeyReadLink: String;
    public isRemoteKeyReadAlreadyAccepted: boolean;
    public showReturnDate:boolean;
    
    public filterPredicate = act =>
        act.aprilActivityId != null ||
        (act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
        (act.activityType.showOnline ||
            act.activityType.id == '000-00-FLATRATE' ||
            act.activityType.id == '000-00-FIXEDPRICES' ||
            act.activityType.id == '000-00-PACKAGE'));

    constructor(
        private attachmentService: AttachmentService,
        private styleSwitcherService: StyleSwitcherService,
        @Inject(LOCALE_ID) private locale: string,
        private countryConfigurationService: CountryConfigurationService,
        private route: ActivatedRoute,
        private technicalSettingService: TechnicalSettingService
    ) {
        super();
        this.language = this.locale.substring(0, 2);
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
        this.route.queryParams.subscribe(params => {
            if (!Utils.isNullOrUndefined(params) && !Utils.isNullOrUndefined(params['RKR'])) {
                this.isVehicleWithRemoteKeyRead = params['RKR'].toLowerCase() === 'true';
            }
        });
    }

    ngOnInit() {
        
        this.technicalSettingService.findByName("DELIVERY_DATE_ENABLED").then(Response=>{
            const countries = Response.value.split(",");
            this.showReturnDate = countries.includes(this.countryConfiguration.countryCode);
        });
        this.sliderConfig = {
            range: {
                min: AppointmentResumeComponent.TIME_START,
                max: AppointmentResumeComponent.TIME_END,
            },
            step: 1,
            margin: 2,
            // Display colored bars between handles
            connect: true,
            keyboard: true,
        };
    }

    public accept(isAccepted: boolean): void {
        this.appointment.remoteKeyRead = isAccepted;
    }

    public init(appointment: Appointment): void {
        this.appointment = appointment;
        this.getOutletMorningConfig();
        this.getOutletAfternoonConfig();
        if (!this.appointment.id) {
            this.createDesiredPickUpHour();
        } /* else {
            this.editDesiredPickUpHour();
        } */

        if (
            Utils.isNullOrUndefined(this.appointment.customer.address) ||
            Utils.isNullOrUndefined(this.appointment.customer.address.latitude) ||
            Utils.isNullOrUndefined(this.appointment.customer.address.longitude)
        ) {
            this.appointment.customer.address = {
                latitude: this.countryConfiguration.latitude,
                longitude: this.countryConfiguration.longitude,
            };
        }

        if (this.appointment.id) {
            this.attachmentService.getAppointmentAttachments(this.appointment.id).then(
                attachments =>
                    (this.attachments = attachments.map((attachment, index, array) => {
                        return {
                            loading: false,
                            path: attachment,
                            name: this.getAttachmentName(attachment),
                            fullName: this.getAttachmentFullName(attachment),
                        } as Attachment;
                    }))
            );
        } else {
            if (this.appointment.attachments) {
                this.attachments = this.appointment.attachments.map(attachment => {
                    return {
                        loading: false,
                        path: attachment,
                        name: this.getAttachmentName(attachment),
                        fullName: this.getAttachmentFullName(attachment),
                    } as Attachment;
                });
            }
        }

        if (
            (this.appointment.status === 'DELIVERED' ||
                this.appointment.status === 'IN_PROCESS' ||
                this.appointment.status === 'CANCELED' ||
                this.appointment.status === 'COMPLETED') &&
            this.appointment.outlet.outletMobilityTypeList.length === 0
        ) {
            this.noEdit = true;
        }

        if (this.appointment.fastLane && this.appointment.fastLaneTimeSlot) {
            // if FastLane 2.0
            this.appointment.returnTime = new Date(this.appointment.startTime.valueOf()); // take date from endTime
            const fastLaneEndTime = this.getDurationFastLane(this.appointment.fastLaneTimeSlot);
            this.appointment.returnTime.setTime(this.appointment.returnTime.getTime() + fastLaneEndTime * 60000);
            this.returnDate = this.appointment.returnTime;
        } else if (!this.appointment.id || !this.appointment.returnTime) {
            this.returnDate = new Date(this.appointment.endTime.valueOf());
            this.appointment.activityList.forEach(activity => {
                this.returnDate.setTime(
                    this.returnDate.getTime() +
                        (activity.duration && activity.duration > 0
                            ? activity.duration
                            : activity.activityType.activityDuration) *
                            60000
                );
            });
            this.appointment.returnTime = this.returnDate;
        }

        if (
            this.appointment.mobilityList &&
            this.appointment.mobilityList.length > 0 &&
            this.appointment.mobilityList[0].address
        ) {
            const pickupAddress = this.appointment.mobilityList[0].address;
            const returnAddress = this.appointment.mobilityList[0].deliveryAddress;
            this.mobilityPickTooltip = pickupAddress.address;
            if (pickupAddress.stairInt) {
                this.mobilityPickTooltip = this.mobilityPickTooltip + ',' + pickupAddress.stairInt;
            }
            this.mobilityReturnTooltip = returnAddress.address;
            if (returnAddress.stairInt) {
                this.mobilityReturnTooltip = this.mobilityReturnTooltip + ',' + returnAddress.stairInt;
            }
        }

        this.oldMileage = appointment.vehicle.mileage;

        if (this.oldMileage) {
            this.mileage.setValue(this.oldMileage);
        }

        this.readOnlyPlate =
            !Utils.isNullOrUndefined(this.appointment.vehicle) &&
            !Utils.isNullOrUndefined(this.appointment.vehicle.plate);

        this.isRemoteKeyReadAlreadyAccepted = appointment.remoteKeyRead;

        if (this.appointment && this.appointment.vehicle) {
            this.enableRemoteKeyRead = this.countryConfiguration[this.appointment.vehicle.model.brand].remoteKeyRead;
            this.remoteKeyReadLink = this.countryConfiguration[this.appointment.vehicle.model.brand].remoteKeyReadLink;
        }
    }

    public onKeyUpPlate(event) {
        this.plate.setValue(event.target.value);
    }

    public onKeyUpMileage(event) {
        this.newMileage = event.target.value;
        this.mileage.setValue(this.newMileage);
    }

    public get valid(): boolean {
        return this.mileage.valid && this.note.valid && (this.readOnlyPlate || this.plate.valid);
    }

    public createDesiredPickUpHour() {
        if (this.appointment.outlet.morningDeliveryEnabled) {
            this.sliderConfig.range = { min: this.outletMorningConfig[0], max: this.outletMorningConfig[1] };
            this.startTimeRange = this.outletMorningConfig;
            this.selectedTimeframe = AppointmentResumeComponent.MORNING;
        } else {
            this.sliderConfig.range = { min: this.outletAfternoonConfig[0], max: this.outletAfternoonConfig[1] };
            this.startTimeRange = this.outletAfternoonConfig;
            this.selectedTimeframe = AppointmentResumeComponent.AFTERNOON;
        }
    }

    public editDesiredPickUpHour() {
        if (this.timeToInt(this.appointment.preferedReturnTimeStart) < AppointmentResumeComponent.TIME_SWITCH) {
            if (this.appointment.outlet.morningDeliveryEnabled) {
                this.sliderConfig.range = { min: this.outletMorningConfig[0], max: this.outletMorningConfig[1] };
                this.startTimeRange = [
                    this.appointment.preferedReturnTimeStart
                        ? this.timeToInt(this.appointment.preferedReturnTimeStart)
                        : AppointmentResumeComponent.TIME_START,
                    this.appointment.preferedReturnTimeEnd
                        ? this.timeToInt(this.appointment.preferedReturnTimeEnd)
                        : AppointmentResumeComponent.TIME_SWITCH,
                ];
                this.selectedTimeframe = AppointmentResumeComponent.MORNING;
            } else {
                this.sliderConfig.range = { min: this.outletAfternoonConfig[0], max: this.outletAfternoonConfig[1] };
                this.startTimeRange = this.outletAfternoonConfig;
                this.selectedTimeframe = AppointmentResumeComponent.AFTERNOON;
            }
        } else {
            if (this.appointment.outlet.afternoonDeliveryEnabled) {
                this.sliderConfig.range = { min: this.outletAfternoonConfig[0], max: this.outletAfternoonConfig[1] };
                this.startTimeRange = [
                    this.appointment.preferedReturnTimeStart
                        ? this.timeToInt(this.appointment.preferedReturnTimeStart)
                        : AppointmentResumeComponent.TIME_SWITCH,
                    this.appointment.preferedReturnTimeEnd
                        ? this.timeToInt(this.appointment.preferedReturnTimeEnd)
                        : AppointmentResumeComponent.TIME_END,
                ];
                this.selectedTimeframe = AppointmentResumeComponent.AFTERNOON;
            } else {
                this.sliderConfig.range = { min: this.outletMorningConfig[0], max: this.outletMorningConfig[1] };
                this.startTimeRange = this.outletMorningConfig;
                this.selectedTimeframe = AppointmentResumeComponent.MORNING;
            }
        }
    }

    public getOutletMorningConfig() {
        this.outletMorningConfig = [
            this.appointment.outlet.morningDeliveryTimeStart
                ? this.timeToInt(this.appointment.outlet.morningDeliveryTimeStart)
                : AppointmentResumeComponent.TIME_START,
            this.appointment.outlet.morningDeliveryTimeEnd
                ? this.timeToInt(this.appointment.outlet.morningDeliveryTimeEnd)
                : AppointmentResumeComponent.TIME_SWITCH,
        ];
    }

    public getOutletAfternoonConfig() {
        this.outletAfternoonConfig = [
            this.appointment.outlet.afternoonDeliveryTimeStart
                ? this.timeToInt(this.appointment.outlet.afternoonDeliveryTimeStart)
                : AppointmentResumeComponent.TIME_SWITCH,
            this.appointment.outlet.afternoonDeliveryTimeEnd
                ? this.timeToInt(this.appointment.outlet.afternoonDeliveryTimeEnd)
                : AppointmentResumeComponent.TIME_END,
        ];
    }

    public switchTimeframe(timeFrame: string) {
        let timeStart: any;
        let timeEnd: any;
        if (timeFrame === AppointmentResumeComponent.MORNING) {
            (timeStart = this.outletMorningConfig[0]), (timeEnd = this.outletMorningConfig[1]);
        } else {
            timeStart = this.outletAfternoonConfig[0];
            timeEnd = this.outletAfternoonConfig[1];
        }
        this.sliderRef.slider.updateOptions({
            range: {
                min: timeStart,
                max: timeEnd,
            },
            start: [timeStart, timeEnd],
        });
    }

    public setSlide(r: number[]): void {
        const timeStart = this.timeInfo(r[0]);
        const timeEnd = this.timeInfo(r[1]);

        this.appointment.preferedReturnTimeStart = timeStart + ':00';
        this.appointment.preferedReturnTimeEnd = timeEnd + ':00';

        this.slideTimeInfo = `${timeStart} - ${timeEnd} (${this.timeInfo(r[1] - r[0], false)})`;
    }

    public timeToInt(time: string): number {
        const times = time.split(':');
        if (times[0] === '00') {
            times[0] = '24';
        }
        const hour = parseInt(times[0], null);
        const minute = parseInt(times[1], null);

        return hour * 2 + (minute === 30 ? 1 : 0);
    }

    public timeInfo(time: number, standardFormat: boolean = true): string {
        let hour = Utils.twoStringNumber(Math.floor(time / 2));
        const minute = Utils.twoStringNumber(time % 2 === 1 ? 30 : 0);
        if (hour === '24') {
            hour = '00';
        }

        if (standardFormat) {
            return `${hour}:${minute}`;
        } else {
            return `${hour}h ${minute}m`;
        }
    }

    public getAttachmentFullName(attachment: string) {
        const sp = attachment.split('/');
        return sp[sp.length - 1];
    }

    public getAttachmentName(attachment: string) {
        const name = this.getAttachmentFullName(attachment);
        return name.split('|')[1];
    }

    public printHour(hour: number) {
        return Utils.twoStringNumber(hour) + ':00';
    }

    private getDurationFastLane(fastLaneTimeSlot: string) {
        let durationFastLane;
        const startTimeSlot = fastLaneTimeSlot.split('-')[0].split(':');
        const endTimeSlot = fastLaneTimeSlot.split('-')[1].split(':');
        durationFastLane =
            Number(endTimeSlot[0]) * 60 +
            Number(endTimeSlot[1]) -
            (Number(startTimeSlot[0]) * 60 + Number(startTimeSlot[1]));
        return durationFastLane;
    }

    public getCurrentBrandFromStyle(): string {
        return this.styleSwitcherService.brand.getValue();
    }

    public updateValuePreRegistration(value: boolean): void {
        this.sendPreRegistrationEmail.emit(value);
    }
}
