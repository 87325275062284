import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { OutletService } from '../../../core/api/outlet/outlet.service';
import { AlertWarningComponent } from '../../../shared/alert/alert-warning.component';
import { AppComponent } from './../../../app.component';
import { MobilityService } from './../../../core/api/mobility/mobility.service';
import { CountryConfiguration } from '../../../country-configuration';
import { countryConfiguration } from '../../../../configuration/country.configuration';
import {
    Appointment,
    BaseResponse,
    EventSci,
    GeoMarker,
    LocationComponentType,
    Outlet,
    OutletNextAvailableSlot,
    WelcomeActivityCategory,
} from './../../../shared/entities';
import { dataAnalytics } from '../../../shared/data-analytics';
import { TracyService } from '../../../shared/tracy.service';
import { AuthService } from '../../../auth/auth.service';
import { Utils } from '../../../core/utils';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { GoogleMapsLoaderService } from '../../../shared/google-maps-loader.service';
import { GoogleMap, MapInfoWindow, MapMarker,  } from '@angular/google-maps';
@Component({
    selector: 'oas-outlet-maps',
    templateUrl: './outlet-maps.component.html',
    styleUrls: ['./outlet-maps.component.scss']
})
export class OutletMapsComponent implements OnInit {
    @Input() public outlet: Outlet;
    @Input() public defaultAddress: GeoMarker;
    @Input() public edit = false;
    @Input() public appointment: Appointment;
    @Input() public welcomeCategory: WelcomeActivityCategory;
    @Output() private outletChange = new EventEmitter<Outlet>();
    @Output() private dropdownSelection = new EventEmitter<EventSci>();
    @ViewChild(GoogleMap) private googleMap: GoogleMap;
    @ViewChild('searchAddress') private searchAddressElementRef: ElementRef;
    @ViewChild('alertWarning') private alertWarning: AlertWarningComponent;
    @ViewChild(MapInfoWindow,  { static: false }) infoWindow: MapInfoWindow;

    public markerTitle: string = '';
    
    public searchType = 'address';
    public outlets: Outlet[] = [];
    public latitude: number;
    public longitude: number;
    public zoom: number;
    public radius;
    public searchAddressControl = new UntypedFormControl();
    public searchOutletControl = new UntypedFormControl();
    public radiusControl;
    public showSlider = true;
    public onPlaceChangeExecuted = true;
    public pendingOutlets: Outlet[] = [];
    public noAvailableTimeslotOutlets: string[] = [];
    public enableOutletFilter = false;
    public enableOutletFilterWhenCashed = false;
    public outletCallsNumber = 0;
    public defaultFilter = "TIME_SLOT";
    public enableAvailabilityFilter: boolean = false;
    public isFstAvailableLoading: boolean = false;
    public mapOptions: google.maps.MapOptions;
    public circleOptions: google.maps.CircleOptions;

    public displayMap = false;
    public infoWindowOptions: google.maps.InfoWindowOptions = {
        minWidth: 200,
        maxWidth: 201
    }

    public style: any = [
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#444444',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#46bcec',
                },
                {
                    visibility: 'on',
                },
            ],
        },
    ];

    private countryConfiguration: CountryConfiguration;
    public isCustomer = false;
    private defaultMapRadius: number;

    constructor(
        private appComponent: AppComponent,
        public googleMapsLoaderService: GoogleMapsLoaderService,
        private ngZone: NgZone,
        private outletService: OutletService,
        private mobilityService: MobilityService,
        private tracyService: TracyService,
        private authService: AuthService,
        private countryConfigurationService: CountryConfigurationService,
    ) {
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
        this.radius = this.countryConfiguration.mapsRadius;
        this.defaultMapRadius = this.countryConfiguration.mapsRadius;
        this.radiusControl = new UntypedFormControl(this.radius / 1000);
        this.enableAvailabilityFilter = this.countryConfiguration.availabilityFilter;
    }

    public ngOnInit() {
        // load Places Autocomplete
        this.googleMapsLoaderService.load().then(() => {
            this.displayMap = true;
            this.setGoogleMapsDefault();
            this.loadGoogleAutocomplete();
        });

        if (this.authService.isCustomer() || Utils.isNullOrUndefined(this.authService.getSelectedRole())) {
            this.isCustomer = true;
        }
    }

    public openInfoWindow(marker: MapMarker, name: string) {
        this.markerTitle = name;
        this.infoWindow.open(marker);
    }

    public closeInfoWindow() {
        this.infoWindow.close()
    }

    private retrieveOutlets(activityCategoryIds: string[], favouriteOutletId?: string): void {
        this.appComponent.loading = true;
        this.outlets = [];
        this.outletService
            .getAllBySkills(activityCategoryIds)
            .then(outlets => {
                // no outlet management
                if (outlets.length === 0) {
                    this.onSelect(null);
                    this.setGoogleMapsDefault();
                    this.appComponent.loading = false;
                    this.alertWarning.show();
                    return;
                }

                this.outlets = outlets;
                if(this.enableAvailabilityFilter && this.pendingOutlets != null && this.pendingOutlets.length > 0){
                    this.outlets.forEach(outlet => {
                        let outl = this.pendingOutlets.find(o1 => outlet.id === o1.id);
                        if(outl) {
                            outlet.firstAvailableTimeslot = outl.firstAvailableTimeslot;
                        }
                    });
                    if(this.defaultFilter == 'TIME_SLOT') {
                        this.changeOutletFilter(null, 'TIME_SLOT');
                    }
                }

                if (this.outlet) {
                    // edit appointment management (outlet is obiovusely already selected!)
                    if (this.outlets.find(outlet => outlet.id === this.outlet.id)) {
                        this.latitude = this.outlet.address.latitude;
                        this.longitude = this.outlet.address.longitude;
                        this.onSelect(this.outlet);
                        this.toggleOutletVisibility();
                    } else {
                        this.onSelect(null);
                        this.setGoogleMapsDefault();
                    }
                } else {
                    // new appointment management (outlet is not selected!)
                    if (favouriteOutletId) {
                        const favouriteOtlet = this.outlets.find(outlet => outlet.id === favouriteOutletId);
                        // if exist, preselect customer favourite outlet
                        if (favouriteOtlet) {
                            this.onSelect(favouriteOtlet);
                        }
                    } else {
                        // no favourite outlet available
                        this.toggleOutletVisibility();
                        this.retrieveFirstTimeslotForOutlets();
                    }
                }
                this.appComponent.loading = false;
            })
            .catch(() => {
                this.appComponent.loading = false;
            });
    }

    private retrieveFirstTimeslotForOutlets() {

        if(this.enableAvailabilityFilter) {
            this.defaultFilter = 'TIME_SLOT';
            this.isFstAvailableLoading = true;
            const outletNextAvailableSlotPromises: Promise<BaseResponse<OutletNextAvailableSlot>>[] = [];
            const circleCenter = new google.maps.LatLng(this.latitude, this.longitude);
            for(const outlet of this.outlets){
                const outletLatLng = new google.maps.LatLng(outlet.address.latitude, outlet.address.longitude);
                if (google.maps.geometry.spherical.computeDistanceBetween(circleCenter, outletLatLng) <= this.radius) {
                    if(!this.pendingOutlets.includes(outlet) && !this.noAvailableTimeslotOutlets.includes(outlet.id)) {
                        outletNextAvailableSlotPromises.push(this.outletService.getOutletNextFreeSlot(this.appointment, outlet));
                    }
                }
            }
            Promise.all(outletNextAvailableSlotPromises).then(result => {
                for(const el of result) {
                    if (el.payload.availableTimeSlot) {
                        const outletIndex = this.outlets.findIndex(outlet => outlet.id === el.payload.outletId);
                        this.outlets[outletIndex].firstAvailableTimeslot = el.payload.availableTimeSlot;
                        this.pendingOutlets.push(this.outlets[outletIndex]);

                    } else {
                        this.noAvailableTimeslotOutlets.push(el.payload.outletId);
                    }
                }
                this.isFstAvailableLoading = false;
                this.changeOutletFilter(null, 'TIME_SLOT');
            })  
        }
    }

    public changeOutletFilter($event, defaultValue = null) {
        if(defaultValue !== null) {
            this.defaultFilter = defaultValue;
        } else {
            this.defaultFilter = $event.target.value;
        }
        
        if(this.defaultFilter === 'BUSINESS_NAME') {
            this.outlets.sort((o1, o2) => {
                if(o1.businessName < o2.businessName) { return -1; }
                if(o1.businessName > o2.businessName) { return 1; }
                return 0;
            });
        } else {
            this.outlets.sort((o1, o2) => {
                if(o1.firstAvailableTimeslot === null) return 1;
                if(o2.firstAvailableTimeslot === null) return -1;
                if(o1.firstAvailableTimeslot < o2.firstAvailableTimeslot) {return -1;}
                if(o1.firstAvailableTimeslot > o2.firstAvailableTimeslot) {return 1;}
                return 0;
            });
        }
    }

    private setGoogleMapsDefault() {
        const isDefaultAddressNull = Utils.isNullOrUndefined(this.defaultAddress);

        this.latitude = isDefaultAddressNull ? this.countryConfiguration.latitude : this.defaultAddress.latitude;
        this.longitude = isDefaultAddressNull ? this.countryConfiguration.longitude : this.defaultAddress.longitude;
        this.zoom = countryConfiguration.mapsZoom;

        this.setMapOptions();
        this.setMapCircleOptions();
    }

    private setMapOptions() {
        this.mapOptions = {
            center: {
                lat: this.latitude,
                lng: this.longitude
            },
            scrollwheel: false,
            streetViewControl: false,
            styles: this.style,
            zoom: this.zoom
        }
    }

    private setMapCircleOptions() {
        this.circleOptions = {
            center: {
                lat: this.latitude, 
                lng: this.longitude 
            },
            radius: this.radius,
            clickable: false,
            visible: true
        };
    }

    private loadGoogleAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.searchAddressElementRef.nativeElement, {
            // types: ['address']
            types: ['geocode'],
        });
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                // get the place result
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                // verify result
                if (place.geometry) {
                    // set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = countryConfiguration.mapsZoom;                    
                    this.onPlaceChangeExecuted = true;

                    this.setMapOptions();
                    this.setMapCircleOptions();
                }

                const event: EventSci = {
                    value: place.formatted_address,
                    source: dataAnalytics.event.effect.addressSelected,
                };

                this.dropdownSelection.emit(event);
                this.resetMapRadiusToDefaultAndRetrieveSlots();
               
            });
        });
    }

    private resetMapRadiusToDefaultAndRetrieveSlots() {
        this.radius = this.defaultMapRadius;
        this.radiusControl = new UntypedFormControl(this.radius / 1000);
        this.setMapCircleOptions();
        this.retrieveFirstTimeslotForOutlets();
    }

    public async onSelect(outlet: Outlet) {
        if (outlet) {
            this.latitude = outlet.address.latitude;
            this.longitude = outlet.address.longitude;

            this.setMapOptions();
            this.setMapCircleOptions();

            if (outlet.outletMobilityTypeList) {
                this.outlet = outlet;
                this.outletChange.emit(outlet);
            } else {
                await this.mobilityService
                    .getAvailableMobilityTypes(outlet.id)
                    .then(outMobTypes => {
                        outlet.outletMobilityTypeList = [];
                        outMobTypes.forEach(outMobType => {
                            outlet.outletMobilityTypeList.push({ mobilityType: outMobType.mobilityType });
                        });
                        this.outlet = outlet;
                        this.outletChange.emit(outlet);
                    })
                    .catch(() => {
                        this.outlet = outlet;
                        this.outletChange.emit(outlet);
                    });
            }
        } else {
            this.outlet = outlet;
            this.outletChange.emit(outlet);
        }
        this.retrieveFirstTimeslotForOutlets();
    }

    public init(
        categoryDurationMap: Map<string, number>,
        favouriteOutletId?: string,
        fastlane?: boolean,
        isDelearChange?: boolean
    ): void {
            const activityCategoryIds = [this.welcomeCategory, ...Array.from(categoryDurationMap.keys())];
            if (fastlane) {
                activityCategoryIds.push('FAST_LANE');
            }
    
            // const activitiesDuration = Array.from(categoryDurationMap.values()).reduce((prev, curr) => prev + curr);
            // if the selected services are less that 4UT
            this.retrieveOutlets(activityCategoryIds, favouriteOutletId);
    
            // force map redering
            this.googleMap.mapInitialized.emit();
            this.setDataAnalytics(isDelearChange);
       
    }

    public setRadius(value: string): void {
        if(this.displayMap) {
            this.radius = parseFloat(value) * 1000;
            this.setMapCircleOptions();
            this.retrieveFirstTimeslotForOutlets();
        }
    }

    /**
     * Show/hide outlets based on distance between selected center and radius
     */
    public toggleOutletVisibility(): void {
        const circleCenter = new google.maps.LatLng(this.latitude, this.longitude);
        for (const outlet of this.outlets) {
            const outletLatLng = new google.maps.LatLng(outlet.address.latitude, outlet.address.longitude);
            if (google.maps.geometry.spherical.computeDistanceBetween(circleCenter, outletLatLng) > this.radius) {
                outlet.visible = false;
            } else {
                outlet.visible = true;
                if (this.onPlaceChangeExecuted) {
                    this.onPlaceChangeExecuted = false;
                }
            }
        }
    }

    // public automaticRadius(): void {
    //     if (this.onPlaceChangeExecuted) {
    //         this.setRadius(String(this.radius / 1000 + 10));
    //         this.radiusControl = new FormControl(this.radius / 1000);
    //     }
    // }

    public onSearchTypeChange(searchType: string): void {
        this.searchType = searchType;
        this.searchAddressControl.reset();
        this.searchOutletControl.reset();
        if (this.searchType === 'outlet') {
            this.showSlider = false;
        } else {
            this.showSlider = true;
        }
    }

    public trackByOutlet(outlet: Outlet) {
        return outlet.id;
    }

    public typeaheadOnSelect(e: TypeaheadMatch): void {
        this.onSelect(e.item);

        const event: EventSci = {
            value: e.item.businessName,
            source: dataAnalytics.event.effect.outletSelected,
        };

        this.dropdownSelection.emit(event);
        this.resetMapRadiusToDefaultAndRetrieveSlots();
    }

    public detectIE() {
        const ua = window.navigator.userAgent;

        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    private setDataAnalytics(isDealerChange?: boolean) {
        if (!isDealerChange) {
            this.setDataCenterServiceSelection();
        } else {
            this.tracyService.setPageInformationIntoDataAnalytics(
                dataAnalytics.page.pageID.lOasServiceSelectionModified,
                dataAnalytics.page.variant.virtual
            );
            this.tracyService.setUserInformationIntoDataAnalytics('na');

            this.tracyService.sendPageView(dataAnalytics.referenceName.serviceSelectionModified);
        }
    }
    setDataCenterServiceSelection() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasServiceCenterSelection,
            dataAnalytics.page.variant.real
        );
        if (!Utils.isNullOrUndefined(this.outlet)) {
            this.tracyService.setDealerInformationIntoDataAnalytics(
                this.outlet.dealerId,
                this.outlet.businessName,
                this.outlet.id,
                'selected'
            );
        }
        this.tracyService.setUserInformationIntoDataAnalytics('na');
        this.tracyService.sendPageView(dataAnalytics.referenceName.serviceCenterSelection);
    }
    outletCityByAddress(address: GeoMarker): string {
        let locality;

        if (address && address.locationComponentList) {
            for (const locationComponent of address.locationComponentList) {
                if (locationComponent.label === <LocationComponentType>'locality') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_3') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_4') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_5') {
                    locality = locationComponent.longName;
                    break;
                }
            }
        }

        return locality;
    }

    public getMapZoom() {
        this.zoom = this.googleMap.getZoom();
        this.mapOptions.zoom = this.googleMap.getZoom();
    }
}
